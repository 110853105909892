import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { Stats, Review } from '@gate31/types/ReviewsTypes';

interface ReviewsItemsOpts {
    container: HTMLElement;
    template: 'reviews-item-desk-template' | 'reviews-item-template';
    className?: string;
}

interface renderOptions {
    reviewsStat: Stats;
    reviewsList: Review[];
}

export class ReviewsItems extends BaseJs {
    static cn = new BaseJs.BuildCn('ReviewsItem');

    container: HTMLElement;
    className: ReviewsItemsOpts['className'];

    constructor({ container, template, className }: ReviewsItemsOpts) {
        super(template);

        this.className = className;
        this.container = container;
    }

    render({ reviewsStat, reviewsList }: renderOptions) {
        const content = reviewsList.map(item => {
            item.Date = (item.Date as string).split('T')[0].split('-').reverse().join('.');

            return this.getHTML({
                item,
                counter: reviewsStat?.ReviewsTotalCount || 0,
                className: this.className
            });
        }).join('');

        this.container.insertAdjacentHTML('beforeend', content);
    }
}
