const BASE_COLORS = [
    {
        id: 7038235,
        option_name_id: 788085,
        position: 0,
        title: 'Черный',
        hex: [ '#000' ]
    },
    {
        id: 7038236,
        option_name_id: 788085,
        position: 1,
        title: 'Красный',
        hex: [ '#d80202' ]
    },
    {
        id: 7221647,
        option_name_id: 788085,
        position: 2,
        title: 'Синий',
        hex: [ '#34497d' ]
    },
    {
        id: 7221702,
        option_name_id: 788085,
        position: 3,
        title: 'Бежевый',
        hex: [ '#d6b18b' ]
    },
    {
        id: 7222530,
        option_name_id: 788085,
        position: 4,
        title: 'Светло-серый',
        hex: [ '#dbdbdb' ]
    },
    {
        id: 13909873,
        option_name_id: 788085,
        position: 5,
        title: 'Темно-серый',
        hex: [ '#737373' ]
    },
    {
        id: 10943313,
        option_name_id: 788085,
        position: 7,
        title: 'Светло-бежевый',
        hex: [ '#dbcec1' ]
    },
    {
        id: 14959174,
        option_name_id: 788085,
        position: 8,
        title: 'Ягодный',
        hex: [ '#9e294a' ]
    },
    {
        id: 7222608,
        option_name_id: 788085,
        position: 9,
        title: 'Электрик',
        hex: [ '#1c37ea' ]
    },
    {
        id: 7222652,
        option_name_id: 788085,
        position: 10,
        title: 'Голубой',
        hex: [ '#c2d2ef' ]
    },
    {
        id: 7226670,
        option_name_id: 788085,
        position: 11,
        title: 'Коралловый',
        hex: [ '#f99d86' ]
    },
    {
        id: 14959216,
        option_name_id: 788085,
        position: 12,
        title: 'Фисташковый',
        hex: [ '#939c8c' ]
    },
    {
        id: 7227573,
        option_name_id: 788085,
        position: 13,
        title: 'Серый',
        hex: [ '#a8a8a8' ]
    },
    {
        id: 7346746,
        option_name_id: 788085,
        position: 14,
        title: 'Неви',
        hex: [ '#2c3363' ]
    },
    {
        id: 7425748,
        option_name_id: 788085,
        position: 15,
        title: 'Зеленый',
        hex: [ '#3a4d39' ]
    },
    {
        id: 7455557,
        option_name_id: 788085,
        position: 16,
        title: 'Розовый',
        hex: [ '#c79793' ]
    },
    {
        id: 8207015,
        option_name_id: 788085,
        position: 17,
        title: 'Изумрудный',
        hex: [ '#0f6859' ]
    },
    {
        id: 8207233,
        option_name_id: 788085,
        position: 18,
        title: 'Мятный',
        hex: [ '#c7e2d3' ]
    },
    {
        id: 8237257,
        option_name_id: 788085,
        position: 19,
        title: 'Песочный',
        hex: [ '#a37c60' ]
    },
    {
        id: 8237828,
        option_name_id: 788085,
        position: 20,
        title: 'Графитовый',
        hex: [ '#636363' ]
    },
    {
        id: 14959196,
        option_name_id: 788085,
        position: 21,
        title: 'Карамельный',
        hex: [ '#8f6951' ]
    },
    {
        id: 11800591,
        option_name_id: 788085,
        position: 22,
        title: 'Шоколадный',
        hex: [ '#54443e' ]
    },
    {
        id: 14879906,
        option_name_id: 788085,
        position: 23,
        title: 'Темно-бежевый',
        hex: [ '#7d5541' ]
    },
    {
        id: 8311072,
        option_name_id: 788085,
        position: 32,
        title: 'Белый',
        hex: [ '#ffffff' ]
    },
    {
        id: 8379563,
        option_name_id: 788085,
        position: 47,
        title: 'Пудровый',
        hex: [ '#ffddd3' ]
    },
    {
        id: 8473719,
        option_name_id: 788085,
        position: 51,
        title: 'Персиковый',
        hex: [ '#ffe7d6' ]
    },
    {
        id: 8480030,
        option_name_id: 788085,
        position: 59,
        title: 'Сиреневый',
        hex: [ '#735c7a' ]
    },
    {
        id: 8489022,
        option_name_id: 788085,
        position: 63,
        title: 'Оливковый',
        hex: [ '#a36e39' ]
    },
    {
        id: 8496100,
        option_name_id: 788085,
        position: 64,
        title: 'Молочный',
        hex: [ '#fcf8de' ]
    },
    {
        id: 8498147,
        option_name_id: 788085,
        position: 66,
        title: 'Желтый',
        hex: [ '#fff091' ]
    },
    {
        id: 8521221,
        option_name_id: 788085,
        position: 76,
        title: 'Хаки',
        hex: [ '#6f7758' ]
    },
    {
        id: 8521516,
        option_name_id: 788085,
        position: 78,
        title: 'Коричневый',
        hex: [ '#453934' ]
    },
    {
        id: 8612720,
        option_name_id: 788085,
        position: 81,
        title: 'Бирюзовый',
        hex: [ '#1b9b9b' ]
    },
    {
        id: 8626129,
        option_name_id: 788085,
        position: 82,
        title: 'Лиловый',
        hex: [ '#e0bcdf' ]
    },
    {
        id: 8668078,
        option_name_id: 788085,
        position: 83,
        title: 'Бордовый',
        hex: [ '#a32828' ]
    },
    {
        id: 10091717,
        option_name_id: 788085,
        position: 87,
        title: 'Кофейный',
        hex: [ '#9b8e7c' ]
    },
    {
        id: 10239484,
        option_name_id: 788085,
        position: 88,
        title: 'Терракотовый',
        hex: [ '#d16023' ]
    },
    {
        id: 10245580,
        option_name_id: 788085,
        position: 89,
        title: 'Фиолетовый',
        hex: [ '#8f46ba' ]
    },
    {
        id: 10270242,
        option_name_id: 788085,
        position: 90,
        title: 'Йогуртовый',
        hex: [ '#d8b6d5' ]
    },
    {
        id: 10415097,
        option_name_id: 788085,
        position: 92,
        title: 'Оранжевый',
        hex: [ '#ffb619' ]
    },
    {
        id: 10415227,
        option_name_id: 788085,
        position: 93,
        title: 'Горчичный',
        hex: [ '#e8d035' ]
    },
    {
        id: 10420248,
        option_name_id: 788085,
        position: 94,
        title: 'Лавандовый',
        hex: [ '#ece0fc' ]
    },
    {
        id: 10553837,
        option_name_id: 788085,
        position: 97,
        title: 'Джинсовый',
        hex: [ '#4f5fa8' ]
    },
    {
        id: 10572745,
        option_name_id: 788085,
        position: 98,
        title: 'Светло-розовый',
        hex: [ '#ffeaf0' ]
    },
    {
        id: 15956723,
        option_name_id: 788085,
        position: 100,
        title: 'Тёмно-зеленый',
        hex: [ '#35524f' ]
    },
    {
        id: 10891523,
        option_name_id: 788085,
        position: 99,
        title: 'Кирпичный',
        hex: [ '#a03623' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Серый меланж',
        hex: [ '#e8e8e8' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Светло-зеленый',
        hex: [ '#a8e0dd' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Ореховый',
        hex: [ '#edcfad' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Дымчатый',
        hex: [ '#5E5D65' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Ванильный',
        hex: [ '#E6E0D4' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Темно-синий',
        hex: [ '#20314D' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Темно-зеленый',
        hex: [ '#434E4B' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Суровый',
        hex: [ '#E9E3D6' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Пепельный',
        hex: [ '#A7A7A9' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Индиго',
        hex: [ '#00416A' ]
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Лимонный',
        hex: [ '#FFFF99' ]
    }
] as const;

type colorTitle = typeof BASE_COLORS[number]['title']

const buildColorMap = (array: typeof BASE_COLORS) => {
    const map: Map<colorTitle, string> = new Map();

    array.forEach(color => {
        map.set(color.title, color.hex[0]);
    });

    return map;
};
const colorMap = buildColorMap(BASE_COLORS);

const prepareColors = (opts: {
    colors: Array<colorTitle>;
    colorMap: Map<colorTitle, string>;
}) => {
    return opts.colors.map(colorName => {
        return colorMap.get(colorName);
    });
};

const COLORS_VARIABLES = [
    {
        id: 8473884,
        option_name_id: 788085,
        position: 52,
        title: 'Бежево-красный',
        hex: prepareColors({ colors: [ 'Бежевый', 'Красный' ], colorMap })
    },
    {
        id: 8475734,
        option_name_id: 788085,
        position: 55,
        title: 'Бело-голубой',
        hex: prepareColors({ colors: [ 'Белый', 'Голубой' ], colorMap })
    },
    {
        id: 8481623,
        option_name_id: 788085,
        position: 60,
        title: 'Черно-белый',
        hex: prepareColors({ colors: [ 'Черный', 'Белый' ], colorMap })
    },
    {
        id: 8481623,
        option_name_id: 788085,
        position: 60,
        title: 'Бело-черный',
        hex: prepareColors({ colors: [ 'Белый', 'Черный' ], colorMap })
    },
    {
        id: 8481645,
        option_name_id: 788085,
        position: 61,
        title: 'Сине-белый',
        hex: prepareColors({ colors: [ 'Синий', 'Белый' ], colorMap })
    },
    {
        id: 8543350,
        option_name_id: 788085,
        position: 80,
        title: 'Серо-зеленый',
        hex: prepareColors({ colors: [ 'Серый', 'Зеленый' ], colorMap })
    },
    {
        id: 8889500,
        option_name_id: 788085,
        position: 84,
        title: 'Бело-красный',
        hex: prepareColors({ colors: [ 'Белый', 'Красный' ], colorMap })
    },
    {
        id: 10480849,
        option_name_id: 788085,
        position: 96,
        title: 'Бело-синий',
        hex: prepareColors({ colors: [ 'Белый', 'Синий' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Серо-бежевый',
        hex: prepareColors({ colors: [ 'Серый', 'Бежевый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Бело-зеленый',
        hex: prepareColors({ colors: [ 'Белый', 'Зеленый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Белый + черный',
        hex: prepareColors({ colors: [ 'Белый', 'Черный' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Кофейный + оливковый',
        hex: prepareColors({ colors: [ 'Кофейный', 'Оливковый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Белый + черный + светло–серый',
        hex: prepareColors({ colors: [ 'Белый', 'Черный', 'Светло-серый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Бело-серый',
        hex: prepareColors({ colors: [ 'Белый', 'Серый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Белый + черный + светло–бежевый',
        hex: prepareColors({ colors: [ 'Белый', 'Черный', 'Светло-бежевый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Светло–серый + светло–бежевый',
        hex: prepareColors({ colors: [ 'Светло-серый', 'Светло-бежевый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Белый + черный + оливковый',
        hex: prepareColors({ colors: [ 'Белый', 'Черный', 'Оливковый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Бело-бордовый',
        hex: prepareColors({ colors: [ 'Белый', 'Бордовый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Бело-коричневый',
        hex: prepareColors({ colors: [ 'Белый', 'Коричневый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Серо-белый',
        hex: prepareColors({ colors: [ 'Серый', 'Белый' ], colorMap })
    },
    {
        id: 0,
        option_name_id: 0,
        position: 0,
        title: 'Бело-бежевый',
        hex: prepareColors({ colors: [ 'Белый', 'Бежевый' ], colorMap })
    }
];

export const COLORS = [
    ...BASE_COLORS,
    ...COLORS_VARIABLES
];

// {
//     id: 8484616,
//     option_name_id: 788085,
//     position: 62,
//     title: 'Бургунди'
// },
// {
//     id: 8926952,
//     option_name_id: 788085,
//     position: 85,
//     title: 'Вишневый'
// },
// {
//     id: 10006908,
//     option_name_id: 788085,
//     position: 86,
//     title: 'Винный'
// },
// {
//     id: 10445345,
//     option_name_id: 788085,
//     position: 95,
//     title: 'Серебряный'
// },
