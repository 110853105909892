import isEqual from 'lodash/isEqual';
import { configureStore, EnhancedStore, bindActionCreators, ActionCreatorsMapObject } from '@reduxjs/toolkit';

export interface Store<S, A extends ActionCreatorsMapObject> {
    store: EnhancedStore<S>;
    actions: A;
    getState(): S;
}

export class AppStore<S, A extends ActionCreatorsMapObject> implements Store<S, A> {
    store: EnhancedStore<S>;
    actions: A;

    constructor(store: EnhancedStore<S>, actions: A) {
        this.store = store;

        this.actions = bindActionCreators<A, A>(actions, this.store.dispatch);
    }

    getState() {
        return this.store.getState();
    }
}

export function Connect(store: InstanceType<typeof AppStore>) {
    // eslint-disable-next-line
    return function ConnectMixin<Input extends new(...args: any[]) => any> (
        value: Input,
        // eslint-disable-next-line
        context: ClassDecoratorContext
    ) {
        abstract class Mixin extends value {
            unsubscribe;

            static _store = store;

            prevState: ReturnType<ReturnType<typeof configureStore>['getState']>;

            // eslint-disable-next-line
            constructor(...args: any[]) {
                super(...args);

                this.onStoreChange = this.onStoreChange.bind(this);

                this.unsubscribe = store.store.subscribe(() => {
                    this.onStoreChange(store.getState(), this.prevState);

                    this.prevState = store.getState();
                });

                this.onStoreChange(store.getState(), store.getState());

                this.prevState = store.getState();
            }

            onStoreChange(state: typeof this.prevState, prevState: typeof this.prevState) {
                super.onStoreChange(state, prevState);
            }
        }

        return Mixin;
    };
}

// eslint-disable-next-line
export function MemoSelect<This, Store>(selector: any) {
    // eslint-disable-next-line
    return function decorator<This, Args extends any[], Return>(
        method: (this: This, ...args: Args) => Return,
        context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
    ) {
        let _store: Store;

        context.addInitializer(function() {
            // @ts-ignore
            this[context.name] = this[context.name].bind(this);
            // @ts-ignore
            _store = this.constructor._store;

            // @ts-ignore
            _store.store.subscribe(() => {
                // @ts-ignore
                newMethod.apply(this, [ _store.getState(), _store.prevState ]);
            });
        });

        const newMethod = function(this: This, ...args: Args) {
            if (
                // @ts-ignore
                this?.prevState &&
                // @ts-ignore
                ! isEqual(selector(_store.getState()), selector(this?.prevState))
            ) {
                return method.apply(this, args);
            }

            // @ts-expect-error
            if (! this?.prevState) {
                return method.apply(this, args);
            }
        };

        return newMethod;
    };
}

// eslint-disable-next-line
// export function MemoSelect<This, Store>() {
//     // eslint-disable-next-line
//     return function MemoSelectDecor<This, Args extends any[], Return>(
//         originalMethod: (this: This, ...args: Args) => Return,
//         context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
//     ) {
//         context.addInitializer(function() {
//             // @ts-ignore
//             this[context.name] = this[context.name].bind(this);
//         });
//
//         // Define a new method that wraps the original method
//         const newMethod = function(this: This, ...args: Args) {
//             if (
//                 // @ts-ignore
//                 this.prevState &&
//                 // @ts-ignore
//                 ! isEqual(this.selector(this._store.getState()), this.selector(this.prevState))
//             ) {
//                 return originalMethod.apply(this, args);
//             }
//
//             // @ts-expect-error
//             if (! this.prevState) {
//                 return originalMethod.apply(this, args);
//             }
//         };
//
//         return newMethod;
//     };
// }
