import { CustomCn } from '@gate31/core/src/libs/classname';

export class AccordionDesk {
    static cn = new CustomCn('AccordionDesk');

    attrIsActive: string;
    headCn: CustomCn;
    headButtonCn: CustomCn
    itemCn: CustomCn;

    constructor() {
        this.attrIsActive = 'data-accordion-is-active';

        this.headCn = AccordionDesk.cn.setElem('header');
        this.headButtonCn = AccordionDesk.cn.setElem('header-button');
        this.itemCn = AccordionDesk.cn.setElem('item');

        this.connectionCallback();
    }

    connectionCallback() {
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;

            if (target.closest(this.headCn.toSelector()) && ! target.closest(this.headButtonCn.toSelector())) {
                const item = target.closest<HTMLDivElement>(this.itemCn.toSelector());

                if (! item) {
                    return;
                }

                const isActive = this.getState(item);

                if (! isActive) {
                    this.show(item);
                }
            }

            if (target.closest(this.headButtonCn.toSelector())) {
                const item = target.closest<HTMLDivElement>(this.itemCn.toSelector());

                if (! item) {
                    return;
                }

                const isActive = this.getState(item);

                if (isActive) {
                    this.hide(item);
                } else {
                    this.show(item);
                }
            }
        });
    }

    getState(item: HTMLDivElement) {
        const isActiveString = item.getAttribute(this.attrIsActive);

        return isActiveString === 'true';
    }

    show(item: HTMLDivElement) {
        item.setAttribute(this.attrIsActive, 'true');
    }

    hide(item: HTMLDivElement) {
        item.setAttribute(this.attrIsActive, 'false');
    }
}
