import { Base } from '@gate31/core/src/libs/BaseComponent';
import { CustomCn } from '@gate31/core/src/libs/classname';
import { ShopPointsSliderDesk } from '../ShopPointsSliderDesk/ShopPointsSliderDesk';

class ShopPointsDeskError extends Error {
    name = 'ShopPointsDeskError'
}

export class ShopPointsDesk extends Base<HTMLDivElement> {
    static cn = new CustomCn('ShopPointsDesk');

    dataAttrCityTubLink: string;
    dataAttrCityTubContent: string;
    dataAttrCityTubContentItem: string;
    dataAttrCityTubPointId: string;
    dataAttrPointContentId: string;
    dataAttrDisplay: string;
    dataAttrDisplayTub: string;
    dataAttrDisplayCurrent: string;

    currentDisplay: string;
    currentCityName: string;
    currentPointId: string;

    constructor(node: HTMLDivElement) {
        super(node);

        this.dataAttrCityTubLink = 'data-city-tub-link';
        this.dataAttrCityTubContent = 'data-city-current-content';
        this.dataAttrCityTubContentItem = 'data-city-content';
        this.dataAttrCityTubPointId = 'data-point-tub-id';
        this.dataAttrPointContentId = 'data-point-content-id';
        this.dataAttrDisplay = 'data-display';
        this.dataAttrDisplayTub = 'data-display-tub';
        this.dataAttrDisplayCurrent = 'data-display-current';

        ShopPointsSliderDesk.init();

        this.initDate();
        this.connectionCallback();
    }

    initDate() {
        const initCurrentDisplay = this.getRoot().querySelector(ShopPointsDesk.cn.setElem('display').setMode({ current: true }).toSelector());
        const initCurrentLink = this.getRoot().querySelector(ShopPointsDesk.cn.setElem('tub-link').setMode({ current: true }).toSelector());
        const initCurrentItem = this.getRoot().querySelector(ShopPointsDesk.cn.setElem('item').setMode({ current: true }).toSelector());

        if (! initCurrentLink || ! initCurrentItem) {
            throw new ShopPointsDeskError('npt found elements width init data');
        }

        this.currentDisplay = initCurrentDisplay?.getAttribute(this.dataAttrDisplayTub) as string;
        this.currentCityName = initCurrentLink?.getAttribute(this.dataAttrCityTubLink) as string;
        this.currentPointId = initCurrentItem?.getAttribute(this.dataAttrPointContentId) as string;
    }

    connectionCallback() {
        this.getRoot().addEventListener('click', e => {
            const target = e.target as HTMLDivElement;
            const foundTarget = target.closest<HTMLDivElement>(ShopPointsDesk.cn.setElem('tub-link').toSelector());

            if (foundTarget) {
                this.currentCityName = this.getCurrentCityName(foundTarget);
                this.currentPointId = this.getFirstPointId();

                this.updateCityTubs();
                this.updateCityContent();
                this.updatePointTub();
                this.updatePointItem();
            }
        });
        this.getRoot().addEventListener('click', e => {
            const target = e.target as HTMLDivElement;
            const foundTarget = target.closest<HTMLDivElement>(ShopPointsDesk.cn.setElem('tub-item').toSelector());

            if (foundTarget) {
                this.currentPointId = this.getCurrentPointId(foundTarget);

                this.updatePointTub();
                this.updatePointItem();
            }
        });
        this.getRoot().addEventListener('click', e => {
            const target = e.target as HTMLDivElement;
            const foundTarget = target.closest<HTMLDivElement>(ShopPointsDesk.cn.setElem('item-toggle').toSelector());

            if (foundTarget) {
                this.togglePreview();
                this.togglePreviewButton();
                this.loadingMap();
            }
        });
        this.getRoot().addEventListener('click', e => {
            const target = e.target as HTMLDivElement;
            const foundTarget = target.closest<HTMLDivElement>(ShopPointsDesk.cn.setElem('display').toSelector());

            if (foundTarget) {
                this.currentDisplay = this.getCurrentDisplay(foundTarget);

                this.updateDisplayTubs();
                this.updateDisplay();
            }
        });

        this.getRoot()
            .querySelectorAll(`${ShopPointsDesk.cn.setElem('map').toSelector()} iframe`)
            .forEach(map => {
                const observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            const dataSrc = entry.target.getAttribute('data-src');

                            if (dataSrc) {
                                entry.target.setAttribute('src', dataSrc);
                                observer.disconnect();
                            }
                        }
                    });
                });

                observer.observe(map);
            });
    }

    getCurrentDisplay(tubDisplayNode: HTMLDivElement): string {
        const displayName = tubDisplayNode.getAttribute(this.dataAttrDisplayTub);

        if (! displayName) {
            throw new ShopPointsDeskError(`not fund display name by data attr: ${this.dataAttrDisplayTub}`);
        }

        return displayName;
    }

    getCurrentCityName(tubLinkNode: HTMLDivElement): string {
        const cityName = tubLinkNode.getAttribute(this.dataAttrCityTubLink);

        if (! cityName) {
            throw new ShopPointsDeskError(`not fund city name by data attr: ${this.dataAttrCityTubLink}`);
        }

        return cityName;
    }

    getCurrentPointId(tubPointNode: HTMLDivElement): string {
        const pointId = tubPointNode.getAttribute(this.dataAttrCityTubPointId);

        if (! pointId) {
            throw new ShopPointsDeskError(`not fund point id by data attr: ${this.dataAttrCityTubPointId}`);
        }

        return pointId;
    }

    getFirstPointId() {
        const firstPointTub = this.getRoot().querySelector<HTMLDivElement>(
            ShopPointsDesk.cn.setElem('tub-item')
                .toSelector() + `[${this.dataAttrCityTubContentItem}="${this.currentCityName}"]`
        );

        if (! firstPointTub) {
            throw new ShopPointsDeskError(`not fund first point tub by city name : ${this.currentCityName}`);
        }

        const currentId = firstPointTub.getAttribute(this.dataAttrCityTubPointId);

        if (! currentId) {
            throw new ShopPointsDeskError(`not fund point id by data attr : ${this.dataAttrCityTubPointId}`);
        }

        return currentId;
    }

    updateDisplayTubs() {
        const currentDisplayTub = this.getRoot().querySelector<HTMLDivElement>(ShopPointsDesk.cn.setElem('display').toSelector() + `[${this.dataAttrDisplayTub}="${this.currentDisplay}"]`);

        if (! currentDisplayTub) {
            throw new ShopPointsDeskError(`not fund current city tub by name: ${this.currentCityName}`);
        }

        const currentClass = ShopPointsDesk.cn.setElem('display').setMode({ current: true }).toString();
        const displayTubs = this.getRoot().querySelectorAll(ShopPointsDesk.cn.setElem('display').toSelector());

        displayTubs.forEach(tub => tub.classList.remove(currentClass));
        currentDisplayTub.classList.add(currentClass);
    }

    updateCityTubs() {
        const currentCityTub = this.getRoot().querySelector<HTMLDivElement>(ShopPointsDesk.cn.setElem('tub-link').toSelector() + `[${this.dataAttrCityTubLink}="${this.currentCityName}"]`);

        if (! currentCityTub) {
            throw new ShopPointsDeskError(`not fund current city tub by name: ${this.currentCityName}`);
        }

        const currentClass = ShopPointsDesk.cn.setElem('tub-link').setMode({ current: true }).toString();
        const cityTubs = this.getRoot().querySelectorAll(ShopPointsDesk.cn.setElem('tub-link').toSelector());

        cityTubs.forEach(tub => tub.classList.remove(currentClass));
        currentCityTub.classList.add(currentClass);
    }

    updateDisplay() {
        this.getRoot().setAttribute(this.dataAttrDisplayCurrent, this.currentDisplay);
    }

    updateCityContent() {
        this.getRoot().setAttribute(this.dataAttrCityTubContent, this.currentCityName);
    }

    updatePointTub() {
        const currentClass = ShopPointsDesk.cn.setElem('tub-item').setMode({ current: true }).toString();
        const allPointsTub = this.getRoot().querySelectorAll<HTMLDivElement>(ShopPointsDesk.cn.setElem('tub-item').toSelector());
        const currentPointsTub = this.getRoot().querySelector<HTMLDivElement>(
            ShopPointsDesk.cn.setElem('tub-item')
                .toSelector() + `[${this.dataAttrCityTubPointId}="${this.currentPointId}"]`
        );

        if (! currentPointsTub) {
            throw new ShopPointsDeskError(`not fund current point tub by point id: ${this.currentPointId}`);
        }

        allPointsTub.forEach(pointTub => pointTub.classList.remove(currentClass));
        currentPointsTub.classList.add(currentClass);
    }

    updatePointItem() {
        const currentClass = ShopPointsDesk.cn.setElem('item').setMode({ current: true }).toString();
        const allPointsTub = this.getRoot().querySelectorAll<HTMLDivElement>(ShopPointsDesk.cn.setElem('item').toSelector());
        const currentPointsTub = this.getRoot().querySelector<HTMLDivElement>(ShopPointsDesk.cn.setElem('item').toSelector() + `[${this.dataAttrPointContentId}="${this.currentPointId}"]`);

        if (! currentPointsTub) {
            throw new ShopPointsDeskError(`not fund current point by point id: ${this.currentPointId}`);
        }

        allPointsTub.forEach(pointTub => pointTub.classList.remove(currentClass));
        currentPointsTub.classList.add(currentClass);
    }

    getCurrentPointItem() {
        const elem = this.getRoot().querySelector(ShopPointsDesk.cn.setElem('item').toSelector() + `[${this.dataAttrPointContentId}="${this.currentPointId}"]`);

        if (! elem) {
            throw new ShopPointsDeskError(`not found current element by id ${this.currentPointId}`);
        }

        return elem;
    }

    togglePreview() {
        const currentItem = this.getCurrentPointItem();

        const previewWrapper = currentItem.querySelector(ShopPointsDesk.cn.setElem('item-top').toSelector());

        if (! previewWrapper) {
            throw new ShopPointsDeskError('not found slider and map wrapper in current item');
        }

        previewWrapper.classList.toggle(ShopPointsDesk.cn.setElem('item-top').setMode({ map: true }).toString());
    }

    loadingMap() {
        const currentItem = this.getCurrentPointItem();
        const iframeMap = currentItem.querySelector(ShopPointsDesk.cn.setElem('item-map').toSelector() + ' iframe');

        if (! iframeMap) {
            throw new ShopPointsDeskError('not found iframe map');
        }

        const dataSrc = iframeMap.getAttribute('data-src');

        if (dataSrc) {
            iframeMap.setAttribute('src', dataSrc);
            iframeMap.removeAttribute('data-src');
        }
    }

    togglePreviewButton() {
        const currentItem = this.getCurrentPointItem();
        const button = currentItem.querySelector(ShopPointsDesk.cn.setElem('item-toggle').toSelector());

        if (! button) {
            throw new ShopPointsDeskError(`not found current element button by id ${this.currentPointId}`);
        }

        const prevText = button.textContent;
        const nextText = prevText === 'Карта' ? 'Фото' : 'Карта';

        button.innerHTML = nextText;
    }
}
