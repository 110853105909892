import $ from 'jquery';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from '@gate31/types';
import queryString from '@gate31/core/src/libs/query-string';
import { CollectionSliceState } from './store';

export interface FetchProductCollectionData {
    products: Product[];
    count: number;
}

export const fetchProductCollection = createAsyncThunk<Awaited<FetchProductCollectionData>, {
    page: {
        // eslint-disable-next-line
        page_size: number;
        page: number;
    };
    filters: Record<string, string[] | string>;
    order?: string;
    strategy: CollectionSliceState['strategy'];
}>('collection/products/fetch', param => {
    const query = queryString.stringify({ ...param.filters, ...param.page, order: param.order }, null);

    return new Promise((resolve, reject) => {
        $.ajax({
            url: `${window.location.pathname}.json?${query}`
        }).then(async data => {
            const ids = data.products.map((product: Product) => product.id).join(',');
            const products = await fetch(`/front_api/products.json?with=product_field_values&ids=${ids}`)
                .then(response => response.json())
                .then(result => result.products);

            const fields = new Map();

            products.forEach((product: { id: number; 'product_field_values': { 'product_field_id'?: number; value: string }[] }) => {
                const field = product.product_field_values
                    .find((fieldItem: { 'product_field_id'?: number }) => fieldItem.product_field_id === 65124) ?? null;

                fields.set(product.id, {
                    value: field ? field.value : null,
                    title: 'ID Vimeo video'
                });
            });

            const result = data.products
                .map((item: Product) => {
                    return {
                        ...item,
                        fields: { video_url_vimeo: fields.get(item.id) }
                    };
                });

            return resolve({ ...data, products: result });
        }).catch(reject);
    });
});
