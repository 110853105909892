import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import LiquidRender, { LiquidTemplates } from '@gate31/uikit/common/scripts/liquid-render';
import $ from 'jquery';
import { ModalDesk } from '../ModalDesk/ModalDesk';
import { SubscribeDesk } from '../SubscribeDesk/SubscribeDesk';

class SubscriptionModalManagerDesk {
    static readonly COOKIE_KEY_NAME = 'SUBSCRIBE_MODAL_STATUS';

    static canShow() {
        switch ($ && $.cookie ? $.cookie(SubscriptionModalManagerDesk.COOKIE_KEY_NAME) : null) {
            // Не подписался и закрыл окно
            case 'CLOSED':
                return false;
            // Подписался
            case 'SUBSCRIBED':
                return false;
            // Показываем впервые
            default:
                return true;
        }
    }

    static pause() {
        $ && $.cookie ? $.cookie(
            SubscriptionModalManagerDesk.COOKIE_KEY_NAME,
            'CLOSED',
            {
                expires: 3
            }
        ) : null;
    }

    static off() {
        $ && $.cookie ? $.cookie(
            SubscriptionModalManagerDesk.COOKIE_KEY_NAME,
            'SUBSCRIBED',
            {
                expires: 999
            }
        ) : null;
    }
}

export class SubscriptionModalDesk extends BaseJs {
    static cn = new BaseJs.BuildCn('SubscriptionModalDesk');

    title: string;
    descr: string;
    photo: string;

    modal: ModalDesk;
    form: SubscribeDesk | undefined;

    constructor({ template, opts }: { template: keyof LiquidTemplates; opts: { title: string; descr: string; photo: string }}) {
        super(template);

        this.title = opts.title;
        this.descr = opts.descr;
        this.photo = opts.photo;

        this.modal = this.getModal();

        this.callbackConnection();
    }

    callbackConnection() {
        if (SubscriptionModalManagerDesk.canShow()) {
            const delayTime = Number(window.Site.settings.modalSubscribeDelayTime) || 5;

            setTimeout(() => {
                this.openModal();
            }, delayTime * 1000);
        }

        this.modal.on('close', () => {
            SubscriptionModalManagerDesk.pause();
        });
    }

    hidePhoto() {
        const node = this.findElem<HTMLDivElement>(SubscriptionModalDesk.cn.setElem('photo').toSelector());

        if (node) {
            node.style.display = 'none';
        }
    }

    hidePhotoMode() {
        const node = this.getRoot();

        node.classList.remove(SubscriptionModalDesk.cn.setMode({ photo: true }).toString());
    }

    showPhoto() {
        const node = this.findElem(SubscriptionModalDesk.cn.setElem('photo').toSelector());

        if (node) {
            node.style.display = 'block';
        }
    }

    openModal() {
        this.modal.open(() => {
            this.form = new SubscribeDesk(this.getRoot().querySelector(SubscribeDesk.cn.toSelector()) as HTMLFormElement);

            this.form.ee.on('subscription-form:success', () => {
                this.modal.close();
                SubscriptionModalManagerDesk.off();
            });

            this.form.ee.on('subscription-form:success-message', () => {
                this.hidePhoto();
                this.hidePhotoMode();
            });
        });
    }

    getModalContent() {
        const form = LiquidRender.render(this.template, {
            is_hidden_text: 'false',
            subscription_title: this.title,
            subscription_descr: this.descr
        });

        const photo = this.photo ? `
            <div class="SubscriptionModalDesk__photo">
                <img src='${ this.photo }'>
            </div>
        ` : '';

        return `
            <div class="SubscriptionModalDesk__wrap">
                ${ photo }
                <div class="SubscriptionModalDesk__form">${ form }</div>
            </div>
        `;
    }

    getModal() {
        return new ModalDesk({
            template: 'modal-desk-template',
            content: this.getModalContent(),
            disableBodyScroll: true,
            viewOpts: {
                closeIconSize: 'm',
                className: 'SubscriptionModalDesk',
                classNameMode: this.photo ? 'photo' : ''
            }
        });
    }
}
