import { Base } from '@gate31/core/src/libs/BaseComponent';
import { AccountAuthBaseForm } from '@gate31/feature/AccountAuthBaseForm/AccountAuthBaseForm';

export class AccountAuthBaseFormDesk extends AccountAuthBaseForm {
    static cn = new Base.BuildCn('AccountAuthBaseFormDesk');

    constructor(node: HTMLElement) {
        super(node, AccountAuthBaseFormDesk.cn);
    }
}
