import { Callback } from '@gate31/types';
import LiquidRender, { LiquidTemplates } from '@gate31/uikit/common/scripts/liquid-render';
import { EventEmitter } from '@gate31/uikit/common/scripts/component-event-emitter';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';

interface ModalOpts {
    template: 'modal-template' | 'modal-mini-template' | 'modal-base-template' | 'modal-desk-template';
    content: string;
    parent?: string;
    disableBodyScroll?: boolean;
    viewOpts?: {
        className?: string;
        classNameMode?: string;
        hideCloseBtn?: boolean;
        hideOverlay?: boolean;
        closeIconSize: 'x' | 'xs' | 'm' | 'xl';
    };
}

export class Modal extends EventEmitter {
    static readonly cn = new BaseJs.BuildCn('Modal');
    static list = new Map<HTMLElement, Modal>();

    private viewOpts: ModalOpts['viewOpts'];
    private template: keyof LiquidTemplates;
    private root: HTMLDivElement;
    private content: string;
    private parent: string = 'body';
    private disableBodyScroll: boolean;

    constructor(opts: ModalOpts) {
        super();

        if (opts.template === undefined) {
            throw new Error('Не определен шаблон модального окна');
        }

        this.template = opts.template;

        if (opts.parent) {
            this.parent = opts.parent;
        }

        this.content = opts.content;
        this.viewOpts = opts.viewOpts;
        this.disableBodyScroll = opts.disableBodyScroll ?? false;

        this.connectionCallback();
    }

    private _close() {
        if (this.root && this.disableBodyScroll) {
            enableBodyScroll(this.root);
        }

        this.root.remove();
    }

    private addEventListener() {
        const closeBtn = this.root?.querySelector('[data-modal-close]');
        const overlayNode = this.root?.querySelector('[data-modal-overlay]');

        closeBtn?.addEventListener('click', () => {
            this.emit('close');
            this._close();
        });

        overlayNode?.addEventListener('click', () => {
            this.emit('close');
            this._close();
        });
    }

    private connectionCallback() {
        this.root = document.createElement('div');
        this.root.innerHTML = LiquidRender.render(this.template, { ...this.viewOpts, modalBody: this.content });
        // @ts-expect-error
        this.root = this.root.querySelector(this.constructor.cn.toSelector());

        this.addEventListener();

        Modal.list.set(this.root, this);
    }

    open(cb?: Callback<unknown>) {
        const body = document.querySelector<HTMLDivElement>(this.parent);

        if (body === null) {
            throw new Error('Родительский селектор модального окна не определен');
        }

        Modal.list.forEach(modal => {
            if (modal.root !== this.root) modal._close();
        });

        if (this.disableBodyScroll) {
            disableBodyScroll(this.root);
        }

        body.append(this.root);

        if (cb) {
            cb();
        }

        this.emit('open');

        return this;
    }

    close() {
        this._close();

        this.emit('force-close');
    }
}
