import { Events } from 'EventBus';

export default function callTargetMyCard(event: Events['add_items:insales:cart']) {
    window._tmr.push({
        type: 'itemView',
        list: '2',
        productid: event.action.currentItems[0].product_id,
        pagetype: 'cart',
        totalvalue: event.total_price
    });
}
