import { Base } from '@gate31/core/src/libs/BaseComponent';
import queryString from '@gate31//core/src/libs/query-string';

export abstract class Pagination extends Base<HTMLElement> {
    protected constructor(node: HTMLElement) {
        super(node);
    }

    abstract onSubmit(): void;
    abstract onStoreChange(): void;
    abstract connectedCallback(): void;
    abstract updatePagination(): void;
    abstract toggleHiddenLoadMoreBtn(): void;

    getUrlByParams(params: Record<string, string | number | string[]>, host: string) {
        return `${host}?${queryString.stringify(params, null) }`;
    }
}
