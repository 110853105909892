import { withNaming, NoStrictEntityMods, ClassNameList } from '@bem-react/classname'

export const cn = withNaming({ n: '.', e: '__', m: '_', v: '_' });

const preset = { n: '.', e: '__', m: '_', v: '_' };
const modValueDelimiter = preset.v || preset.m

function stringify(b: string, e?: string, m?: NoStrictEntityMods | null, mix?: ClassNameList) {
    const entityName = e ? b + preset.e + e : b
    let className = entityName

    if (m) {
        const modPrefix = ' ' + className + preset.m

        for (const k in m) {
            if (m.hasOwnProperty(k)) {
                const modVal = m[k]

                if (modVal === true) {
                    className = modPrefix + k
                } else if (modVal) {
                    className = modPrefix + k + modValueDelimiter + modVal
                }
            }
        }
    }

    if (mix !== undefined) {
        mix = Array.isArray(mix) ? mix : [mix]

        for (let i = 0, len = mix.length; i < len; i++) {
            const value = mix[i]

            // Skipping non-string values and empty strings
            if (!value || typeof value.valueOf() !== 'string') continue

            const mixes = value.valueOf().split(' ')

            for (let j = 0; j < mixes.length; j++) {
                const val = mixes[j]
                if (val !== entityName) {
                    className += ' ' + val
                }
            }
        }
    }

    return className.trim();
}

export class CustomCn {
    head: string;

    constructor(head: string) {
        this.head = head;
    }

    setElem(elem: string) {
        return new CustomCn(stringify(this.head, elem))
    }

    setMode(mode: NoStrictEntityMods | null) {
        return new CustomCn(stringify(this.head, undefined, mode))
    }

    toSelector() {
        if (this.head[0] !== '.') {
            return `.${this.head}`;
        }

        return this.head;
    }

    toString() {
        return this.head.replace(/\./g, '');
    }

    get() {
        return this.head;
    }
}
