import EventBus from 'EventBus';
import { CustomCn } from '@gate31/core/src/libs/classname';
import { MindboxApi, serCartBody } from '@gate31/core/src/api/mindboxApi';
import { OrderLine } from '@gate31/types/order';
import callTargetMyCard from '@gate31/feature/targetMy/targetMy';
import { HeaderDesk } from '../HeaderDesk/HeaderDesk';
import { HeaderPopoverDesk } from '../HeaderPopoverDesk/HeaderPopoverDesk';

export interface CartPopover {
    hide(): void;
    show(): void;
    flash(t: OrderLine[]): void;
}

export class CartManagerDesk {
    static snippetCn = new CustomCn('CartPopoverSnippetDesk');

    header: HeaderDesk;
    triggerSelector: string;
    trigger: HTMLDivElement;
    modal: CartPopover;
    modalCn: CustomCn;

    constructor({ modal, modalCn, modalTriggerSelector, header }: { modal: CartPopover; modalCn: CustomCn; modalTriggerSelector: string; header: HeaderDesk }) {
        this.header = header;
        this.triggerSelector = modalTriggerSelector;
        this.trigger = header.findElem(this.triggerSelector);
        this.modal = modal;
        this.modalCn = modalCn;

        this.callBackConnection();
    }

    callBackConnection() {
        this.trigger.addEventListener('mouseenter', () => {
            this.modal.show();
            this.trigger.classList.add('active');
        });

        this.trigger.addEventListener('mouseout', e => {
            const relatedTarget = e.relatedTarget as HTMLElement;

            if (relatedTarget && ! relatedTarget.closest(this.modalCn.toSelector()) && ! relatedTarget.closest(this.triggerSelector)) {
                this.modal.hide();
                this.trigger.classList.remove('active');
            }
        });

        document.addEventListener('mouseover', e => {
            const target = e.target as HTMLElement;
            const currentElem = target.closest(HeaderPopoverDesk.cn.toSelector());

            if (currentElem) {
                if (HeaderPopoverDesk.shortTimoutId) {
                    clearTimeout(HeaderPopoverDesk.shortTimoutId);
                }

                if (! HeaderPopoverDesk.list[0]) {
                    return;
                }

                HeaderPopoverDesk.list[0].addEventListener('mouseleave', () => {
                    HeaderPopoverDesk.destroy();
                    this.trigger.classList.remove('active');
                });
            }
        });

        EventBus.subscribe('update_items:insales:cart', data => {
            const body: serCartBody = {
                productList: data.order_lines.map(variant => {
                    return {
                        product: {
                            ids: {
                                gate31: variant.variant_id
                            }
                        },
                        count: variant.quantity,
                        pricePerItem: variant.sale_price
                    };
                })
            };

            MindboxApi.setCart(body);
        });

        EventBus.subscribe('delete_items:insales:cart', data => {
            if (! data.order_lines.length && HeaderPopoverDesk.list[0]) {
                HeaderPopoverDesk.destroy();
            }
        });

        EventBus.subscribe('add_items:insales:cart', data => {
            if (! data.action.form) {
                // Если событие вызвано через js a не в форме // Чтобы не вызывать при самостоятельно изменение кол-ва позиций в модальном окне
                return;
            }

            const products = data.order_lines;

            this.modal.flash(products);
            callTargetMyCard(data);
        });
    }
}
