import { Base } from '@gate31/core/src/libs/BaseComponent';

export class InputCode extends Base<HTMLElement> {
    static cn = new Base.BuildCn('InputCode');

    inputs: NodeListOf<HTMLInputElement>;

    value: string;

    constructor(node: HTMLElement) {
        super(node);

        // @ts-expect-error
        this.inputs = this.getRoot().querySelectorAll<HTMLInputElement>(this.constructor.cn.setElem('input').toSelector());

        this.callbackConnection();
    }

    onSubmit() {}

    callbackConnection() {
        this.focus();

        if (this.inputs.length === 1) {
            const maxLength = Number(this.getRoot().getAttribute('data-max-length') ?? 5);

            this.inputs[0].addEventListener('input', evt => {
                const value = (evt.target as HTMLInputElement).value;

                if (value.length === maxLength) {
                    this.handleSubmit();
                }
            });

            return;
        }

        this.inputs.forEach((input, index) => {
            input.addEventListener('beforeinput', evt => {
                this.value = evt.data ?? '';

                if ('.,-+'.includes(evt.data as string)) {
                    evt.preventDefault();
                }

                if (evt.inputType === 'deleteContentBackward') {
                    evt.preventDefault();

                    if (input.value.length === 0) this.focus(index - 1);

                    input.value = '';

                    this.handleSubmit();
                }
            });

            input.addEventListener('input', evt => {
                const value = this.value
                    .split('').filter(this.isNumber).slice(0, this.inputs.length);

                if (value.length > 1) {
                    value.forEach((char, i) => this.inputs[i].value = char);

                    this.handleSubmit();

                    for (let i = 0; i < this.inputs.length; i++) {
                        if (this.inputs[i].value.length === 0) return this.focus(i);
                    }

                    return this.focus(this.inputs.length - 1);
                }

                if (input.value.length === 0) {
                    input.value = value[0];
                } else {
                    input.value = input.value[0];

                    if (this.inputs[index + 1] && (evt as InputEvent).inputType === 'insertText' && ! this.inputs[index + 1].value.length) {
                        this.focus(index + 1);
                    }
                }

                this.handleSubmit();
            });
        });
    }

    handleSubmit() {
        if (! Array.from(this.inputs).every(item => item.value.length)) return;

        this.onSubmit();
    }

    isNumber(char: string) {
        return ! isNaN(parseInt(char, 10));
    }

    focus(index: number = 0) {
        this.inputs[index]?.focus();
    }

    reset() {
        this.inputs.forEach(input => input.value = '');
    }
}
