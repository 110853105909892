import { Connect, MemoSelect } from '@gate31/core/src/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isOpenFilters } from '@gate31/feature/Filters';
import { fetchStatusIsSuccess, pages, fetchStatus } from '@gate31/feature/Collection';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { MindboxApi } from '@gate31/core/src/api/mindboxApi';
import { ProductVideoDesk } from '@gate31/desktop/components/ProductVideoDesk/ProductVideoDesk';
import { SortingDesk } from '../components/SortingDesk/SortingDesk';
import { FiltersDesk } from '../components/FiltersDesk/FiltersDesk';
import { FiltersTriggerDesk } from '../components/FiltersTriggerDesk/FiltersTriggerDesk';
import { CollectionDesk } from '../components/CollectionDesk/CollectionDesk';
import { PaginationDesk } from '../components/PaginationDesk/PaginationDesk';
import { DesktopState, desktopStore } from './store';

interface SearchParamInt {
    page: { page: number; pageSize: number };
    filters: Record<string, string | string[]>;
    order?: string;
}

/* eslint-disable */

const selectorFetchStatus = createSelector([ fetchStatus ],(fetchStatus) => {
    return {
        fetchStatus
    };
});
const selectorIsOpenFilters = createSelector([ isOpenFilters ],(isOpenFilters) => {
    return {
        isOpenFilters
    };
});
const selectorChangePages = createSelector([ fetchStatusIsSuccess, pages ],(fetchStatusIsSuccess, pages) => {
    return {
        fetchStatusIsSuccess,
        pages
    };
});
/* eslint-unable */

@Connect(desktopStore)
export class CollectionPage {
    static cn = new Base.BuildCn('CollectionPage');

    private readonly headerWrapper: HTMLElement;
    private readonly filters = new FiltersDesk('filters-desk-template');

    // eslint-disable-next-line new-cap
    private readonly sorting = new SortingDesk(SortingDesk.querySelector<HTMLButtonElement>(SortingDesk.cn.toSelector()));
    // eslint-disable-next-line new-cap
    private readonly filterTrigger = new FiltersTriggerDesk(FiltersTriggerDesk.querySelector<HTMLButtonElement>(FiltersTriggerDesk.cn.toSelector()));
    // eslint-disable-next-line new-cap
    private readonly collection = new CollectionDesk(CollectionDesk.querySelector<HTMLDivElement>(CollectionDesk.cn.toSelector()));
    // eslint-disable-next-line new-cap
    private readonly pagination = new PaginationDesk(PaginationDesk.querySelector<HTMLDivElement>(PaginationDesk.cn.toSelector()))

    constructor(headerWrapper: HTMLElement) {
        this.headerWrapper = headerWrapper;

        const id = this.collection.id;

        if (id) MindboxApi.categoryView(id);

        this.pagination.onSubmit = () => {
            const state = desktopStore.getState();

            const prevCurrentPage = state.collection.currentPage;

            desktopStore.actions.setCurrentPage(prevCurrentPage + 1);

            this.onSearch({
                page: {
                    page: prevCurrentPage + 1,
                    pageSize: state.collection.pageSize,
                },
                filters: state.filters.state,
                order: state.collection.sorting.order
            });

            this.updateCollectionUrl({
                ...state.filters.state,
                page: prevCurrentPage + 1,
                page_size: state.collection.pageSize,
                order: state.collection.sorting.order
            })
        }

        this.sorting.onSubmit = () => {
            const state = desktopStore.getState();

            desktopStore.actions.setCurrentPage( 1);

            const urlObj: any = {
                ...state.filters.state,
                page: 1,
                page_size: state.collection.pageSize
            }

            if (state.collection.sorting.order) {
                urlObj.order = state.collection.sorting.order
            }

            this.updateCollectionUrl(urlObj)
            this.filters.destroyElement();
            this.onSearch({
                page: {
                    page: 1,
                    pageSize: state.collection.pageSize,
                },
                filters: state.filters.state,
                order: state.collection.sorting.order
            });
        };
        this.filters.onSubmit = () => {
            const state = desktopStore.getState();

            this.sorting.reset();
            desktopStore.actions.setCurrentPage( 1);
            desktopStore.actions.filtersToggleOpen();

            this.updateCollectionUrl({
                ...state.filters.state,
                page: 1,
                page_size: state.collection.pageSize
            })
            this.onSearch({
                page: {
                    page: 1,
                    pageSize: state.collection.pageSize,
                },
                filters: state.filters.state
            });
        };
        this.filters.onReset = () => {
            this.sorting.reset();
            desktopStore.actions.setCurrentPage( 1);
            desktopStore.actions.filtersToggleOpen();
            desktopStore.actions.setFilterState({});

            const state = desktopStore.getState();

            this.updateCollectionUrl({
                page: 1,
                page_size: state.collection.pageSize
            })
            this.onSearch({
                page: {
                    page: 1,
                    pageSize: state.collection.pageSize,
                },
                filters: {}
            });
        };

        ProductVideoDesk.init();
    }

    @MemoSelect(selectorChangePages)
    onChangePages(state: DesktopState) {
        const { fetchStatusIsSuccess } = selectorChangePages(state);

        if (fetchStatusIsSuccess) {
            this.pagination.updatePagination();
            this.pagination.updatePaginationLinks();
        }
    }

    @MemoSelect(selectorIsOpenFilters)
    openFilters(state: DesktopState) {
        const isOpen = state.filters.isOpen;

        if (isOpen) {
            this.filters.openFilters({ container: this.headerWrapper, state });
        } else {
            this.filters.destroyElement();
        }
    }

    @MemoSelect(selectorFetchStatus)
    onLoadingStatus(state: DesktopState) {
        const { fetchStatus } = selectorFetchStatus(state);

        if (fetchStatus === 'loading') {
            this.showLoader();
        }

        if (fetchStatus === 'succeeded') {
            this.hideLoader();
        }
    }

    showLoader() {
        const loader = document.querySelector(CollectionPage.cn.setElem('loader').toSelector());

        loader?.classList.add(CollectionPage.cn.setElem('loader').setMode({ active: true }).toString());
    }

    hideLoader() {
        const loader = document.querySelector(CollectionPage.cn.setElem('loader').toSelector());

        loader?.classList.remove(CollectionPage.cn.setElem('loader').setMode({ active: true }).toString());
    }

    onStoreChange() {}

    onSearch(params: SearchParamInt) {
        desktopStore.actions.fetchProductCollection({
            page: { page_size: params.page.pageSize, page: params.page.page },
            filters: params.filters,
            order: params.order ? params.order : '',
            strategy: 'REFETCH'
        });
    }

    updateCollectionUrl(paramsState: Record<string, string[] | string | number>) {
        desktopStore.actions.replace({
            pathname: window.location.pathname,
            query: paramsState
        })
    }
}
