import { Base } from '@gate31/core/src/libs/BaseComponent';

interface ScrollTopOptions {
    triggerTop?: number;
}

export class ScrollTopDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ScrollTopDesk');

    triggerTop: number;
    activeClass: string;

    constructor(node: HTMLElement, options?: ScrollTopOptions) {
        super(node);

        this.triggerTop = options?.triggerTop ?? window.outerHeight;
        this.activeClass = ScrollTopDesk.cn.setMode({ active: true }).toString();

        this.connectionCallback();
    }

    connectionCallback() {
        if (! this.getRoot()) return;

        this.toggleVisible();

        window.addEventListener('scroll', () => {
            this.toggleVisible();
        });

        this.getRoot().addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    }

    toggleVisible() {
        const currentTop = document.body.scrollTop || document.documentElement.scrollTop;

        if (currentTop > this.triggerTop) {
            this.getRoot().classList.add(this.activeClass);
        } else {
            this.getRoot().classList.remove(this.activeClass);
        }
    }
}
