import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';

export const DEFAULT_TEXT = {
    SUCCESS_TITLE: 'Отзыв отправлен',
    ERROR_TITLE: 'Ошибка',
    SUCCESS_MESSAGE: 'Спасибо за отзыв! Как только наши модераторы проверят, отзыв будет добавлен на сайт.',
    ERROR_MESSAGE: 'При отправке отзыва произошла ошибка. Пожалуйста, повторите попытку.',
    RATING_MESSAGE: 'Укажите рейтинг.'
};

export interface ReviewsOpts {
    reviewsResultWrap: HTMLDivElement | null;
    reviewsFormWrap: HTMLDivElement | null;
}

export class Reviews {
    reviewsResultWrap: HTMLDivElement | null;
    reviewsFormWrap: HTMLDivElement | null;

    constructor(opts: ReviewsOpts) {
        this.reviewsResultWrap = opts.reviewsResultWrap;
        this.reviewsFormWrap = opts.reviewsFormWrap;
    }

    renderResult(opts: { title: string; message: string; isError: boolean }, time: number) {
        if (! this.reviewsFormWrap || ! this.reviewsResultWrap) {
            return;
        }

        const templateMessage = LiquidRender.render('reviews-result-template', {
            title: opts.title,
            message: opts.message,
            error: opts.isError
        });

        this.reviewsFormWrap.classList.add('hidden');
        this.reviewsResultWrap.innerHTML = templateMessage;

        if (time <= 0) {
            return;
        }

        setTimeout(() => {
            (this.reviewsFormWrap as HTMLDivElement).classList.remove('hidden');
            (this.reviewsResultWrap as HTMLDivElement).innerHTML = '';
        }, time);
    }
}
