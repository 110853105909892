import { Base } from '@gate31/core/src/libs/BaseComponent';
import Client from '@gate31/uikit/common/components/client/client';
import { ReviewsApi } from '@gate31/core/src/api/MneniyaApi';
import InputImages from '@gate31/uikit/common/components/input-images/input-images';
import { DEFAULT_TEXT } from '@gate31/feature/reviews/reviews';

class ReviewsFormError extends Error {
    name = 'ReviewsFormError';
}

export class ReviewsForm extends Base<HTMLFormElement> {
    static cn = new Base.BuildCn('ReviewsForm');

    api: ReviewsApi;
    inputImages: InputImages;
    inputImagesWrap: HTMLDivElement | null;
    firstNameInput: HTMLInputElement;
    emailInput: HTMLInputElement;
    ratingInputs: NodeListOf<HTMLInputElement>;
    submitButton: HTMLButtonElement;
    errorWrap: HTMLDivElement | null;

    constructor(node: HTMLFormElement) {
        super(node);

        this.api = new ReviewsApi();
        this.inputImages = new InputImages({ name: 'files' });
        // @ts-expect-error
        this.inputImagesWrap = this.getRoot().querySelector(this.constructor.cn.setElem('files-input').toSelector());
        this.firstNameInput = this.getRoot().querySelector('[name=first-name]') as HTMLInputElement;
        this.emailInput = this.getRoot().querySelector('[name=email]') as HTMLInputElement;
        this.ratingInputs = this.getRoot().querySelectorAll('[name="review[rating]"]');
        // @ts-expect-error
        this.submitButton = this.getRoot().querySelector(this.constructor.cn.setElem('submit').toSelector()) as HTMLButtonElement;
        // @ts-expect-error
        this.errorWrap = this.getRoot().querySelector(this.constructor.cn.setElem('error').toSelector());

        this.connectionCallback();
    }

    connectionCallback() {
        this.submitButton.disabled = true;

        this.ratingInputs.forEach(rating => {
            rating.addEventListener('change', () => this.validateSubmit());
        });
        this.firstNameInput.addEventListener('input', () => this.validateSubmit());
        this.emailInput.addEventListener('input', () => this.validateSubmit());

        this.renderInputImages();

        Client.get().then(client => {
            if (client.status !== 'ok') return;

            const { name, email } = client.client;

            if (name) this.firstNameInput.value = name;
            if (email) this.emailInput.value = email;

            this.validateSubmit();
        });

        this.getRoot().addEventListener('submit', async e => {
            e.preventDefault();

            const formData = new FormData((this.getRoot() as HTMLFormElement));

            this.toggleDisableSubmitButton(true);

            if (! this.isValid(formData)) return;

            await this.sendForm(formData)
                .then(() => {
                    this.onSubmit(formData);
                    this.reset();
                })
                .catch((error: Error) => {
                    this.toggleDisableSubmitButton(false);
                    this.renderError(error);
                });
        });
    }

    // eslint-disable-next-line
    onSubmit(formData: FormData) {}

    isValid(formData: FormData) {
        return ((formData.get('first-name') !== '') && (formData.get('email') !== '') && (Number(formData.get('review[rating]')) > 0));
    }

    renderError(error?: Error) {
        if (! this.errorWrap) return;

        // @ts-expect-error
        const errorActiveClass = this.constructor.cn.setElem('error').setMode({ active: true }).toString();

        if (error?.message) {
            this.errorWrap.textContent = error.message;
        }

        this.errorWrap.classList.add(errorActiveClass);

        setTimeout(() => {
            this.errorWrap?.classList.remove(errorActiveClass);
        }, 3000);
    }

    validateSubmit() {
        const formData = new FormData((this.getRoot() as HTMLFormElement));

        this.toggleDisableSubmitButton(! this.isValid(formData));
    }

    toggleDisableSubmitButton(isDisabled: boolean) {
        this.submitButton.disabled = isDisabled;
    }

    reset() {
        this.getRoot().reset();
        this.inputImages.resetInput();
        this.inputImages.renderImages();
        this.validateSubmit();
    }

    async sendForm(formData: FormData) {
        if (! this.getRoot()) {
            return Promise.resolve();
        }

        const body = {
            AuthorName: formData.get('first-name'),
            AuthorEmail: formData.get('email'),
            ReviewText: formData.get('comment'),
            Rate: formData.get('review[rating]'),
            Date: String(new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]),
            Recommends: formData.get('recommends') === '' ? 'true' : 'false'
        };

        const reviewResponse = await this.api.createReview(body);

        if (reviewResponse?.Message === "Can't add review to database, invalid author email") {
            throw new ReviewsFormError('Неверный адрес электронной почты');
        }

        if (reviewResponse?.Message !== 'Review is added to database and awaiting moderation' || ! reviewResponse.ReviewID) {
            throw new ReviewsFormError(DEFAULT_TEXT.ERROR_MESSAGE);
        }

        if (! this.inputImages.imagesList.filter(Boolean).length) {
            // Картинок нет. Завершаем отправку успешно;
            return reviewResponse;
        }

        const resultSetImages = await this.api.addImageForReview(reviewResponse.ReviewID, this.inputImages.formData);

        if (resultSetImages.Message !== 'Files are uploaded successfully. ') {
            throw new ReviewsFormError('Фото не было отправлено');
        }

        return reviewResponse;
    }

    renderInputImages(): void {
        this.inputImagesWrap?.append(this.inputImages.template);
    }
}
