import { WarehouseResponseWrap } from '@gate31/types/warehouse';
import { OrderStatus } from '@gate31/types/order-statuses';
import { stringify } from '../libs/query-string';

export const HOST_GATE31_API = 'https://api.aerogate31.com/gates/';
export const HOST_GATE31_API_V2 = 'https://api.aerogate31.com/v2/';
// export const HOST_GATE31_API = 'http://localhost:4000/gates/';

class Gate31ApiError extends Error { name = 'ApiGate31Error' }

interface params {
    [key: string]: string;
}
interface body {
    // eslint-disable-next-line
    [key: string]: body | any;
}
interface headers {
    'Content-Type'?: string;
    Authorization?: string;
}

export interface UpdateAccountBodyInt {
    userId: number;
    userName?: string;
    userSurname?: string;
    userPatronymic?: string;
    userPhone: string;
    userEmail: string;
    userDateOfBirthday?: string;
    passwordInfo?: {
        isUpdate: boolean;
        newPassword: string;
        newPasswordConfirmation: string;
    };
    userFavorites?: string;
}
export interface UpdateAccountResponseErrorInt {
    error: {
       code: string;
       message: string;
    };
}
export interface UpdateAccountResponseInt {
    response: 'ok';
}

interface CreateAccountBody {
    email: string;
    phone: string;
    dateOfBirth: string | null;
    name: {
        firstName : string;
        lastName: string | null;
    };
    password: string | null;
    endpoint: {
        shop: string;
    };
    subscriptions: {
        email: boolean;
        phone: boolean;
    };
}

export default class Gate31Api {
    static headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    }

    static promoCodeActivation(body: { code: string; phone: string; name: string; lastName: string; email: string }) {
        return fetch(`${HOST_GATE31_API_V2}maxma/promo-code-activation`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: Gate31Api.headers
        })
            .then(response => response.json());
    }

    static configPhoneSendCode({ phone, code }: { phone: string; code: string }) {
        return fetch(`${HOST_GATE31_API_V2}user/confirm-phone/send-code`, {
            method: 'POST',
            body: JSON.stringify({ phoneNumber: phone, code }),
            headers: Gate31Api.headers
        })
            .then(response => response.json());
    }

    static configPhoneGetCode(phone: string) {
        return fetch(`${HOST_GATE31_API_V2}user/confirm-phone/get-code`, {
            method: 'POST',
            body: JSON.stringify({ phoneNumber: phone }),
            headers: Gate31Api.headers
        })
            .then(response => response.json());
    }

    static getCustomStatus(): Promise<{ response: Array<OrderStatus> }> {
        return fetch(`${HOST_GATE31_API}insales-api/custom-statuses`, {
            method: 'GET',
            headers: Gate31Api.headers
        })
            .then(response => response.json())
            .catch(err => {
                throw new Gate31ApiError(`Ошибка в обращении к ApiGate - insales-api/custom-statuses - ${err}`);
            });
    }

    static getWarehouseStock(id: string) {
        const params = { id };

        return Gate31Api.get(params, 'warehouse/new-stock', null);
    }

    static updateAccount(body: UpdateAccountBodyInt): Promise<UpdateAccountResponseInt | UpdateAccountResponseErrorInt> {
        return Gate31Api.post(body, 'update-user/update-user')
            .then(response => {
                return response.json();
            })
            .catch(error => { throw error; });
    }

    static createAccount(body: CreateAccountBody) {
        return Gate31Api.post(body, 'create-user/create-user')
            .then(response => response.json());
    }

    static post(body: body, path: string) {
        const url = `${HOST_GATE31_API}${path}`;

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { ...Gate31Api.headers }
        }).then(res => {
            return res;
        }).catch(error => {
            throw new Gate31ApiError(`Ошибка в обращении к ApiGate - ${url} - ${error}`);
        });
    }

    static get(params: params | null, path: string, headers: headers | null): Promise<WarehouseResponseWrap> {
        const url = `${HOST_GATE31_API}${path}?${stringify({ ...params }, null)}`;

        return fetch(url, {
            method: 'GET',
            headers: { ...headers, ...Gate31Api.headers }
        })
            .then(response => response.json())
            .then((response: WarehouseResponseWrap) => {
                return response;
            })
            .catch(err => {
                throw new Gate31ApiError(`Ошибка в обращении к ApiGate - ${url} - ${err}`);
            });
    }
}
