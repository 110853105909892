import { Base } from '@gate31/core/src/libs/BaseComponent';
import $ from 'jquery';

export class SelectDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('SelectDesk');
    static list = new WeakMap<HTMLElement, SelectDesk>();

    constructor(node: HTMLElement) {
        super(node);

        this.connectedCallback();
    }

    static getOrCreateInstance(Elem: HTMLElement): SelectDesk {
        if (this.list.has(Elem)) {
            return this.list.get(Elem) as SelectDesk;
        }

        const instance = new SelectDesk(Elem);

        this.list.set(Elem, instance);

        return instance;
    }

    static init() {
        $(document).on('click', this.cn.toSelector(), e => {
            const $select = $(e.currentTarget).get(0);

            if (! $select) {
                return;
            }

            this.getOrCreateInstance($select);
        });

        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;

            const isSelectTarget = target.closest(SelectDesk.cn.toSelector());

            if (! isSelectTarget) {
                const activeClass = SelectDesk.cn.setMode({ active: true }).toString();

                document.querySelectorAll(SelectDesk.cn.toSelector()).forEach(select => select.classList.remove(activeClass));
            }
        });
    }

    connectedCallback() {
        this.getRoot().querySelector(SelectDesk.cn.setElem('header').toSelector())?.addEventListener('click', () => {
            this.toggleOpen();
        });
        this.getRoot().querySelector(SelectDesk.cn.setElem('body').toSelector())?.addEventListener('click', e => {
            const target = e.target as HTMLElement;

            if (target.classList.contains(SelectDesk.cn.setElem('item').toString()) || target.closest(SelectDesk.cn.setElem('item').toSelector())) {
                this.changeSelect();
            }
        });
    }

    changeSelect() {
        const label = (this.getCurrentItem() as HTMLInputElement).closest(SelectDesk.cn.setElem('item').toSelector()) as HTMLElement;

        this.updateActiveItem(label);
        this.closeSelect();

        this.onChange();
    }

    onChange = () => {}

    getCurrentItem() {
        return this.getRoot().querySelector<HTMLInputElement>('input:checked');
    }

    getValue() {
        const checkedField = this.getCurrentItem();

        if (! checkedField) {
            return '';
        }

        return checkedField.value;
    }

    setValue(value: string) {
        const currentInput = this.getRoot().querySelector<HTMLInputElement>(`input[value="${value}"]`);

        if (! currentInput) {
            throw new Error(`undefined input by selector: input[value="${value}"]`);
        }

        currentInput.checked = true;

        this.changeSelect();
    }

    updateActiveItem(newActiveItem: HTMLElement) {
        const selectValue = this.getRoot().querySelector(SelectDesk.cn.setElem('header-text').toSelector()) as HTMLElement;
        const activeClassName = SelectDesk.cn.setElem('item').setMode({ active: true }).toString();
        const currentValue = newActiveItem.getAttribute('data-option-value') as string;
        const items = this.getRoot().querySelectorAll(SelectDesk.cn.setElem('item').toSelector());

        items.forEach(item => item.classList.remove(activeClassName));

        newActiveItem.classList.add(activeClassName);
        selectValue.innerHTML = currentValue;
    }

    toggleOpen() {
        const activeClass = SelectDesk.cn.setMode({ active: true }).toString();

        this.getRoot().classList.toggle(activeClass);
    }

    closeSelect() {
        const activeClass = SelectDesk.cn.setMode({ active: true }).toString();

        this.getRoot().classList.remove(activeClass);
    }
}
