import { configureStore } from '@reduxjs/toolkit';
import { routerReducer, routerMiddleware, routerActions, syncHistoryWithStore } from 'react-router-redux';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import useBasename from 'history/lib/useBasename';
import useQueries from 'history/lib/useQueries';
import { headerWrapperSlice } from '@gate31/feature/HeaderWrapper';
import { filtersSlice, fetchFiltersCounter } from '@gate31/feature/Filters';
import { collectionSlice, fetchProductCollection } from '@gate31/feature/Collection';
import { AppStore } from '@gate31/core/src/redux/store';
import { appInitAction } from '@gate31/core/src/redux/init';

const browserHistory = useQueries(useBasename(createBrowserHistory))();

export const appStore = configureStore({
    reducer: {
        [headerWrapperSlice.name]: headerWrapperSlice.reducer,
        [collectionSlice.name]: collectionSlice.reducer,
        [filtersSlice.name]: filtersSlice.reducer,
        routing: routerReducer
    },
    middleware: gDM => gDM().prepend(routerMiddleware(browserHistory))
});

export type DesktopState = ReturnType<typeof appStore.getState>;

export const history = syncHistoryWithStore(browserHistory, appStore);

const actions = {
    init: appInitAction,
    ...routerActions,
    ...headerWrapperSlice.actions,
    ...filtersSlice.actions,
    ...collectionSlice.actions,
    fetchFiltersCounter,
    fetchProductCollection
};

export const desktopStore = new AppStore<DesktopState, typeof actions>(appStore, actions);
