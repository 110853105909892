import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Connect } from '@gate31/core/src/redux/store';
import { desktopStore } from '@gate31/desktop/scripts/store';

@Connect(desktopStore)
export class HeaderWrapperDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('header-wrapper');
    private observer: MutationObserver;
    private config: MutationObserverInit;

    constructor(node: HTMLDivElement) {
        super(node);

        this.config = {
            attributes: true,
            subtree: true
        };
        this.connectionCallback();
    }

    private callback: MutationCallback = mutationsList => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'attributes') {
                desktopStore.actions.setCurrentHeight(this.getRoot().clientHeight);
            }
        }
    }

    connectionCallback() {
        this.observer = new window.MutationObserver(this.callback);
        this.observer.observe(this.getRoot(), this.config);
        desktopStore.actions.setCurrentHeight(this.getRoot().clientHeight);
    }

    onStoreChange() {}
}
