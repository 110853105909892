import $ from 'jquery';
import { Base } from '@gate31/core/src/libs/BaseComponent';

const COOKIE_NAME = 'slim_banner_';
const COOKIE_VALUE = 'hidden';
const DATA_COOKIE_NAME = 'data-cookie-name';

export class SlimBanner extends Base<HTMLDivElement> {
    static readonly cn = new Base.BuildCn('SlimBanner');
    private readonly classActive: string;
    readonly cookieName: string;

    constructor(node: HTMLDivElement) {
        super(node);

        this.classActive = SlimBanner.cn.setMode({ active: true }).toString();
        this.cookieName = COOKIE_NAME + this.getRoot().getAttribute(DATA_COOKIE_NAME) ?? '';
        this.connectedCallback();
    }

    static getHeightForBody() {
        return Array.from(document.querySelectorAll(SlimBanner.cn.toSelector()))
            .reduce((a, e) => a + e.clientHeight ?? 0, 0);
    }

    show() {
        this.getRoot().classList.add(this.classActive);
    }

    hide() {
        this.getRoot().classList.remove(this.classActive);
    }

    close() {
        this.hide();
        $ && $.cookie ? $.cookie(this.cookieName, COOKIE_VALUE, { expires: 7 }) : null;
    }

    // eslint-disable-next-line
    onClose(evt?: MouseEvent) {}

    connectedCallback() {
        if ($.cookie(this.cookieName) === COOKIE_VALUE) return;

        this.show();

        const btnClose = this.getRoot().querySelector<HTMLButtonElement>(SlimBanner.cn.setElem('close-btn').toSelector());

        btnClose?.addEventListener('click', evt => {
            this.close();
            this.onClose(evt);
        });
    }
}
