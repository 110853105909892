import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { FiltersSliceState } from '../Filters';

export abstract class Sorting<S> extends Base<HTMLElement> {
    static readonly cn = new BaseJs.BuildCn('.Sorting');

    protected constructor(node: HTMLElement) {
        super(node);
    }

    abstract onSubmit(filterState: FiltersSliceState['state']): void;
    abstract onStoreChange(state: S, oldState?: S): void;
}
