import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';

export class SearchBoxPopoverDesk extends BaseJs {
    static cn = new BaseJs.BuildCn('SearchBoxDesk');

    container: HTMLDivElement;

    constructor({ container }: { container: HTMLDivElement }) {
        super('search-box-desk-template');

        this.container = container;
    }

    render() {
        this.renderTemplate({
            container: this.container,
            templateData: {
                type: 'modal'
            }
        });
    }

    closeSlowly() {
        if (this.getRoot()) {
            this.getRoot().classList.add(SearchBoxPopoverDesk.cn.setMode({ 'animate-close': true }).toString());
        }
    }

    openSlowly() {
        if (this.getRoot()) {
            this.getRoot().classList.remove(SearchBoxPopoverDesk.cn.setMode({ 'animate-close': true }).toString());
        }
    }
}
