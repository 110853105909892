import $ from 'jquery';
import { createAsyncThunk } from '@reduxjs/toolkit';

// import ajaxAPI from 'ajaxAPI';

export const fetchFiltersCounter = createAsyncThunk<Awaited<number>, {
    filters: Record<string, string[]>;
}>('filters/counter', ({ filters }) => {
    // pending
    const filtersString = Object.keys(filters).map(key => filters[key].map((value: string) => `${key}=${value}&`).join('')).join('');

    return new Promise((resolve, reject) => {
        return $.ajax({
            url: `${window.location.pathname}.json?${filtersString}page=1&page_size=30`
        }).then(data => {
            resolve(data.count);
        }).catch(reject);
    });
});
