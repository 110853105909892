import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { LiquidTemplates } from '@gate31/uikit/common/scripts/liquid-render';

export class PasswordToggleDesk extends BaseJs {
    static cn = new BaseJs.BuildCn('PasswordToggleDesk');

    fields: NodeListOf<HTMLInputElement>;

    constructor(template: keyof LiquidTemplates) {
        super(template);

        this.fields = document.querySelectorAll<HTMLInputElement>('input[type="password"]');

        this.callbackConnection();
    }

    callbackConnection() {
        this.fields.forEach(field => {
            const containerNode = this.getContainer(field);

            containerNode.classList.add(PasswordToggleDesk.cn.setElem('parent').toString());

            this.renderTemplate({
                container: containerNode,
                templateData: {}
            });

            this.addEventListener(containerNode);
        });
    }

    getContainer(field: HTMLInputElement): HTMLElement {
        return field.parentNode as HTMLElement;
    }

    addEventListener(container: HTMLElement) {
        const buttonContainer = container.querySelector(PasswordToggleDesk.cn.toSelector());
        const input = container.querySelector('input');

        buttonContainer?.addEventListener('click', () => {
            buttonContainer?.classList.toggle(PasswordToggleDesk.cn.setMode({ hide: true }).toString());

            if (buttonContainer?.classList.contains(PasswordToggleDesk.cn.setMode({ hide: true }).toString())) {
                input?.setAttribute('type', 'password');
            } else {
                input?.setAttribute('type', 'text');
            }
        });
    }
}
