import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Connect } from '@gate31/core/src/redux/store';
import { desktopStore } from '@gate31/desktop/scripts/store';
import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';
import { paginationObj } from '@gate31/uikit/common/components/pagination/pagination';
import { Pagination } from '@gate31/feature/Pagination';
import { currentPage, pages } from '@gate31/feature/Collection';
import { createSelector } from '@reduxjs/toolkit';

/* eslint-disable */
const selectPageParams = createSelector([currentPage, pages], (currentPage, pages) => {
    return {
        currentPage,
        pages
    };
});
/* eslint-unable */

@Connect(desktopStore)
export class PaginationDesk extends Pagination {
    static cn = new Base.BuildCn('PaginationDesk');

    private readonly loadMoreBtn: HTMLLinkElement | null;
    private readonly linkListPagination: HTMLDivElement | null;
    private readonly hostUrl: string;

    constructor(node: HTMLDivElement) {
        super(node);

        this.loadMoreBtn = this.findElem<HTMLLinkElement>(PaginationDesk.cn.setElem('btn').toSelector());
        this.linkListPagination = this.findElem<HTMLDivElement>(PaginationDesk.cn.setElem('links-wrap').toSelector());
        this.hostUrl = window.location.origin + window.location.pathname;

        this.connectedCallback();
    }

    onStoreChange() {}

    onSubmit = () => {}

    connectedCallback() {
        this.loadMoreBtn?.addEventListener('click', evt => {
            evt.preventDefault();

            this.disabledButton();
            this.onSubmit();
        });
    }

    disabledButton() {
        this.loadMoreBtn?.classList.add(PaginationDesk.cn.setElem('no-pointer').toString());
    }

    showPagination() {
        this.getRoot().classList.remove(PaginationDesk.cn.setMode({ hidden: true }).toString())
    }

    hiddenPagination() {
        this.getRoot().classList.add(PaginationDesk.cn.setMode({ hidden: true }).toString())
    }

    showPaginationLoadMoreButton() {
        this.loadMoreBtn?.classList.remove(PaginationDesk.cn.setElem('btn').setMode({ hidden: true }).toString())
    }

    hiddenPaginationLoadMoreButton() {
        this.loadMoreBtn?.classList.add(PaginationDesk.cn.setElem('btn').setMode({ hidden: true }).toString())
    }

    updatePagination() {
        const { currentPage = 1, pages = 1 } = desktopStore.getState().collection;

        if (pages > 1) {
            this.showPagination()

            if (currentPage < pages) {
                this.showPaginationLoadMoreButton();
            } else {
                this.hiddenPaginationLoadMoreButton();
            }
        } else {
            this.hiddenPagination();
        };
    }

    updatePaginationLinks() {
        //@ts-ignore
        const { currentPage = 1, pages = 1, pageSize = 30 } = desktopStore.getState().collection;
        const filterState = desktopStore.getState().filters.state;
        const sortingState = desktopStore.getState().collection.sorting;
        const filterAndSortingState = { ...filterState, ...sortingState } as Record<string, string[]>;

        const paramsObj: {
            [p: string]: number | string | string[];
        } = {
            page_size: pageSize,
            ...filterState,
        }

        if (sortingState.order) {
            paramsObj.order = sortingState.order
        }

        const nextParamsObj = {
            ...paramsObj,
            page: currentPage + 1
        }

        const prevParamsObj = {
            ...paramsObj,
            page: currentPage - 1
        }

        const paginate = {
            size: 'm',
            paginate: {
                current_offset: currentPage - 1,
                current_page: currentPage,
                previous: currentPage - 1 ? {
                    title: '&#60;', url: `${this.getUrlByParams(prevParamsObj, this.hostUrl)}`, is_link: true
                } : undefined,
                next: currentPage + 1 <= pages ? {
                    title: '&#62;', url: `${this.getUrlByParams(nextParamsObj, this.hostUrl)}`, is_link: true
                } : undefined,
                page_size: pageSize,
                pages,
                parts: paginationObj({
                    pages,
                    curePage: currentPage,
                    filterState: filterAndSortingState,
                    hostUrl: this.hostUrl
                })
            }
        };

        const paginatorHTML = LiquidRender.render('pagination-template', paginate);

        if (this.linkListPagination) {
            this.linkListPagination.innerHTML = paginatorHTML;
        }

        this.loadMoreBtn?.classList.remove(PaginationDesk.cn.setElem('no-pointer').toString());
        this.toggleHiddenLoadMoreBtn();
    }

    toggleHiddenLoadMoreBtn() {
        const className = PaginationDesk.cn.setElem('hidden').toString();
        //@ts-ignore
        const { page, pages } = desktopStore.getState().collection.currentPage;

        if (page >= pages) {
            this.loadMoreBtn?.classList.add(className);
        } else {
            this.loadMoreBtn?.classList.remove(className);
        }
    }
}
