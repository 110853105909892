import { CustomCn } from './classname';

export class Base<ElEM extends HTMLElement> {
    static elements = new WeakMap<HTMLElement, any>();
    static BuildCn = CustomCn;

    readonly node: ElEM;

    constructor(node: ElEM) {
        this.node = node;
        Base.elements.set(node, this);
    }

    getRoot(): ElEM {
        return this.node;
    }

    findElem<Elem extends Element = HTMLElement>(className: string): Elem {
        // const elem = this?.ref?.deref()?.querySelector<Elem>(className);
        const elem = this.getRoot().querySelector<Elem>(className);

        if (elem) {
            return elem;
        }

        throw new Error('elem not found ' + className);
    }

    static querySelector<E extends HTMLElement>(selector: string) {
        const elem = document.querySelector<E>(selector);

        if (! elem) {
            throw new Error('element not found ' + selector);
        }

        return elem;
    }

    static querySelectorAll<E extends HTMLElement>(selector: string) {
        const elems = document.querySelectorAll<E>(selector);

        if (! elems) {
            throw new Error('element not found ' + selector);
        }

        return elems;
    }
}
