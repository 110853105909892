import Swiper from 'swiper';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { SnippetProductDesk } from '../SnippetProductDesk/SnippetProductDesk';

export class ProductsSliderDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ProductsSliderDesk');

    constructor(node: HTMLElement) {
        super(node);
    }

    static init() {
        // eslint-disable-next-line
        new Swiper(ProductsSliderDesk.cn.setElem('slider').toSelector(), {
            loop: true,
            slidesPerView: 4,
            spaceBetween: 8,
            navigation: {
                nextEl: ProductsSliderDesk.cn.setElem('next-btn').toSelector(),
                prevEl: ProductsSliderDesk.cn.setElem('prev-btn').toSelector()
            },
            on: {
                // Выравнимаем положение кнопок переключения слайдов
                // eslint-disable-next-line
                afterInit: function() {
                    const root = (this as { $el: Array<HTMLElement> }).$el[0];

                    const buttons = root.querySelectorAll<HTMLDivElement>(ProductsSliderDesk.cn.setElem('btn').toSelector());
                    const view = root.querySelector(SnippetProductDesk.cn.setElem('view').toSelector());

                    const rootHeight = root ? root.clientHeight : 0;
                    const viewHeight = view ? view.clientHeight : 0;
                    const offset = (rootHeight - viewHeight) / 2;

                    buttons.forEach(button => {
                        button.style.top = `calc(50% - ${offset}px)`;
                    });
                }
            }
        });
    }
}
