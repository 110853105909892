import EventBus from 'EventBus';
import { MindboxApi, serCartBody } from '@gate31/core/src/api/mindboxApi';
import type { Product } from '@gate31/types/product';

export default class FavoriteDesk {
    private static FavoritesProducts = window.FavoritesProducts;

    constructor() {}

    static getItems(): Promise<Product[]> {
        return new Promise((resolve, reject) => {
            window.ajaxAPI.favorites.get().done((data: { products: Product[] }) => resolve(data.products)).fail(reject);
        });
    }

    static update() {
        FavoriteDesk.FavoritesProducts.update();
    }

    static getIdList(): Promise<Array<number>> {
        return FavoriteDesk.getItems().then(products => products.map(product => product.id));
    }

    // Получаем состояние - меняем состояние
    static add(id: number) {
        FavoriteDesk.FavoritesProducts.add({ item: id });

        return FavoriteDesk.getIdList();
    }

    // Получаем состояние - меняем состояние
    static remove(id: number) {
        FavoriteDesk.FavoritesProducts.remove({ item: id });

        return FavoriteDesk.getIdList();
    }

    static init() {
        EventBus.subscribe('update_items:insales:favorites_products', data => {
            const body: serCartBody = {
                productList: data.products.map(product => {
                    return {
                        product: {
                            ids: {
                                gate31: product.id
                            }
                        },
                        count: 1,
                        pricePerItem: parseFloat(product.variants[0].base_price)
                    };
                })
            };

            MindboxApi.setFavorite(body);
        });
    }
}

// Глобальная видимость для any-query поиска
window.Favorite = {
    add: FavoriteDesk.add,
    remove: FavoriteDesk.remove,
    getIdList: FavoriteDesk.getIdList
};
