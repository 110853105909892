import { CustomCn } from '@gate31/core/src/libs/classname';
import { validateEmail } from '@gate31/uikit/common/components/velidation/validation';
import { MindboxApi } from '@gate31/core/src/api/mindboxApi';
import { mindboxSubscribeInfo } from '@gate31/types/mindbox';
import { EventEmitter } from '@gate31/uikit/common/scripts/component-event-emitter';

export class SubscribeDesk {
    static cn = new CustomCn('SubscribeDesk');

    root: HTMLFormElement;
    input: HTMLInputElement;
    button: HTMLButtonElement;
    variantsFields: NodeListOf<HTMLInputElement>;

    errorContainer: HTMLDivElement;
    contentContainer: HTMLDivElement;
    resultContainer: HTMLDivElement;

    dataAttrSettingHidden: string;
    ee: EventEmitter;

    constructor(root: HTMLFormElement) {
        this.root = root;
        this.input = this.root.querySelector<HTMLInputElement>(SubscribeDesk.cn.setElem('input').toSelector()) as HTMLInputElement;
        this.button = this.root.querySelector<HTMLInputElement>(SubscribeDesk.cn.setElem('button').toSelector()) as HTMLButtonElement;
        this.variantsFields = this.root.querySelectorAll(SubscribeDesk.cn.setElem('variants').toSelector() + ' input');

        this.errorContainer = this.root.querySelector(SubscribeDesk.cn.setElem('message').setMode({ error: true }).toSelector()) as HTMLDivElement;
        this.contentContainer = this.root.querySelector(SubscribeDesk.cn.setElem('content').toSelector()) as HTMLDivElement;
        this.resultContainer = this.root.querySelector(SubscribeDesk.cn.setElem('result').toSelector()) as HTMLDivElement;

        this.dataAttrSettingHidden = 'data-settings-hidden-text';
        this.ee = new EventEmitter();

        this.callbackConnection();
    }

    callbackConnection() {
        this.root.addEventListener('submit', e => {
            e.preventDefault();

            this.handleForm();
        });

        this.root.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            const isHiddenText = this.getSettingsIsHiddenText();

            if (target.closest(SubscribeDesk.cn.setElem('input').toSelector()) && isHiddenText) {
                this.showInfoText();
            } else {
                // this.hideInfoText(); // todo Нужно ли убирать текст?
            }
        });

        this.input.addEventListener('input', e => {
            const target = e.target as HTMLInputElement;

            if (target.value !== '') {
                this.unableButton();
            } else {
                this.disableButton();
            }
        });
    }

    resetForm() {
        this.input.value = '';

        this.variantsFields.forEach(field => {
            field.checked = false;
        });
    }

    async handleForm() {
        const formData = new FormData(this.root);

        if (! validateEmail(this.input.value)) {
            return this.showError({
                message: 'Некорректный адрес.'
            });
        }

        const isAvailableSubscribe = await this.examinationAvailableSubscribe(this.input.value)
            .then(available => {
                if (available) {
                    this.showError({
                        message: 'Вы уже подписаны на рассылку.'
                    });

                    return true;
                }

                return false;
            })
            .catch(() => {
                this.showError({
                    message: 'Произошла ошибка. Повторите действие позже.'
                });

                return false;
            });

        if (! isAvailableSubscribe) {
            const subscribeBody: {
                customer: {
                    email: string;
                    subscriptions: Array<{
                        brand: 'gate31';
                        pointOfContact: 'Email';
                        topic?: string;
                    }>;
                };
            } = {
                customer: {
                    email: formData.get('EMAIL') as string,
                    subscriptions: []
                }
            };

            if (formData.get('subscription__var_men') && ! formData.get('subscription__var_woman')) {
                subscribeBody.customer.subscriptions.push({
                    brand: 'gate31',
                    pointOfContact: 'Email',
                    topic: 'gate31men'
                });
            }

            if (formData.get('subscription__var_woman') && ! formData.get('subscription__var_men')) {
                subscribeBody.customer.subscriptions.push({
                    brand: 'gate31',
                    pointOfContact: 'Email',
                    topic: 'gate31women'
                });
            }

            if (! subscribeBody.customer.subscriptions.length) {
                subscribeBody.customer.subscriptions.push({
                    brand: 'gate31',
                    pointOfContact: 'Email'
                });
            }

            MindboxApi.subscribe(subscribeBody, null, null)
                .then(() => {
                    this.showSuccessResult();
                    this.ee.emit('subscription-form:success-message');

                    setTimeout(() => {
                        this.hideSuccessResult();
                        this.ee.emit('subscription-form:success');
                        this.resetForm();
                    }, 5000);
                })
                .catch(() => {
                    return this.showError({
                        message: 'Произошла ошибка. Повторите действие позже.'
                    });
                });
        } else {
            return;
        }
    }

    showError({ message, timeout }: { message: string; timeout?: number }) {
        const activeClass = SubscribeDesk.cn.setElem('message').setMode({ active: true }).toString();

        this.errorContainer.innerHTML = message;
        this.errorContainer.classList.add(activeClass);

        setTimeout(() => {
            this.errorContainer.classList.remove(activeClass);
        }, timeout || 3000);
    }

    getSettingsIsHiddenText(): boolean {
        return this.root.getAttribute(this.dataAttrSettingHidden) === 'true';
    }

    showInfoText() {
        this.root.classList.add(SubscribeDesk.cn.setMode({ active: true }).toString());
    }

    hideInfoText() {
        this.root.classList.remove(SubscribeDesk.cn.setMode({ active: true }).toString());
    }

    disableButton() {
        this.button.setAttribute('disabled', 'true');
    }

    unableButton() {
        this.button.removeAttribute('disabled');
    }

    examinationAvailableSubscribe(email: string): Promise<boolean | undefined> {
        return MindboxApi.getUserSubscribeList(email)
            .then((data: mindboxSubscribeInfo) => {
                if (! data.customer || data.customer && data.customer.processingStatus === 'NotFound') {
                    // Если пользователя нет в системе то он не подписан
                    return false;
                }

                if (data.customer && data.customer.processingStatus === 'Found') {
                    if (! data.customer.subscriptions.length) {
                        return false;
                    }

                    return Boolean(data.customer.subscriptions.find(item => item.isSubscribed && item.pointOfContact === 'Email'));
                }
            })
            .catch(() => {
                return false;
            });
    }

    showSuccessResult() {
        const resultActiveClass = SubscribeDesk.cn.setElem('result').setMode({ active: true }).toString();
        const contentHiddenClass = SubscribeDesk.cn.setElem('content').setMode({ hidden: true }).toString();

        this.resultContainer.classList.add(resultActiveClass);
        this.contentContainer.classList.add(contentHiddenClass);
    }

    hideSuccessResult() {
        const resultActiveClass = SubscribeDesk.cn.setElem('result').setMode({ active: true }).toString();
        const contentHiddenClass = SubscribeDesk.cn.setElem('content').setMode({ hidden: true }).toString();

        this.resultContainer.classList.remove(resultActiveClass);
        this.contentContainer.classList.remove(contentHiddenClass);
    }
}
