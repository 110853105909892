import $ from 'jquery';
import { CustomCn } from '@gate31/core/src/libs/classname';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import EventBus from 'EventBus';

export class HeaderDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('HeaderDesk');

    cnHeaderModal: CustomCn;
    cnHeaderModalMenuList: CustomCn;

    $headerModal: HTMLDivElement | null;
    $navItemsParent: NodeListOf<HTMLLinkElement>;

    private readonly menuHandleAttr = 'data-menu-handle';

    constructor(node: HTMLDivElement) {
        super(node);

        this.cnHeaderModal = HeaderDesk.cn.setElem('modal');
        this.cnHeaderModalMenuList = HeaderDesk.cn.setElem('modal-menu-list');

        this.$headerModal = this.getRoot().querySelector<HTMLDivElement>(this.cnHeaderModal.toSelector());
        this.$navItemsParent = this.getRoot().querySelectorAll<HTMLLinkElement>(HeaderDesk.cn.setElem('nav-item').setMode({ parent: true }).toSelector());

        this.headerModalHandle();
        this.toggleShowCartCounter();
    }

    toggleShowCartCounter() {
        const cartToolNode = HeaderDesk.querySelector(HeaderDesk.cn.setElem('tools-item').setMode({ cart: true }).toSelector());
        const cartCounterNode = cartToolNode.querySelector(HeaderDesk.cn.setElem('tools-counter').toSelector());

        EventBus.subscribe('update_items:insales:cart', data => {
            if (data.items_count > 0) {
                cartCounterNode?.classList.add(HeaderDesk.cn.setElem('tools-counter').setMode({ active: true }).toString());
            } else {
                cartCounterNode?.classList.remove(HeaderDesk.cn.setElem('tools-counter').setMode({ active: true }).toString());
            }
        });
    }

    // eslint-disable-next-line
    openMenu(event: JQuery.MouseOverEvent | Event) {
        const target = event.target as HTMLDivElement;
        const targetItem = target.closest<HTMLLIElement>(HeaderDesk.cn.setElem('nav-item').setMode({ parent: true }).toSelector());
        const targetMenuHandle = targetItem?.getAttribute(this.menuHandleAttr);

        const menuList = this.getRoot().querySelectorAll(this.cnHeaderModalMenuList.toSelector());

        if (! menuList) {
            throw new Error('Не определены области меню в модальном окне');
        }

        menuList.forEach(menuItem => {
            if (targetMenuHandle === menuItem.getAttribute(this.menuHandleAttr)) {
                menuItem.classList.add(this.cnHeaderModalMenuList.setMode({ active: true }).toString());
            } else {
                menuItem.classList.remove(this.cnHeaderModalMenuList.setMode({ active: true }).toString());
            }
        });
    }

    openModal() {
        this.$headerModal?.classList.add(this.cnHeaderModal.setMode({ active: true }).toString());
    }

    closeModal() {
        this.$headerModal?.classList.remove(this.cnHeaderModal.setMode({ active: true }).toString());
    }

    headerModalHandle() {
        const navItemParent = $(HeaderDesk.cn.setElem('nav-item').setMode({ parent: true }).toSelector());

        this.$headerModal?.addEventListener('mouseover', () => {
            this.openModal();
        });

        this.$headerModal?.addEventListener('mouseout', () => {
            this.closeModal();
        });

        navItemParent.on('mouseover', event => {
            this.openModal();
            this.openMenu(event);
        });

        navItemParent.on('mouseout', () => {
            this.closeModal();
        });
    }
}
