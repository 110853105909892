import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { Stats } from '@gate31/types/ReviewsTypes';

interface ReviewsStatsOpts {
    container: HTMLElement;
    template: 'reviews-stats-desk-template';
}

export class ReviewsStats extends BaseJs {
    static cn = new BaseJs.BuildCn('ReviewsStats');

    container: HTMLElement;

    constructor({ container, template }: ReviewsStatsOpts) {
        super(template);

        this.container = container;
    }

    render(stats: Stats) {
        const rating = (stats.RatesTotalSum / stats.ReviewsTotalCount).toFixed(1);

        return this.renderTemplate({
            container: this.container,
            templateData: {
                rating,
                total: stats.FilteredReviewsTotalCount?.toLocaleString('ru')
            }
        });
    }
}
