import { Base } from '@gate31/core/src/libs/BaseComponent';
import { CustomCn } from '@gate31/core/src/libs/classname';
import { SubscribeDesk } from '../SubscribeDesk/SubscribeDesk';

export class FooterDesk extends Base<HTMLElement> {
    static cn = new CustomCn('FooterDesk');

    constructor(node: HTMLElement) {
        super(node);

        // eslint-disable-next-line
        new SubscribeDesk(this.getRoot().querySelector(SubscribeDesk.cn.toSelector()) as HTMLFormElement);
    }
}
