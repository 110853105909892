import { Base } from '@gate31/core/src/libs/BaseComponent';
import { OrderData } from '../AccountClientDesk/AccountClientDesk';
import { AccountOrderDesk } from '../AccountOrderDesk/AccountOrderDesk';

export class AccountOrdersDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('AccountOrdersDesk');

    orders: Array<OrderData>;
    accountOrderDesk: AccountOrderDesk;

    constructor(node: HTMLDivElement, orders: Array<OrderData>) {
        super(node);

        this.orders = orders;
        this.accountOrderDesk = new AccountOrderDesk('account-order-desk-template');

        this.connectionCallback();
    }

    connectionCallback() {
        if (this.orders.length) {
            this.getRoot().innerHTML = this.orders.map(orderDate => {
                return this.accountOrderDesk.getHTML(orderDate);
            }).join('');
        }
    }
}
