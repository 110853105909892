import {createPopper, createPopperBase} from '@popperjs/core';
import LiquidRender, { LiquidTemplates } from "@gate31/uikit/common/scripts/liquid-render";
import { CustomCn } from './classname';

class BaseJsError extends Error {
    name = 'BaseJsError'
}

export class BaseJs {
    static BuildCn = CustomCn;
    static readonly cn = new BaseJs.BuildCn('BaseTemplate')

    template: keyof LiquidTemplates;
    htmlTemplate: string | null;

    constructor(template: keyof LiquidTemplates) {
        this.template = template;
    }

    getRoot(): HTMLElement {
        // @ts-expect-error
        const root = document.querySelector<HTMLElement>(this.constructor.cn.toSelector());

        if (! root) {
            // @ts-expect-error
            throw new BaseJsError(`elem root not found: ${this.constructor.cn.toSelector()}`)
        }

        return root;
    }

    findElem<T extends Element = HTMLElement>(selector: string): T {
        const elem = this.getRoot().querySelector<T>(selector);

        if (! elem) {
            throw new BaseJsError(`elem root not found: ${selector}`)
        }

        return elem;
    }

    destroyElement(): void {
        // @ts-expect-error
        const elem = document.querySelector<HTMLElement>(this.constructor.cn.toSelector());

        if (! elem) {
            return;
        }

        elem.remove();
    }

    getOverlay(html: string): HTMLElement {
        const node = document.createElement('div');

        node.classList.add('overlay')

        node.innerHTML = html;

        return node;
    }

    getHTML(data: any) {
        return LiquidRender.render(this.template, data);
    }

    renderTemplate({ container, popperConfig, templateData }: {
        container?: HTMLElement;
        popperConfig?: { [key: string]: any; },
        templateData: { [key: string]: unknown } | Array<unknown>
    }): HTMLElement {
        this.htmlTemplate = LiquidRender.render(this.template, templateData);

        if (! this.htmlTemplate) {
            throw new BaseJsError(`Template not created: ${this.template}. Use method createHTML`);
        }

        const root = container ?? document.body;

        root.insertAdjacentHTML( 'beforeend', this.htmlTemplate );

        if (popperConfig && container) {
            createPopper(container, this.getRoot(), popperConfig as any);
        }

        return this.getRoot();
    }
}
