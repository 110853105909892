export default class Accordion {
    accordionDataAttr: string;
    accordionHeaderDataAttr: string;
    accordionBodyDataAttr: string;
    accordionStateDataAttr: string;
    constructor() {
        this.accordionDataAttr = 'data-accordion-wrap';
        this.accordionStateDataAttr = 'data-accordion-is-active';
        this.accordionHeaderDataAttr = 'data-accordion-header';
        this.accordionBodyDataAttr = 'data-accordion-body';

        this.init();
    }

    isAccordionHeaderTarget(target: HTMLElement) {
        return Boolean(target.getAttribute(this.accordionHeaderDataAttr));
    }

    getAccordionVariable(target: HTMLElement) {
        return target.getAttribute(this.accordionHeaderDataAttr);
    }

    getCurrentAccordion(accordionVariable: string, target: HTMLElement) {
        return target.closest(`[${this.accordionDataAttr}="${accordionVariable}"]`);
    }

    getStateCurrentAccordion($bodyAccordion: HTMLElement) {
        const stateString = $bodyAccordion?.getAttribute(`${this.accordionStateDataAttr}`);

        if (stateString === 'true') {
            return true;
        }

        return false;
    }

    setStateCurrentAccordion($accordion: HTMLElement, newState: Boolean) {
        $accordion.setAttribute(this.accordionStateDataAttr, String(newState));
    }

    updateStateAccordion(accordionHandle: string, target: HTMLElement) {
        const $accordion = this.getCurrentAccordion(accordionHandle as string, target) as HTMLElement;
        const state = this.getStateCurrentAccordion($accordion);
        const newState = ! state;

        this.setStateCurrentAccordion($accordion, newState);
    }

    init() {
        document.body.addEventListener('click', event => {
            const target = event.target as HTMLElement;

            if (this.isAccordionHeaderTarget(target)) {
                const accordionHandle = this.getAccordionVariable(target);

                if (accordionHandle) {
                    this.updateStateAccordion(accordionHandle, target);
                }
            }
        });
    }
}
