import { Base } from '@gate31/core/src/libs/BaseComponent';

export class SearchBoxDesk {
    static cn = new Base.BuildCn('SearchBoxDesk');

    root: HTMLDivElement | null;

    field: HTMLInputElement;
    closeIcon: HTMLDivElement | null;
    buttonSubmit: HTMLButtonElement;
    buttonReset: HTMLButtonElement | null;
    buttonClose: HTMLButtonElement | null;

    constructor(root: HTMLDivElement | null) {
        this.root = root;

        if (! this.root) {
            return;
        }

        this.field = this.root.querySelector<HTMLInputElement>(SearchBoxDesk.cn.setElem('input').toSelector()) as HTMLInputElement;
        this.closeIcon = this.root.querySelector<HTMLDivElement>(SearchBoxDesk.cn.setElem('icon').setMode({ close: true }).toSelector());
        this.buttonSubmit = this.root.querySelector<HTMLButtonElement>(SearchBoxDesk.cn.setElem('btn-submit').toSelector()) as HTMLButtonElement;
        this.buttonReset = this.root.querySelector<HTMLButtonElement>(SearchBoxDesk.cn.setElem('btn-reset').toSelector());
        this.buttonClose = this.root.querySelector<HTMLButtonElement>(SearchBoxDesk.cn.setElem('btn-close').toSelector());

        this.callbackConnection();
    }

    callbackConnection() {
        this.field.addEventListener('input', () => {
            if (this.field.value) {
                this.openCloseIcon();
            } else {
                this.closeCloseIcon();
            }
        });

        if (this.buttonReset) {
            this.buttonReset.addEventListener('click', () => {
                this.field.value = '';
                this.closeCloseIcon();
            });
        }

        if (this.closeIcon) {
            this.closeIcon.addEventListener('click', () => {
                this.field.value = '';
                this.closeCloseIcon();
            });
        }
    }

    focus() {
        this.field.focus();
    }

    openCloseIcon() {
        this.closeIcon?.classList.remove(SearchBoxDesk.cn.setElem('icon').setMode({ hidden: true }).toString());
    }

    closeCloseIcon() {
        this.closeIcon?.classList.add(SearchBoxDesk.cn.setElem('icon').setMode({ hidden: true }).toString());
    }
}
