import { Base } from '@gate31/core/src/libs/BaseComponent';

export class CartItemDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('CartItemDesk');

    constructor(node: HTMLDivElement) {
        super(node);
    }

    disabledMinus(id: number) {
        const item = document.querySelector(CartItemDesk.cn.toSelector() + `[data-item-id="${id}"]`);

        if (! item) {
            return;
        }

        const buttonMinusNode = item?.querySelector(CartItemDesk.cn.setElem('counter-btn-minus').toSelector());

        if (! buttonMinusNode) {
            return;
        }

        buttonMinusNode.setAttribute('disabled', '');
    }

    allowMinus(id: number) {
        const item = document.querySelector(CartItemDesk.cn.toSelector() + `[data-item-id="${id}"]`);

        if (! item) {
            return;
        }

        const buttonMinusNode = item?.querySelector(CartItemDesk.cn.setElem('counter-btn-minus').toSelector());

        if (! buttonMinusNode) {
            return;
        }

        buttonMinusNode.removeAttribute('disabled');
    }

    removeItem(id: number) {
        const node = document.querySelector(CartItemDesk.cn.toSelector() + `[data-item-id="${id}"]`);

        if (node) {
            node.remove();
        }
    }
}
