import { FiltersSliceState } from './filtersStore';

export const selectFiltersCount = (state: { filters: FiltersSliceState }) =>
    Object.values(state.filters.state ?? {}).reduce((ret, values) => {
        return ret + values.length;
    }, 0);

export const statusCounter = (state: { filters: FiltersSliceState }) => {
    return state.filters.counter.status;
};

export const selectIsPendingCounter = (state: { filters: FiltersSliceState }) => {
    return state.filters.counter.status === 'loading';
};

export const selectIsFulfilledCounter = (state: { filters: FiltersSliceState }) => {
    return state.filters.counter.status === 'fulfilled';
};

export const fetchResultCounter = (state: { filters: FiltersSliceState }) => {
    return state.filters.counter.count;
};
export const checkedFieldCounter = (state: { filters: FiltersSliceState }) => {
    const filterState = state.filters.state;

    return Object.keys(filterState).reduce((result, key) => Number(result) + Number(filterState[key].length), 0);
};
export const isOpenFilters = (state: { filters: FiltersSliceState }) => {
    return state.filters.isOpen;
};
export const filterState = (state: { filters: FiltersSliceState }) => {
    return state.filters.state;
};
