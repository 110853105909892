import { createSlice } from '@reduxjs/toolkit';

export interface HeaderWrapperSliceState {
    currentHeight: number;
}

const initialState: HeaderWrapperSliceState = {
    currentHeight: 0
};

export const headerWrapperSlice = createSlice({
    name: 'headerWrapper',
    initialState,
    reducers: {
        setCurrentHeight: (state, action) => {
            state.currentHeight = action.payload;
        }
    }
});
