import { VarProduct } from '@gate31/types';
import { ClientResponseInt } from '@gate31/types/client';
import Gate31Api from './gate31-api';

interface calculatePurchaseBody {
    user: {
        phone: string;
    };
    client: ClientResponseInt['client'];
    fieldValues: { [key: string]: string };
    orderLine: Array<VarProduct>;
    loyalty: {
        usedBonuses: number | string;
    };
}
export interface responseCalculate {
    response: {
        calculationResult: {
            bonuses: {
                applied: number;
                collected: number;
                maxToApply: number;
            };
            rows: Array<{
                bonuses: {
                    applied: number;
                    collected: number;
                };
                discounts: {
                    auto: number;
                    manual: number;
                    bonuses: number;
                    promocode: number;
                    offer: number;
                    rounding: number;
                };
                offers: Array<{
                    id: number;
                    code: string;
                    name: string;
                    amount: number;
                }>;
                totalDiscount: number;
            }>;
            summary: {
                discounts: {
                    auto: number;
                    manual: number;
                    bonuses: number;
                    promocode: number;
                    offer: number;
                    rounding: number;
                };
                totalDiscount: number;
            };
        };
    };
}

export interface ResponseBalance {
    response: {
        client: {
            isEmailSubscribed: boolean;
            isPhoneSubscribed: boolean;
            bonuses: number;
            pendingBonuses: number;
        };
        level: {
            name: string;
        };
    };
}

export interface UpdateClientBody {
    phoneNumber: string;
    client: {
        isEmailSubscribed?: boolean;
        isPhoneSubscribed?: boolean;
    };
}

interface body {
    // eslint-disable-next-line
    [key: string]: body | any;
}

export class Maxma {
    constructor() {}

    static send(opts: {
        path: string;
        body: body;
        // eslint-disable-next-line
    }): Promise<any> {
        return Gate31Api.post(opts.body, opts.path);
    }

    static getBalance(userPhone: string) {
        const path = 'maxma/get-balance';
        const body = {
            phoneNumber: userPhone
        };

        return Maxma.send({
            path,
            body
        });
    }

    static updateClient(body: UpdateClientBody) {
        const path = '/update-client';

        return Maxma.send({
            path,
            body
        }).then(res => res.json());
    }

    static calculatePurchase(body: calculatePurchaseBody): Promise<responseCalculate> {
        const path = 'maxma/calculate-purchase';

        return Maxma.send({
            path,
            body
        }).then(res => res.json());
    }
}
