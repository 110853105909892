import { Base } from '@gate31/core/src/libs/BaseComponent';
import { ClientResponseInt } from '@gate31/types/client';
import { Maxma, ResponseBalance, UpdateClientBody } from '@gate31/core/src/api/maxma';
import { MindboxApi, editByEmailBody, Subscriptions } from '@gate31/core/src/api/mindboxApi';

interface EditSubscriptionsOptions {
    clientData: ClientResponseInt;
    maxmaData: ResponseBalance;
}

export class EditSubscriptionsDesk extends Base<HTMLFormElement> {
    static cn = new Base.BuildCn('EditSubscriptionsDesk');

    clientData: ClientResponseInt;
    maxmaData: ResponseBalance;

    typeNodes: NodeListOf<HTMLElement>;
    errorNode: HTMLElement | null;
    submitButton: HTMLButtonElement | null;
    successNode: HTMLElement | null;

    constructor(node: HTMLFormElement, options: EditSubscriptionsOptions) {
        super(node);

        this.clientData = options.clientData;
        this.maxmaData = options.maxmaData;

        this.typeNodes = this.getRoot().querySelectorAll(EditSubscriptionsDesk.cn.setElem('type').toSelector());
        this.errorNode = this.getRoot().querySelector(EditSubscriptionsDesk.cn.setElem('error').toSelector());
        this.submitButton = this.getRoot().querySelector(EditSubscriptionsDesk.cn.setElem('submit').toSelector());
        this.successNode = this.getRoot().querySelector(EditSubscriptionsDesk.cn.setElem('success').toSelector());

        this.connectionCallback();
    }

    connectionCallback() {
        this.typeNodes.forEach(typeNode => {
            typeNode.addEventListener('change', () => {
                this.toggleVisibleTypeNode(typeNode);
            });
        });

        this.getRoot().addEventListener('change', () => {
            this.toggleDisableSubmit(false);
            this.toggleVisibleSuccessResult(false);
        });

        this.getRoot().addEventListener('submit', evt => {
            this.handleSubmit(evt);
        });

        this.getSubscribeInfo();
    }

    toggleVisibleTypeNode(typeNode: HTMLElement) {
        const typeCheckbox = typeNode
            .querySelector<HTMLInputElement>(EditSubscriptionsDesk.cn.setElem('type-checkbox__checkbox-input').toSelector());
        const topicsNode = typeNode.querySelector(EditSubscriptionsDesk.cn.setElem('topics').toSelector());
        const activeClass = EditSubscriptionsDesk.cn.setElem('topics').setMode({ active: true }).toString();

        topicsNode?.classList[typeCheckbox?.checked ? 'add' : 'remove'](activeClass);
    }

    toggleVisibleAllTypeNodes() {
        this.typeNodes.forEach(typeNode => {
            this.toggleVisibleTypeNode(typeNode);
        });
    }

    toggleDisableSubmit(isDisabled: boolean) {
        if (! this.submitButton) return;

        this.submitButton.disabled = isDisabled;
    }

    getSubscribeInfo() {
        return MindboxApi.getUserSubscribeList(this.clientData.client.email ?? '')
            .then(response => {
                const {
                    isEmailSubscribed,
                    isPhoneSubscribed
                } = this.maxmaData.response?.client;
                const subscriptions: Subscriptions[] = response.customer?.subscriptions ?? [];

                const smsCheckbox = this.getRoot().querySelector<HTMLInputElement>('[name="sms"]');
                const emailCheckbox = this.getRoot().querySelector<HTMLInputElement>('[name="sms"]');

                if (emailCheckbox) emailCheckbox.checked = isEmailSubscribed;

                subscriptions.forEach(subscription => {
                    const checkbox = this.getSubscribeCheckboxByInfo(subscription);

                    if (! checkbox) return;

                    checkbox.checked = Boolean(subscription.isSubscribed);
                });

                if (smsCheckbox) smsCheckbox.checked = isPhoneSubscribed;

                this.toggleVisibleAllTypeNodes();
            })
            .catch(() => {
                this.showError();
            });
    }

    getSubscribeCheckboxByInfo(subscribeInfo: Subscriptions) {
        const { pointOfContact, topic } = subscribeInfo;
        const name = (topic ? `"${pointOfContact}-${topic}"` : `"${pointOfContact}"`).toLocaleLowerCase();

        return this.getRoot().querySelector<HTMLInputElement>(`[name=${name}]`);
    }

    getSubscribeInfoByCheckbox(checkbox: HTMLInputElement) {
        const [ pointOfContact, topic ] = checkbox.name.split('-');
        const isSubscribed = checkbox.checked;

        const result: Subscriptions = {
            brand: 'gate31',
            pointOfContact: pointOfContact as Subscriptions['pointOfContact'],
            isSubscribed: isSubscribed.toString()
        };

        if (topic) result.topic = topic;

        return result;
    }

    handleSubmit(evt: Event) {
        evt.preventDefault();

        this.toggleDisableSubmit(true);

        if (! this.clientData.client.email) {
            return this.showError({ message: 'Укажите вашу ел. почту в настройке профиля' });
        }

        if (! this.clientData.client.phone) {
            return this.showError({ message: 'Укажите ваш номер телефона в настройке профиля' });
        }

        const formData = new FormData(this.getRoot());

        const maxmaBody: UpdateClientBody = {
            phoneNumber: this.clientData.client.phone || '',
            client: {
                isEmailSubscribed: formData.get('email') === 'on',
                isPhoneSubscribed: formData.get('sms') === 'on'
            }
        };

        const subscriptions: Subscriptions[] = [];

        this.typeNodes.forEach(typeNode => {
            const type = typeNode.getAttribute('data-type');
            const typeCheckbox = typeNode.querySelector<HTMLInputElement>(`[name="${type}"]`);

            if (! typeCheckbox) return;

            subscriptions.push(this.getSubscribeInfoByCheckbox(typeCheckbox));

            if (typeCheckbox.checked === false) return;

            typeNode.querySelectorAll<HTMLInputElement>(EditSubscriptionsDesk.cn.setElem('topic__checkbox-input').toSelector())
                .forEach(checkbox => {
                    subscriptions.push(this.getSubscribeInfoByCheckbox(checkbox));
                });
        });

        const mindboxBody: editByEmailBody = {
            customer: {
                email: this.clientData.client.email,
                // @ts-ignore
                subscriptions
            }
        };

        Promise.allSettled([
            Maxma.updateClient(maxmaBody),
            MindboxApi.editByEmail(mindboxBody)
        ])
            .then(() => {
                this.toggleVisibleSuccessResult(true);
            })
            .catch(() => {
                this.showError();
                this.toggleDisableSubmit(false);
            });
    }

    showError(options?: { message?: string }) {
        if (! this.errorNode) return;

        const activeClass = EditSubscriptionsDesk.cn.setElem('error').setMode({ active: true }).toString();

        this.errorNode.textContent = options?.message ?? 'Произошла ошибка. Попробуйте повторить действия позже.';
        this.errorNode.classList.add(activeClass);
    }

    hideError() {
        const activeClass = EditSubscriptionsDesk.cn.setElem('error').setMode({ active: true }).toString();

        this.errorNode?.classList.remove(activeClass);
    }

    toggleVisibleSuccessResult(isVisible: boolean) {
        if (! this.successNode) return;

        const activeClass = EditSubscriptionsDesk.cn.setElem('success').setMode({ active: true }).toString();

        if (isVisible) {
            this.successNode.classList.add(activeClass);
            setTimeout(() => {
                this.successNode?.classList.remove(activeClass);
            }, 20000);
        } else {
            this.successNode.classList.remove(activeClass);
        }
    }
}
