import { createSlice } from '@reduxjs/toolkit';
import { Product } from '@gate31/types';
import { fetchProductCollection } from './actions';

export interface CollectionSliceState {
    count: number;
    pages: number;
    pageSize: number;
    currentPage: number;
    products: Product[];
    sorting: Record<string, string>;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: null | Error | string;
    strategy: 'REFETCH' | 'MORE';
}

const initialState = {
    count: 0,
    ...window.COLLECTION_STORE_INIT, // Иобьект создается глобально в liquid
    products: [],
    sorting: {},
    status: 'idle',
    error: null,
    strategy: 'REFETCH'
};

export const collectionSlice = createSlice({
    name: 'collection',
    initialState: () => {
        return initialState as CollectionSliceState;
    },
    reducers: {
        // eslint-disable-next-line
        // init(...args) {},
        setCurrentPage: (state, action) => {
            return {
                ...state,
                currentPage: action.payload
            };
        },
        setSortingState: (state, action) => {
            return {
                ...state,
                sorting: action.payload
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchProductCollection.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchProductCollection.fulfilled, (state, action) => {
                state.status = 'succeeded';

                state.count = action.payload.count;
                state.products = action.payload.products;
                state.pages = Math.ceil(action.payload.count / state.pageSize);
            })
            .addCase(fetchProductCollection.rejected, (state, action) => {
                state.status = 'failed';
                state.error = String(action.error.message);
            });
    }
});
