import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appInitAction } from '@gate31/core/src/redux/init';
import { fetchFiltersCounter } from './actions';

export interface FiltersSlideDateItem {
    id: number;
    title: string;
    checked?: boolean;
    background?: string;
    name: string;
}
export interface FiltersSliceState {
    isOpen: boolean;
    state: Record<string, string[]>;
    data: {
        [key: string]: Array<FiltersSlideDateItem>;
    };
    counter: {
        count: number;
        status: 'idle' | 'loading' | 'fulfilled' | 'failed';
        error: null | Error | string;
    };
}

const initialState: FiltersSliceState = {
    isOpen: false,
    state: {},
    data: {},
    counter: {
        count: 0,
        status: 'idle',
        error: null
    }
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        // init(state, action) {
        //     state.counter.count = action.payload.counter;
        //     state.data = action.payload.filtersData;
        // },
        onChange: (state, action: PayloadAction<FiltersSliceState>) => {
            return action.payload;
        },
        filtersToggleOpen: state => {
            return {
                ...state,
                isOpen: ! state.isOpen
            };
        },
        setFilterState: (state, action) => {
            return {
                ...state,
                state: action.payload
            };
        },
        clearFilterGroup: (state, action) => {
            const { groupName, groupKey } = action.payload;

            const localeState: Record<string, Array<string>> = JSON.parse(JSON.stringify(state.state));
            const localeData: Record<string, Array<{ id: number }>> = JSON.parse(JSON.stringify(state.data));

            const newStateGroup = localeState[groupName].filter(i => ! localeData[groupKey].find(j => String(j.id) === String(i)));

            if (! newStateGroup.length) {
                delete localeState[groupName];
            } else {
                localeState[groupName] = newStateGroup;
            }

            return {
                ...state,
                state: localeState
            };
        }
    },
    extraReducers(builder) {
        builder
            .addCase(appInitAction, (state, action) => {
                if (action?.payload?.state) {
                    state.state = action.payload.state;
                }
                if (action?.payload?.data) {
                    state.data = action.payload.data;
                }
                if (action?.payload?.counter) {
                    state.counter.count = action.payload.counter;
                }
                if (action?.payload?.isOpen) {
                    state.isOpen = action.payload.isOpen;
                }
            })
            .addCase(fetchFiltersCounter.pending, state => {
                state.counter.status = 'loading';

                return state;
            })
            .addCase(fetchFiltersCounter.fulfilled, (state, action) => {
                state.counter.status = 'fulfilled';
                state.counter.count = action.payload;

                return state;
            });
    }
});

