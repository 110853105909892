import { Base } from '@gate31/core/src/libs/BaseComponent';
import { OrderData } from '../AccountClientDesk/AccountClientDesk';
import { AccountOrderDesk } from '../AccountOrderDesk/AccountOrderDesk';

export default class AccountActiveOrdersDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('AccountActiveOrdersDesk');

    orders: Array<OrderData>;
    accountOrderDesk: AccountOrderDesk;

    constructor(node: HTMLDivElement, orders: Array<OrderData>) {
        super(node);

        this.orders = orders;
        this.accountOrderDesk = new AccountOrderDesk('account-order-desk-template');

        this.connectionCallback();
    }

    connectionCallback() {
        if (this.orders.filter(order => order.isActive).length) {
            this.renderOrders();
            this.renderFlag();
        }
    }

    renderOrders() {
        this.getRoot().innerHTML = this.orders.filter(order => order.isActive).map(orderDate => {
            return this.accountOrderDesk.getHTML(orderDate);
        }).join('');
    }

    renderFlag() {
        const container = AccountActiveOrdersDesk.querySelector<HTMLDivElement>(AccountActiveOrdersDesk.cn.setElem('accordion-header-content').toSelector());
        const content = `
            <div class="AccountActiveOrdersDesk__flag">
                <span class="AccountActiveOrdersDesk__flag-icon"></span>
                <span class="AccountActiveOrdersDesk__flag-counter">${this.orders.filter(order => order.isActive).length}</span>
            </div>
        `;

        container.innerHTML += content;
    }
}
