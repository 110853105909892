import { Base } from '@gate31/core/src/libs/BaseComponent';
import { CustomCn } from '@gate31/core/src/libs/classname';

const ERROR_LIST: Record<string, string> = {
    'не совпадает с подтверждением': 'Пароли не совпадают'
};

class BaseForm extends Base<HTMLElement> {
    cn: CustomCn;
    formNode: HTMLFormElement | null;
    errorPositionNode: HTMLElement | null;
    submitButton: HTMLButtonElement | null;

    constructor(node: HTMLElement, cn: CustomCn) {
        super(node);

        this.cn = cn;
        this.formNode = this.getRoot().querySelector('form');
        this.errorPositionNode = this.getRoot().querySelector('.co-form-controls');
        this.submitButton = this.getRoot().querySelector('[type=submit]');
    }

    setTitle(title: string) {
        const titleNode = this.getRoot().querySelector<HTMLElement>(this.cn.setElem('title').toSelector());

        if (! titleNode) return;

        titleNode.textContent = title;
    }

    setButtonText(text: string) {
        if (! this.submitButton) return;

        this.submitButton.textContent = text;
    }

    toggleDisableSubmit(isDisabled: boolean) {
        if (! this.submitButton) return;

        this.submitButton.disabled = isDisabled;
    }

    showNativeError() {
        const dangerNodes = this.getRoot().querySelectorAll('.co-notice--danger');

        const errors = Array.from(dangerNodes).map(dangerNode => dangerNode.textContent?.trim()).filter(Boolean);

        if (! errors.length) return;

        const message = ERROR_LIST[errors[0] as string] ?? errors[0];

        this.renderError({ message });
    }

    renderError(options?: { message?: string }) {
        if (! this.errorPositionNode) return;

        this.removeAllErrors();

        const div = document.createElement('div');

        div.classList.add(this.cn.setElem('error').toString());
        div.textContent = options?.message ?? 'Произошла ошибка. Попробуйте повторить действия позже.';

        this.errorPositionNode.insertAdjacentElement('beforebegin', div);
    }

    removeAllErrors() {
        this.getRoot().querySelectorAll<HTMLElement>(this.cn.setElem('error')
            .toSelector())
            .forEach(errorNode => errorNode.remove());
    }
}

class NewPasswordAccount extends BaseForm {
    passwordInput: HTMLInputElement | null;
    passwordConfirmationInput: HTMLInputElement | null;

    constructor(node: HTMLElement, cn: CustomCn) {
        super(node, cn);

        this.passwordInput = this.getRoot().querySelector('[name="client[password]"]');
        this.passwordConfirmationInput = this.getRoot().querySelector('[name="client[password_confirmation]"]');

        this.connectionCallback();
    }

    connectionCallback() {
        this.setTitle('Установите новый пароль');
        this.setPlaceholders();
        this.setButtonText('Далее');
        this.showNativeError();
        this.toggleDisableSubmit(true);

        this.formNode?.addEventListener('input', () => {
            this.toggleDisableSubmit(! this.isValid());
            this.removeAllErrors();
        });
    }

    setPlaceholders() {
        if (this.passwordInput) this.passwordInput.placeholder = 'Новый пароль';
        if (this.passwordConfirmationInput) this.passwordConfirmationInput.placeholder = 'Повторите новый пароль';
    }

    isValid() {
        return Boolean(this.passwordInput?.value.length) && Boolean(this.passwordConfirmationInput?.value.length);
    }
}

class ResetPasswordAccount extends BaseForm {
    emailInput: HTMLInputElement | null;

    formInnerNode: HTMLElement | null;;
    infoNode: HTMLElement | null;;

    formAction: string;
    formMethod: string;

    constructor(node: HTMLElement, cn: CustomCn) {
        super(node, cn);

        this.emailInput = this.getRoot().querySelector('[name=email]');

        this.formInnerNode = this.getRoot().querySelector(this.cn.setElem('form').toSelector());
        this.infoNode = this.getRoot().querySelector(this.cn.setElem('info').toSelector());

        this.formAction = this.formNode?.action ?? '/';
        this.formMethod = this.formNode?.method ?? 'post';

        this.connectionCallback();
    }

    connectionCallback() {
        this.setTitle('Восстановление пароля');
        this.setPlaceholders();
        this.setButtonText('Восстановить');
        this.showNativeError();
        this.toggleDisableSubmit(true);

        this.formNode?.addEventListener('input', () => {
            this.toggleDisableSubmit(! this.isValid());
            this.removeAllErrors();
        });
        this.formNode?.addEventListener('submit', evt => {
            this.handleForm(evt);
        });
    }

    // eslint-disable-next-line
    onSubmit(formData: FormData) {}

    setPlaceholders() {
        if (this.emailInput) this.emailInput.placeholder = 'эл. почта';
    }

    isValid() {
        return Boolean(this.emailInput?.value.length);
    }

    showInfoMessage() {
        const formInnerNodeActiveClass = this.cn.setElem('form').setMode({ active: true }).toString();
        const infoNodeActiveClass = this.cn.setElem('info').setMode({ active: true }).toString();

        this.formInnerNode?.classList.remove(formInnerNodeActiveClass);
        this.infoNode?.classList.add(infoNodeActiveClass);
    }

    reset() {
        this.formNode?.reset();
        this.toggleDisableSubmit(true);
    }

    handleForm(evt: Event) {
        evt.preventDefault();

        const formData = new FormData(this.formNode as HTMLFormElement);

        const email = formData.get('email');

        if (! (email as string)?.includes('@')) {
            this.renderError({ message: 'Неправильный email' });

            return;
        }

        this.toggleDisableSubmit(true);

        fetch(this.formAction + '.json', {
            method: this.formMethod,
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.onSubmit(formData);
                    this.showInfoMessage();
                }

                if (data.status === 'error') {
                    this.toggleDisableSubmit(false);
                    this.renderError({ message: data.message });
                }
            })
            .catch(() => {
                this.toggleDisableSubmit(false);
                this.renderError();
            });
    }
}

export class AccountAuthBaseForm extends Base<HTMLElement> {
    cn: CustomCn;
    formNode: HTMLFormElement | null;
    formAction: string;

    constructor(node: HTMLElement, cn: CustomCn) {
        super(node);

        this.cn = cn;
        this.formNode = this.getRoot().querySelector('form');
        this.formAction = this.formNode?.getAttribute('action') ?? '';

        this.connectionCallback();
    }

    connectionCallback() {
        switch (this.formAction) {
            case '/client_account/password/reset':
                // eslint-disable-next-line
                new ResetPasswordAccount(this.getRoot(), this.cn);
                break;
            case '/client_account/password':
                // eslint-disable-next-line
                new NewPasswordAccount(this.getRoot(), this.cn);
                break;
            default:
                break;
        }
    }
}
