import grecaptcha from 'grecaptcha';
import { RecaptchaInstanceId, RecaptchaInt, RecaptchaOptsInt } from '@gate31/types/recaptcha';

const MESSAGES = {
    UNEXPECTED_ELEMENT: 'Невозможно определить элемент по входному селектору.',
    UNEXPECTED_RECAPTCHA_ID: 'Не определен id капчи. Сначала необходимо вызвать render().'
};

export default class Recaptcha implements RecaptchaInt {
    container: HTMLElement | null;
    siteKey: string;
    instanceId: RecaptchaInstanceId | undefined;
    isUseCaptcha: boolean;

    constructor(opts: RecaptchaOptsInt) {
        this.isUseCaptcha = window.Site.settings.captchaIsUse === 'true';
        this.container = document.querySelector(opts.containerSelector);
        this.siteKey = opts.siteKey;
    }

    render(): RecaptchaInstanceId {
        if (! this.container) {
            throw new Error(MESSAGES.UNEXPECTED_ELEMENT);
        }

        const instanceId = grecaptcha.render(
            this.container,
            {
                sitekey: this.siteKey
            }
        );

        this.instanceId = instanceId;

        return instanceId;
    }

    reset(): void {
        if (typeof this.instanceId === undefined) {
            throw new Error(MESSAGES.UNEXPECTED_RECAPTCHA_ID);
        }

        grecaptcha.reset(this.instanceId);
    }

    getResponse(): string {
        return grecaptcha.getResponse(this.instanceId);
    }
}
