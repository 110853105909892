import { ReviewTypes } from '@gate31/types/ReviewsTypes';

class ReviewApiError extends Error {
    name = 'ReviewApiError'
}

interface createProductReviewInt {
    Token?: string;
    AuthorName: string;
    AuthorEmail: string;
    Pros: string;
    Cons: string;
    ReviewText: string;
    Rate: number;
    Date: string; // date iso
    Recommends: boolean;
    ProductName: string;
    ProductURL: string;
    Meta?:{
        'some rating': number;
        'some text': string;
        'some dropdown': string;
        'some text area': string;
    };
}

export interface bodyPost {
    token?: string;
    AuthorName: FormDataEntryValue | null | string;
    AuthorEmail: FormDataEntryValue | null | string;
    ReviewText: FormDataEntryValue | null | string;
    Rate: FormDataEntryValue | null | string;
    Date: string;
    Recommends: string | number | string[] | undefined;
}

export class ReviewsApi {
    clientId: string;
    token: string;
    host: string;

    constructor() {
        this.clientId = 'de7a6856-5087-4179-b588-45a729b476dc';
        this.token = 'n{MP&v(IFaOVk;/N';
        this.host = 'https://api.mneniya.pro/v2.1/clients';
    }

    addImageForReview(reviewId: string, formData: FormData) {
        const url = `${this.host}/${this.clientId}/files/images/review/${reviewId}`;

        return fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .catch(err => err);
    }

    getReviewsProduct(id: number) {
        const url = `${this.host}/${this.clientId}/reviews/Product/Collected/${id}`;

        return fetch(url)
            .then(data => data.json())
            .catch(err => err);
    }

    createProductReview(body: createProductReviewInt, productId: number) {
        const url = `${this.host}/${this.clientId}/reviews/Product/${productId}`;

        body.Token = this.token;

        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .catch(err => {
                throw new ReviewApiError(err);
            });
    }

    createReview(body: bodyPost) {
        body.token = this.token;

        return fetch(`${this.host}/${this.clientId}/reviews/Merchant`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(data => data.json())
            .catch(error => {
                throw new ReviewApiError(error);
            });
    }

    getReviewsAll(offset?: number): Promise<ReviewTypes> {
        let resultUrl = `${this.host}/${this.clientId}/reviews/Merchant/Collected`;

        if (offset) {
            resultUrl = `${resultUrl}?offset=${offset}`;
        }

        return fetch(resultUrl)
            .then(data => data.json());
    }
}
