import { Base } from '@gate31/core/src/libs/BaseComponent';

export class ShopDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ShopDesk');

    controlBtns: NodeListOf<HTMLButtonElement>;
    mapsNodes: NodeListOf<HTMLDivElement>;

    constructor(node: HTMLElement) {
        super(node);

        this.controlBtns = ShopDesk.querySelectorAll(ShopDesk.cn.setElem('btn').toSelector());
        this.mapsNodes = ShopDesk.querySelectorAll(ShopDesk.cn.setElem('map').toSelector());

        this.connectionCallback();
    }

    toggle(index: number) {
        const classActiveControl = ShopDesk.cn.setElem('btn').setMode({ active: true }).toString();
        const classActiveMap = ShopDesk.cn.setElem('map').setMode({ active: true }).toString();

        if (this.controlBtns[index].classList.contains(classActiveControl)) return;

        this.controlBtns.forEach(btn => btn.classList.remove(classActiveControl));
        this.controlBtns[index].classList.add(classActiveControl);

        this.mapsNodes.forEach(btn => btn.classList.remove(classActiveMap));
        this.mapsNodes[index].classList.add(classActiveMap);
    }

    connectionCallback() {
        this.toggle(0);

        this.controlBtns.forEach((btn, index) => {
            btn.addEventListener('click', () => this.toggle(index));
        });

        this.getRoot()
            .querySelectorAll(`${ShopDesk.cn.setElem('map').toSelector()} iframe`)
            .forEach(map => {
                const observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            const dataSrc = entry.target.getAttribute('data-src');

                            if (dataSrc) {
                                entry.target.setAttribute('src', dataSrc);
                                observer.disconnect();
                            }
                        }
                    });
                });

                observer.observe(map);
            });
    }
}
