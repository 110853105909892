import { Base } from '@gate31/core/src/libs/BaseComponent';
import CreateAccountDesk from '../CreateAccountDesk/CreateAccountDesk';
import { LoginAccountPhoneDesk } from '../LoginAccountPhoneDesk/LoginAccountPhoneDesk';
import { LoginAccountEmailDesk } from '../LoginAccountEmailDesk/LoginAccountEmailDesk';
import { AccountAuthBaseFormDesk } from '../AccountAuthBaseFormDesk/AccountAuthBaseFormDesk';

type formName = 'create' | 'reset-password' | 'base-form' | 'login' | 'login-email';

export default class AccountAuthDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('AccountAuthDesk')

    toggleItems: NodeListOf<HTMLElement>;
    loader: HTMLDivElement | null;

    loginAccountPhone?: LoginAccountPhoneDesk;

    customWrapper: HTMLElement;
    baseForm: HTMLElement;
    baseWrapper: HTMLElement;

    constructor(node: HTMLElement) {
        super(node);

        this.toggleItems = AccountAuthDesk.querySelectorAll<HTMLElement>(AccountAuthDesk.cn.setElem('variants-page-item').toSelector());
        this.loader = AccountAuthDesk.querySelector<HTMLDivElement>(AccountAuthDesk.cn.setElem('loader').toSelector());

        this.customWrapper = AccountAuthDesk.querySelector(AccountAuthDesk.cn.setElem('custom-form').toSelector());
        this.baseForm = AccountAuthDesk.querySelector(AccountAuthDesk.cn.setElem('base-form').toSelector());

        this.baseWrapper = AccountAuthDesk.querySelector(AccountAuthDesk.cn.setElem('base-wrapper').toSelector());

        if (document.querySelector<HTMLFormElement>(LoginAccountPhoneDesk.cn.toSelector())) {
            this.loginAccountPhone = new LoginAccountPhoneDesk(LoginAccountPhoneDesk.querySelector<HTMLFormElement>(LoginAccountPhoneDesk.cn.toSelector()));
        }
        if (document.querySelector<HTMLFormElement>(LoginAccountEmailDesk.cn.toSelector())) {
            // eslint-disable-next-line
            new LoginAccountEmailDesk(LoginAccountEmailDesk.querySelector<HTMLFormElement>(LoginAccountEmailDesk.cn.toSelector()));
        }
        // eslint-disable-next-line
        new AccountAuthBaseFormDesk(AccountAuthBaseFormDesk.querySelector<HTMLFormElement>(AccountAuthBaseFormDesk.cn.toSelector()));
        // eslint-disable-next-line
        new CreateAccountDesk(CreateAccountDesk.querySelector(CreateAccountDesk.cn.toSelector()));

        this.callbackConnection();
    }

    callbackConnection() {
        this.initPage();

        document.addEventListener('click', this.toggleFormHandle.bind(this));

        if (this.loginAccountPhone) {
            this.loginAccountPhone.onShowPhoneForm = () => {
                const linksNode = this.getActiveForm()?.querySelector<HTMLElement>(AccountAuthDesk.cn.setElem('links').toSelector());

                if (! linksNode) return;

                linksNode.removeAttribute('style');
            };

            this.loginAccountPhone.onShowSmsForm = () => {
                const linksNode = this.getActiveForm()?.querySelector<HTMLElement>(AccountAuthDesk.cn.setElem('links').toSelector());

                if (! linksNode) return;

                linksNode.style.display = 'none';
            };
        }
    }

    hiddenAllForm() {
        this.toggleItems.forEach(toggleItem => {
            toggleItem.classList.remove(AccountAuthDesk.cn.setElem('variants-page-item').setMode({ active: true }).toString());
        });
    }

    hasForm(formName: formName) {
        return Boolean(document.querySelector<HTMLElement>(AccountAuthDesk.cn.setElem(formName).toSelector()));
    }

    openForm(formName: formName) {
        const currentItem = document.querySelector<HTMLElement>(AccountAuthDesk.cn.setElem(formName).toSelector());
        const activeClass = AccountAuthDesk.cn.setElem('variants-page-item').setMode({ active: true }).toString();

        if (currentItem) {
            this.hiddenAllForm();
            currentItem.classList.add(activeClass);
        }
    }

    getActiveForm() {
        for (let i = 0; i < this.toggleItems.length; i++) {
            if (this.toggleItems[i].className.includes('active')) {
                return this.toggleItems[i];
            }
        }
    }

    removeLoader() {
        if (! this.loader) {
            return;
        }

        this.loader.remove();
    }

    openBaseWrapper() {
        if (! this.baseWrapper) {
            return;
        }

        this.baseWrapper.classList.remove(AccountAuthDesk.cn.setElem('base-wrapper').setMode({ hidden: true }).toString());
    }

    openBaseForm() {
        if (! this.baseForm) {
            return;
        }

        this.hiddenAllForm();
        this.baseForm.classList.remove(AccountAuthDesk.cn.setElem('base-form').setMode({ hidden: true }).toString());
    }

    openCustomForm() {
        if (! this.customWrapper) {
            return;
        }

        this.hiddenAllForm();
        this.customWrapper.classList.remove(AccountAuthDesk.cn.setElem('custom-form').setMode({ hidden: true }).toString());
    }

    toggleFormHandle(e: Event) {
        const target = e.target as HTMLElement;
        const link = target.closest(AccountAuthDesk.cn.setElem('link').toSelector());
        const formName = link ? link.getAttribute('data-account-auth') as formName : '';

        if (formName) {
            this.openForm(formName);
        }
    }

    initPage() {
        const pathName = window.location.pathname;

        this.removeLoader();

        switch (pathName) {
            case '/client_account/login':
                this.openCustomForm();
                if (this.hasForm('login')) {
                    this.openForm('login');
                } else if (this.hasForm('login-email')) {
                    this.openForm('login-email');
                }
                break;
            case '/client_account/contacts/new':
                this.openCustomForm();
                this.openForm('create');
                break;
            case '/client_account/session/new':
                this.openCustomForm();
                if (this.hasForm('login-email')) {
                    this.openForm('login-email');
                } else if (this.hasForm('login')) {
                    this.openForm('login');
                }
                break;
            default:
                if (pathName.includes('orders')) {
                    this.openBaseWrapper();
                } else {
                    this.openCustomForm();
                    this.openForm('base-form');
                }
                break;
        }
    }
}
