import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';
import { discount } from '@gate31/types/order';
import EventBus from 'EventBus';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import Client from '@gate31/uikit/common/components/client/client';
import { CartItemDesk } from '../../components/CartItemDesk/CartItemDesk';
import { CartLoyaltyDesk } from '../../components/CartLoyaltyDesk/CartLoyaltyDesk';
import { SnippetProductSliderDesk } from '../../components/SnippetProductSliderDesk/SnippetProductSliderDesk';

export class Cart extends Base<HTMLElement> {
    static cn = new Base.BuildCn('cart')

    cartItem: CartItemDesk;

    couponWrapper: HTMLDivElement;
    couponHeader: HTMLDivElement;
    couponResetButton: HTMLElement;
    couponInput: HTMLInputElement;
    couponError: HTMLDivElement
    couponSubmitButton: HTMLButtonElement;

    constructor(node: HTMLElement) {
        super(node);

        if (CartItemDesk.querySelectorAll(CartItemDesk.cn.toSelector()).length) {
            this.cartItem = new CartItemDesk(CartItemDesk.querySelector<HTMLDivElement>(CartItemDesk.cn.toSelector()));
        }

        this.couponWrapper = Cart.querySelector<HTMLDivElement>(Cart.cn.setElem('coupon').toSelector());
        this.couponHeader = Cart.querySelector<HTMLDivElement>(Cart.cn.setElem('coupon-header').toSelector());
        this.couponResetButton = Cart.querySelector<HTMLElement>(Cart.cn.setElem('coupon-reset-btn').toSelector());
        this.couponInput = Cart.querySelector<HTMLInputElement>(Cart.cn.setElem('coupon-input').toSelector());
        this.couponError = Cart.querySelector<HTMLDivElement>(Cart.cn.setElem('coupon-error').toSelector());
        this.couponSubmitButton = Cart.querySelector<HTMLButtonElement>(Cart.cn.setElem('coupon-button').toSelector());

        this.connectionCallback();
    }

    connectionCallback() {
        EventBus.subscribe('delete_items:insales:cart', data => {
            this.cartItem.removeItem(data.action.items[0]);
        });

        EventBus.subscribe('update_items:insales:cart', data => {
            data.order_lines.forEach(orderLine => {
                if (orderLine.quantity < 2) {
                    this.cartItem.disabledMinus(orderLine.id);
                } else {
                    this.cartItem.allowMinus(orderLine.id);
                }
            });

            if (data.items_count === 0) {
                this.renderEmptyCart();
                SnippetProductSliderDesk.init();
            } else {
                this.updateCartPrice({
                    discountObj: data.discounts[0],
                    totalPrice: data.total_price
                });
            }
        });

        Client.get()
            .then(() => {
                // eslint-disable-next-line
                new CartLoyaltyDesk(CartLoyaltyDesk.querySelector(CartLoyaltyDesk.cn.toSelector()));
            })
            .catch(() => {});

        this.couponResetButton.addEventListener('click', () => {
            this.couponInput.value = ' ';
            this.couponSubmitButton.click();
        });

        this.couponInput.addEventListener('input', () => {
            const value = this.couponInput.value;

            if (value !== '') {
                this.couponSubmitButton.removeAttribute('disabled');
            } else {
                this.couponSubmitButton.setAttribute('disabled', 'true');
            }

            this.hideCouponError();
        });

        this.couponHeader.addEventListener('click', () => {
            this.couponWrapper.classList.toggle(Cart.cn.setElem('coupon').setMode({ show: true }).toString());
        });
    }

    hideCouponError() {
        this.couponError.style.display = 'none';
    }

    renderEmptyCart() {
        const baseContent = Cart.querySelector(Cart.cn.setElem('content').toSelector());
        const emptyContent = Cart.querySelector(Cart.cn.setElem('empty').toSelector());

        baseContent.classList.add(Cart.cn.setElem('content').setMode({ hidden: true }).toString());
        emptyContent.classList.remove(Cart.cn.setElem('empty').setMode({ hidden: true }).toString());
    }

    /**
     * Обновляем стоимость корзины
     * @param opts
     */
    updateCartPrice(opts: { discountObj: discount | undefined; totalPrice: number }): void {
        const wrapper = Cart.querySelector(Cart.cn.setElem('price-container').toSelector());
        const content = LiquidRender.render('cart-price-desktop-template', {
            discount: opts.discountObj,
            total_price: opts.totalPrice
        }) || '';

        wrapper.innerHTML = content;
    }
}
