import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';

export class HeaderPopoverDesk extends BaseJs {
    static readonly cn = new BaseJs.BuildCn('HeaderPopoverDesk');
    static list: Array<HTMLElement> = [];
    static readonly openTime = 5000;
    static shortTimoutId: number;

    data: Record<string, string>
    container: HTMLElement;

    closeButton: HTMLButtonElement | null | undefined;

    constructor(modalData: Record<string, string>, container: HTMLElement) {
        super('header-popover-desk-template');

        this.data = modalData;
        this.container = container;
    }

    callbackConnection() {
        this.closeButton = document.querySelector<HTMLButtonElement>(HeaderPopoverDesk.cn.setElem('close-link').toSelector());

        if (this.closeButton) {
            this.closeButton.addEventListener('click', () => {
                HeaderPopoverDesk.destroy();
            });
        }
    }

    shortRender() {
        this.render();

        HeaderPopoverDesk.shortTimoutId = setTimeout(() => {
            HeaderPopoverDesk.destroy();
        }, HeaderPopoverDesk.openTime) as unknown as number;
    }

    static destroy() {
        clearTimeout(HeaderPopoverDesk.shortTimoutId);

        if (HeaderPopoverDesk.list && HeaderPopoverDesk.list.length) {
            const item = HeaderPopoverDesk.list.pop() as HTMLElement;

            item.remove();
        }

        HeaderPopoverDesk.list = [];
    }

    render() {
        if (HeaderPopoverDesk.list[0]) {
            HeaderPopoverDesk.destroy();
        }

        const element = this.renderTemplate({
            container: this.container,
            templateData: this.data
        });

        HeaderPopoverDesk.list.push(element);

        this.callbackConnection();
    }
}
