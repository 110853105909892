import { Base } from '@gate31/core/src/libs/BaseComponent';
import { validateDateOfBirthday, validateEmail, validatePhone } from '@gate31/core/src/libs/utils';
import { ClientResponseInt } from '@gate31/types/client';
import Gate31Api, {
    UpdateAccountBodyInt,
    UpdateAccountResponseErrorInt,
    UpdateAccountResponseInt
} from '@gate31/core/src/api/gate31-api';
import Inputmask from 'inputmask';
import { PasswordToggleDesk } from '../PasswordToggleDesk/PasswordToggleDesk';

export class AccountProfileDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('AccountProfileDesk');

    im: Inputmask.Instance;

    clientData: ClientResponseInt;
    passwordToggleDesk: PasswordToggleDesk;

    wrapperContent: HTMLDivElement;
    wrapperForm: HTMLFormElement;
    form: HTMLFormElement;
    phoneField: HTMLInputElement;
    emailField: HTMLInputElement;
    dateOfBirthdayField: HTMLInputElement;
    togglePasswordField: HTMLInputElement;

    accordion: HTMLDivElement;
    trigger: HTMLDivElement;
    submitButton: HTMLButtonElement;
    resetButton: HTMLButtonElement;

    constructor(node: HTMLDivElement, clientData: ClientResponseInt) {
        super(node);

        this.im = new Inputmask('99.99.9999', { showMaskOnHover: false });
        this.im.mask('input[type="text"].type-date');

        this.clientData = clientData;
        this.passwordToggleDesk = new PasswordToggleDesk('password-toggle-desk-template');

        this.wrapperContent = document.querySelector<HTMLDivElement>(AccountProfileDesk.cn.setElem('content').toSelector()) as HTMLDivElement;
        this.wrapperForm = document.querySelector<HTMLFormElement>(AccountProfileDesk.cn.setElem('form').toSelector()) as HTMLFormElement;
        this.form = document.querySelector<HTMLFormElement>(AccountProfileDesk.cn.setElem('form').toSelector()) as HTMLFormElement;
        this.phoneField = this.form.querySelector<HTMLInputElement>(AccountProfileDesk.cn.setElem('input').toSelector() + '[name="phone"]') as HTMLInputElement;
        this.emailField = this.form.querySelector<HTMLInputElement>(AccountProfileDesk.cn.setElem('input').toSelector() + '[name="email"]') as HTMLInputElement;
        this.dateOfBirthdayField = this.form.querySelector<HTMLInputElement>(AccountProfileDesk.cn.setElem('input').toSelector() + '[name="date-of-birthday"]') as HTMLInputElement;
        this.togglePasswordField = this.form.querySelector<HTMLInputElement>(AccountProfileDesk.cn.setElem('checkbox').toSelector() + ' [name="password-toggle"]') as HTMLInputElement;

        this.accordion = document.querySelector(AccountProfileDesk.cn.setElem('accordion-desk-item').toSelector()) as HTMLDivElement;
        this.trigger = document.querySelector<HTMLDivElement>(AccountProfileDesk.cn.setElem('trigger').toSelector()) as HTMLDivElement;
        this.submitButton = document.querySelector<HTMLButtonElement>(AccountProfileDesk.cn.setElem('button').setMode({ submit: true }).toSelector()) as HTMLButtonElement;
        this.resetButton = document.querySelector<HTMLButtonElement>(AccountProfileDesk.cn.setElem('button').setMode({ reset: true }).toSelector()) as HTMLButtonElement;

        this.callbackConnection();
    }

    callbackConnection() {
        this.trigger.addEventListener('click', () => {
            this.openForm();
            this.initDataInForm();
            this.hiddenContent();
        });

        this.accordion.addEventListener('click', () => {
            setTimeout(() => {
                if (this.isActiveAccordion() && ! this.isOpenForm()) {
                    this.openTrigger();
                } else {
                    this.hiddenTrigger();
                }
            }, 0);
        });

        this.form.addEventListener('submit', e => {
            e.preventDefault();

            this.handleForm(e);
        });

        this.form.addEventListener('input', e => {
            this.handleChangeForm(e);
        });

        this.resetButton.addEventListener('click', () => {
            this.openContent();
            this.hiddenForm();
        });

        this.togglePasswordField.addEventListener('input', e => {
            const target = e.target as HTMLInputElement;

            if (target.checked) {
                this.showPasswordsFields();
            } else {
                this.hidePasswordsFields();
            }
        });
    }

    showPasswordsFields() {
        const passwordWrapper = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('field-set').setMode({ password: true }).toSelector());

        passwordWrapper?.classList.remove(AccountProfileDesk.cn.setElem('field-set').setMode({ hidden: true }).toString());
    }

    hidePasswordsFields() {
        const passwordWrapper = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('field-set').setMode({ password: true }).toSelector());

        passwordWrapper?.classList.add(AccountProfileDesk.cn.setElem('field-set').setMode({ hidden: true }).toString());
    }

    handleChangeForm(e: Event) {
        const input = e.target as HTMLInputElement;

        const formData = new FormData(this.form);

        const inputName = input.getAttribute('name');

        const name = formData.get('name');
        const phone = this.phoneField.value;
        const email = this.emailField.value;

        if (name && phone && email) {
            this.unableButton();
        } else {
            this.disableButton();
        }

        this.hideFormError();
        this.removeFieldError(inputName as string);
    }

    async handleForm(e: Event) {
        const isValidForm = this.formValidate();

        if (! isValidForm) {
            return;
        }

        this.showFormLoader();

        const formData = new FormData(e.target as HTMLFormElement);

        const body: UpdateAccountBodyInt = {
            userId: this.clientData.client.id,
            userName: formData.get('name') as string,
            userSurname: formData.get('last-name') as string,
            userPatronymic: '',
            userPhone: this.phoneField.value,
            userEmail: this.emailField.value,
            userDateOfBirthday: this.dateOfBirthdayField.value,
            passwordInfo: {
                isUpdate: Boolean(formData.get('password-toggle')),
                newPassword: formData.get('new-password') as string,
                newPasswordConfirmation: formData.get('password-confirm') as string
            }
        };

        const responseUpdateAccount = await Gate31Api.updateAccount(body)
            .then(response => {
                this.hideFormLoader();
                return response;
            })
            .catch(error => {
                this.hideFormLoader();
                return error;
            });

        if ((responseUpdateAccount as UpdateAccountResponseInt).response === 'ok') {
            this.updateProfileDate(body);
            this.updateDataValueFields(body);
            this.hiddenForm();
            this.openContent();
            this.openTrigger();
        } else if ((responseUpdateAccount as UpdateAccountResponseErrorInt).error) {
            const message = (responseUpdateAccount as UpdateAccountResponseErrorInt).error.message;

            this.showFormError(message);
        } else {
            this.showFormError('Неизвестная ошибка');
        }
    }

    updateProfileDate(formBody: UpdateAccountBodyInt) {
        const { userName, userSurname, userPhone, userEmail, userDateOfBirthday } = formBody;

        this.updateProfileDateItem('name', `${userName} ${userSurname}`);
        this.updateProfileDateItem('phone', userPhone);
        this.updateProfileDateItem('email', userEmail);

        if (userDateOfBirthday) {
            this.openDateContent();
            this.dateOfBirthdayField.setAttribute('disabled', 'true');
            this.updateProfileDateItem('date', userDateOfBirthday);
        }
    }

    updateDataValueFields(formBody: UpdateAccountBodyInt) {
        const { userName, userSurname, userPhone, userEmail } = formBody;

        this.updateDataValueField('name', userName || '');
        this.updateDataValueField('last-name', userSurname || '');
        this.updateDataValueField('phone', userPhone);
        this.updateDataValueField('email', userEmail);
    }

    updateDataValueField(name: string, newValue: string) {
        const input = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('input').toSelector() + `[name="${name}"]`);

        if (! input) {
            return;
        }

        input.setAttribute('data-value', newValue);
    }

    openDateContent() {
        const nodeNode = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('content-item').setMode({ date: true }).toSelector());

        if (nodeNode) {
            nodeNode.classList.remove(AccountProfileDesk.cn.setElem('content-item').setMode({ hidden: true }).toString());
        }
    }

    updateProfileDateItem(name: string, newValue: string) {
        const fieldWrapper = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('content-item').setMode({ [name]: true }).toSelector());

        if (! fieldWrapper) {
            return;
        }

        const node = fieldWrapper.querySelector(AccountProfileDesk.cn.setElem('content-item-info').toSelector());

        if (! node) {
            return;
        }

        node.innerHTML = newValue;
    }

    showFormError(message: string) {
        const errorNode = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('form-error').toSelector());

        if (errorNode) {
            errorNode.innerHTML = message;
        }

        errorNode?.classList.remove(AccountProfileDesk.cn.setElem('form-error').setMode({ hidden: true }).toString());
    }

    hideFormError() {
        const errorNode = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('form-error').toSelector());

        if (errorNode) {
            errorNode.innerHTML = '';
        }

        errorNode?.classList.add(AccountProfileDesk.cn.setElem('form-error').setMode({ hidden: true }).toString());
    }

    showFormLoader() {
        const loader = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('form-loader').toSelector());

        loader?.classList.remove(AccountProfileDesk.cn.setElem('form-loader').setMode({ hidden: true }).toString());
    }

    hideFormLoader() {
        const loader = this.getRoot().querySelector(AccountProfileDesk.cn.setElem('form-loader').toSelector());

        loader?.classList.add(AccountProfileDesk.cn.setElem('form-loader').setMode({ hidden: true }).toString());
    }

    removeFieldError(fieldName: string) {
        const field = AccountProfileDesk.querySelector(AccountProfileDesk.cn.setElem('field').setMode({ [fieldName]: true }).toSelector());
        const errorNode = field.querySelector(AccountProfileDesk.cn.setElem('field-error').toSelector());

        if (errorNode) {
            errorNode.innerHTML = '';
            errorNode.classList.remove(AccountProfileDesk.cn.setElem('field-error').setMode({ active: true }).toString());
        }
    }

    renderFieldError(fieldName: string, message: string) {
        const field = AccountProfileDesk.querySelector(AccountProfileDesk.cn.setElem('field').setMode({ [fieldName]: true }).toSelector());
        const errorNode = field.querySelector(AccountProfileDesk.cn.setElem('field-error').toSelector());

        if (errorNode) {
            errorNode.innerHTML = message;
            errorNode.classList.add(AccountProfileDesk.cn.setElem('field-error').setMode({ active: true }).toString());
        }
    }

    formValidate(): boolean {
        const formData = new FormData(this.form);

        let isValid = true;

        if (! validateEmail(this.emailField.value)) {
            isValid = false;
            this.renderFieldError('email', 'Email указан некорректно');
        }

        if (this.dateOfBirthdayField.value && ! validateDateOfBirthday(this.dateOfBirthdayField.value)) {
            isValid = false;
            this.renderFieldError('date-of-birthday', 'Дата указана некорректно');
        }

        if (! validatePhone(this.phoneField.value)) {
            isValid = false;
            this.renderFieldError('phone', 'Телефон указан некорректно');
        }

        if (formData.get('new-password') && (formData.get('new-password') as string).length <= 5 && this.togglePasswordField.checked) {
            isValid = false;
            this.renderFieldError('new-password', 'Длина пароля меньше 6 симолов');
        }

        if (formData.get('new-password') !== formData.get('password-confirm') && this.togglePasswordField.checked) {
            isValid = false;
            this.renderFieldError('password-confirm', 'Пароли не совпадают');
        }

        return isValid;
    }

    initDataInForm() {
        const fields = this.form.querySelectorAll<HTMLInputElement>(AccountProfileDesk.cn.setElem('input').toSelector());

        fields.forEach(field => {
            const dataValue = field.getAttribute('data-value');

            if (dataValue) {
                field.value = dataValue;
            }
        });
    }

    disableButton() {
        this.submitButton.setAttribute('disabled', 'true');
    }

    unableButton() {
        this.submitButton.removeAttribute('disabled');
    }

    openTrigger() {
        this.trigger.classList.remove(AccountProfileDesk.cn.setElem('trigger').setMode({ hidden: true }).toString());
    }

    hiddenTrigger() {
        this.trigger.classList.add(AccountProfileDesk.cn.setElem('trigger').setMode({ hidden: true }).toString());
    }

    openForm() {
        this.wrapperForm.classList.remove(AccountProfileDesk.cn.setElem('form').setMode({ hidden: true }).toString());
    }

    openContent() {
        this.wrapperContent.classList.remove(AccountProfileDesk.cn.setElem('content').setMode({ hidden: true }).toString());
    }

    hiddenForm() {
        this.wrapperForm.classList.add(AccountProfileDesk.cn.setElem('form').setMode({ hidden: true }).toString());
    }

    hiddenContent() {
        this.wrapperContent.classList.add(AccountProfileDesk.cn.setElem('content').setMode({ hidden: true }).toString());
    }

    isOpenForm() {
        return ! this.wrapperForm.classList.contains(AccountProfileDesk.cn.setElem('form').setMode({ hidden: true }).toString());
    }

    isActiveAccordion() {
        return this.accordion.getAttribute('data-accordion-is-active') === 'true';
    }
}
