import { Base } from '@gate31/core/src/libs/BaseComponent';
import { ClientResponseInt } from '@gate31/types/client';
import { ResponseBalance } from '@gate31/core/src/api/maxma';

interface AccountMilesOptions {
    clientData: ClientResponseInt;
    maxmaData: ResponseBalance;
}

export class AccountMilesDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('AccountMilesDesk');

    clientData: ClientResponseInt;
    maxmaData: ResponseBalance;

    LevelValueNode: HTMLElement | null;
    activeValueNode: HTMLElement | null;
    awaitingValueNode: HTMLElement | null;

    constructor(node: HTMLElement, options: AccountMilesOptions) {
        super(node);

        this.clientData = options.clientData;
        this.maxmaData = options.maxmaData;

        this.LevelValueNode = this.getRoot().querySelector(AccountMilesDesk.cn.setElem('level-value').toSelector());
        this.activeValueNode = this.getRoot().querySelector(AccountMilesDesk.cn.setElem('active-value').toSelector());
        this.awaitingValueNode = this.getRoot().querySelector(AccountMilesDesk.cn.setElem('awaiting-value').toSelector());

        this.connectionCallback();
    }

    connectionCallback() {
        this.showBonuses();
    }

    showBonuses() {
        const { bonuses, pendingBonuses } = this.maxmaData.response?.client;
        const { name: levelName } = this.maxmaData.response?.level;

        if (this.LevelValueNode && levelName) {
            this.LevelValueNode.textContent = levelName;
        }

        if (this.activeValueNode && bonuses) {
            this.activeValueNode.textContent = bonuses.toLocaleString();
        }

        if (this.awaitingValueNode && pendingBonuses) {
            this.awaitingValueNode.textContent = pendingBonuses.toLocaleString();
        }
    }
}
