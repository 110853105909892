import Swiper from 'swiper';
import { Base } from '@gate31/core/src/libs/BaseComponent';

export class InstagramDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('InstagramDesk');
    static list = new WeakMap<HTMLElement, InstagramDesk>();
    readonly slider: Swiper;

    constructor(node: HTMLElement) {
        super(node);

        this.slider = this.initSlider();
    }

    private initSlider() {
        const slides = this.getRoot().querySelectorAll(InstagramDesk.cn.setElem('slide').toSelector());

        return new Swiper(this.getRoot(), {
            loop: slides.length > 2,
            slidesPerView: 4,
            spaceBetween: 10,
            navigation: {
                nextEl: InstagramDesk.cn.setElem('btn-next').toSelector(),
                prevEl: InstagramDesk.cn.setElem('btn-prev').toSelector()
            }
        });
    }

    static getOrCreateInstance(Elem: HTMLElement): InstagramDesk {
        if (this.list.has(Elem)) {
            return this.list.get(Elem) as InstagramDesk;
        }

        const instance = new InstagramDesk(Elem);

        this.list.set(Elem, instance);

        return instance;
    }

    static init() {
        document.querySelectorAll<HTMLElement>(InstagramDesk.cn.setElem('slider').toSelector()).forEach(node => {
            this.list.set(node, new InstagramDesk(node));
        });
    }
}
