import { Base } from '@gate31/core/src/libs/BaseComponent';
import $ from 'jquery';

export class ShopPointsSliderDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ShopPointsSliderDesk');
    static list = new WeakMap<HTMLElement, ShopPointsSliderDesk>();

    index: string | number;
    dataIndexAttr: string;

    constructor(node: HTMLElement, index: string | number) {
        super(node);

        this.index = index;
        this.dataIndexAttr = 'data-index';

        this.connectedCallback();
    }

    connectedCallback() {
        this.getRoot().addEventListener('mouseover', e => {
            const target = e.target as HTMLElement;

            const currentIndex = this.getCurrentIndex(target);
            const $slide = this.getCurrentSlide(this.getRoot(), currentIndex);
            const $navigation = this.getCurrentNavigation(this.getRoot(), currentIndex);

            this.hiddenSlidesCurrentSlider(this.getRoot());
            this.hiddenNavigationsCurrentSlider(this.getRoot());

            this.openCurrentSlide($slide);
            this.openCurrentNavigation($navigation);
        });
    }

    private getCurrentIndex(target: HTMLElement) {
        const index = target.getAttribute(this.dataIndexAttr);

        if (! index) {
            throw new Error(`index not found: ${target}, [${this.dataIndexAttr}]`);
        }

        return index;
    }

    private getCurrentSlide(slider: HTMLElement, index: string | number) {
        const slideCn = ShopPointsSliderDesk.cn.setElem('image');
        const slide = slider.querySelector<HTMLImageElement>(`${slideCn.toSelector()}[${this.dataIndexAttr}="${index}"]`);

        if (! slide) {
            throw new Error(`element not found: ${slideCn.toSelector()}`);
        }

        return slide;
    }

    private getCurrentNavigation(slider: HTMLElement, index: string | number) {
        const slideCn = ShopPointsSliderDesk.cn.setElem('pagination-bullet');
        const slide = slider.querySelector<HTMLDivElement>(`${slideCn.toSelector()}[${this.dataIndexAttr}="${index}"]`);

        if (! slide) {
            throw new Error(`element not found: ${slideCn.toSelector()}`);
        }

        return slide;
    }

    private static getOrCreateInstance(Elem: HTMLElement): ShopPointsSliderDesk {
        if (this.list.has(Elem)) {
            return this.list.get(Elem) as ShopPointsSliderDesk;
        }

        const index = Elem.getAttribute('data-slider-wrapper-index') as string;
        const instance = new ShopPointsSliderDesk(Elem, index);

        this.list.set(Elem, instance);

        return instance;
    }

    private hiddenSlidesCurrentSlider(slider: HTMLElement) {
        const slideCn = ShopPointsSliderDesk.cn.setElem('image');
        const slideList = slider.querySelectorAll<HTMLImageElement>(slideCn.toSelector());

        slideList.forEach(slide => slide.classList.remove(slideCn.setMode({ active: true }).toString()));
    }

    private openCurrentNavigation(nav: HTMLDivElement) {
        const activeNavCn = ShopPointsSliderDesk.cn.setElem('pagination-bullet').setMode({ active: true });

        nav.classList.add(activeNavCn.toString());
    }

    private hiddenNavigationsCurrentSlider(slider: HTMLElement) {
        const navCn = ShopPointsSliderDesk.cn.setElem('pagination-bullet');
        const navCnList = slider.querySelectorAll<HTMLImageElement>(navCn.toSelector());

        navCnList.forEach(nav => nav.classList.remove(navCn.setMode({ active: true }).toString()));
    }

    private openCurrentSlide(slide: HTMLImageElement) {
        const activeSlideCn = ShopPointsSliderDesk.cn.setElem('image').setMode({ active: true });

        slide.classList.add(activeSlideCn.toString());
    }

    static init() {
        $(document).on('mouseover', this.cn.toSelector(), e => {
            const $slider = $(e.currentTarget).get(0);

            if (! $slider) {
                return;
            }

            this.getOrCreateInstance($slider);
        });
    }
}
