import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import Cart from 'Cart';
import { OrderLine } from '@gate31/types/order';
import { Variant } from '@gate31/types';
import { HeaderPopoverDesk } from '../HeaderPopoverDesk/HeaderPopoverDesk';

export class CartPopoverDesk extends BaseJs {
    container: HTMLElement
    itemCount: number;
    modalOpts: Record<string, string>;

    constructor({ container }: { container: HTMLElement | null }) {
        super('cart-popover-snippet-desk-template');

        if (! container) {
            throw new Error('not found container headerWrapper');
        }

        this.container = container;
        this.itemCount = 4;
        this.modalOpts = {
            type: 'cart',
            title: 'Корзина',
            link_text: 'оформить заказ',
            link_url: '/cart_items'
        };
    }

    getSize(variant: OrderLine) {
        const id = variant.id;

        const currentVar = variant.product.variants.find((item: Variant) => item.id === id);

        if (! currentVar) {
            return '';
        }

        const size = currentVar.option_values[1].title;

        return size;
    }

    show() {
        const products = Cart.order.order_lines;

        if (! products || ! products.length) {
            return;
        }

        const snippets = products.map(product => this.getHTML({ variant: product, size: this.getSize(product) })).reverse().join('');

        const modal = new HeaderPopoverDesk({
            ...this.modalOpts,
            content: snippets
        }, this.container);

        modal.render();
    }

    flash(products: OrderLine[]) {
        const snippets = products.map(product => this.getHTML({ variant: product, size: this.getSize(product) })).reverse().join('');

        const modal = new HeaderPopoverDesk({
            ...this.modalOpts,
            mode: 'short',
            content: snippets
        }, this.container);

        modal.shortRender();
    }

    hide() {
        HeaderPopoverDesk.destroy();
    }
}
