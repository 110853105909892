import EventBus from 'EventBus';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Product } from '@gate31/types';
import LiquidRender from '../../../uikit/common/scripts/liquid-render';
import FavoriteDesk from '../FavoriteDesk/FavoriteDesk';
import { ProductsSliderDesk } from '../ProductsSliderDesk/ProductsSliderDesk';

interface FavoritePageDeskPropsInt {
    rootNode: HTMLElement;
    favoriteDesk: typeof FavoriteDesk;
    productsSliderDesk: typeof ProductsSliderDesk;
}

export default class FavoritePageDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('FavoritePageDesk')

    productsWrapper: HTMLElement;

    favoriteDesk: typeof FavoriteDesk;
    productsSliderDesk: typeof ProductsSliderDesk;

    mainWrapper: HTMLElement;
    emptyWrapper: HTMLElement;
    errorWrapper: HTMLElement;

    constructor({ rootNode, favoriteDesk, productsSliderDesk }: FavoritePageDeskPropsInt) {
        super(rootNode);

        this.favoriteDesk = favoriteDesk;
        this.productsSliderDesk = productsSliderDesk;

        this.productsWrapper = FavoritePageDesk.querySelector(FavoritePageDesk.cn.setElem('list-items').toSelector());
        this.mainWrapper = FavoritePageDesk.querySelector(FavoritePageDesk.cn.setElem('main').toSelector());
        this.emptyWrapper = FavoritePageDesk.querySelector(FavoritePageDesk.cn.setElem('empty').toSelector());
        this.errorWrapper = FavoritePageDesk.querySelector(FavoritePageDesk.cn.setElem('error').toSelector());

        this.callbackConnection();
    }

    callbackConnection() {
        this.favoriteDesk.getItems()
            .then((products: Array<Product>) => {
                this.hideLoader();

                if (products.length) {
                    this.renderProducts(products);
                    this.openMain();
                    this.favoriteDesk.update();
                } else {
                    this.openEmpty();
                    this.productsSliderDesk.init();
                }
            })
            .catch(() => {
                this.openError();
                this.productsSliderDesk.init();
            });

        EventBus.subscribe('update_items:insales:favorites_products', data => {
            if (data.products.length) {
                this.renderProducts(data.products);
                this.openMain();
                this.hiddenEmpty();
            } else {
                this.hiddenMain();
                this.openEmpty();
                this.productsSliderDesk.init();
            }
        });
    }

    hideLoader() {
        this.getRoot().classList.remove(FavoritePageDesk.cn.setMode({ 'is-loader': true }).toString());
    }

    renderProducts(products: Array<Product>) {
        this.productsWrapper.innerHTML = products.map(product => {
            return LiquidRender.render('snippet-product-desktop-template', { data: product });
        }).join('');
    }

    openMain() {
        this.mainWrapper.classList.remove(FavoritePageDesk.cn.setElem('main').setMode({ hidden: true }).toString());
    }

    hiddenMain() {
        this.mainWrapper.classList.add(FavoritePageDesk.cn.setElem('main').setMode({ hidden: true }).toString());
    }

    openEmpty() {
        this.emptyWrapper.classList.remove(FavoritePageDesk.cn.setElem('empty').setMode({ hidden: true }).toString());
    }

    hiddenEmpty() {
        this.emptyWrapper.classList.add(FavoritePageDesk.cn.setElem('empty').setMode({ hidden: true }).toString());
    }

    openError() {
        this.errorWrapper.classList.remove(FavoritePageDesk.cn.setElem('error').setMode({ hidden: true }).toString());
    }
}
