import { CustomCn } from '@gate31/core/src/libs/classname';
import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { SearchBoxPopoverDesk } from '../SearchBoxPopoverDesk/SearchBoxPopoverDesk';
import { SearchBoxDesk } from '../SearchBoxDesk/SearchBoxDesk';

export class SearchBoxManagerDesk {
    static cn = new BaseJs.BuildCn('SearchBoxDesk');

    searchBoxDesk: SearchBoxDesk | undefined;

    modal: SearchBoxPopoverDesk;
    modalCn: CustomCn;
    modalTrigger: HTMLElement | null;
    headerWrapper: HTMLDivElement;

    modalCloseButton: HTMLElement | null;

    isRender: boolean;

    constructor({ modal, modalCn, modalTriggerSelector, headerWrapper }: { modal: SearchBoxPopoverDesk; modalCn: CustomCn; modalTriggerSelector: string; headerWrapper: HTMLDivElement }) {
        this.modal = modal;
        this.modalCn = modalCn;
        this.modalTrigger = document.querySelector(modalTriggerSelector);
        this.headerWrapper = headerWrapper;

        this.isRender = false;

        this.callbackConnection();
    }

    callbackConnection() {
        if (this.modalTrigger) {
            this.modalTrigger.addEventListener('mouseenter', () => {
                if (! this.isRender) {
                    this.modal.render();
                    this.isRender = true;

                    this.addEventListener();
                    this.searchBoxDesk?.focus();
                }
            });
        }

        this.headerWrapper.addEventListener('mouseleave', () => {
            if (this.modal && this.isRender) {
                this.modal.closeSlowly();

                const hiddenTimeoutId = setTimeout(() => {
                    this.modal.destroyElement();
                    this.isRender = false;
                }, 2000);

                this.modal.getRoot().addEventListener('mouseenter', () => {
                    clearTimeout(hiddenTimeoutId);
                    this.modal.openSlowly();
                });
            }
        });
    }

    addEventListener() {
        this.searchBoxDesk = new SearchBoxDesk(this.headerWrapper.querySelector(SearchBoxDesk.cn.toSelector()));

        if (this.searchBoxDesk.buttonClose) {
            this.searchBoxDesk.buttonClose.addEventListener('click', () => {
                if (this.isRender) {
                    this.modal.destroyElement();
                    this.isRender = false;
                }
            });
        }
    }
}
