import { Base } from '@gate31/core/src/libs/BaseComponent';
import EventBus from 'EventBus';
import Shop from 'Shop';
import { InputCode } from '@gate31/uikit/common/components/InputCode/InputCode';
import Recaptcha from '@gate31/feature/recaptcha/recaptcha';

export class LoginAccountPhone extends Base<HTMLFormElement> {
    static cn = new Base.BuildCn('LoginAccountPhone');

    loginInput: HTMLInputElement;
    submitButton: HTMLButtonElement;

    phoneFormNode: HTMLInputElement;
    smsFormNode: HTMLInputElement;
    phoneNode: HTMLElement;
    timeoutNode: HTMLElement;
    timeoutValueNode: HTMLElement;
    timeoutSubmitButton: HTMLButtonElement;
    backNode: HTMLElement;

    errorPhoneClassName: string;
    errorSmsClassName: string;

    inputCode: InputCode;

    formAction: string;
    formMethod: string;

    recaptchaNode: HTMLElement | null;
    isUseCaptcha: boolean;
    recaptchaInstanse: Recaptcha | undefined;
    recaptchaId: number | undefined;

    constructor(node: HTMLFormElement) {
        super(node);

        this.loginInput = this.getRoot().querySelector('[type=tel]')!;
        this.submitButton = this.getRoot().querySelector('[type=submit]')!;

        // @ts-expect-error
        this.phoneFormNode = this.getRoot().querySelector(this.constructor.cn.setElem('phone-form').toSelector());
        // @ts-expect-error
        this.smsFormNode = this.getRoot().querySelector(this.constructor.cn.setElem('sms-form').toSelector());
        // @ts-expect-error
        this.phoneNode = this.getRoot().querySelector(this.constructor.cn.setElem('phone-value').toSelector());
        // @ts-expect-error
        this.timeoutNode = this.getRoot().querySelector(this.constructor.cn.setElem('timeout-info').toSelector());
        // @ts-expect-error
        this.timeoutValueNode = this.getRoot().querySelector(this.constructor.cn.setElem('timeout-value').toSelector());
        // @ts-expect-error
        this.timeoutSubmitButton = this.getRoot().querySelector(this.constructor.cn.setElem('timeout-submit').toSelector());
        // @ts-expect-error
        this.errorPhoneClassName = this.constructor.cn.setElem('error').toString();
        // @ts-expect-error
        this.errorSmsClassName = this.constructor.cn.setElem('sms-error').toString();
        // @ts-expect-error
        this.backNode = this.getRoot().querySelector(this.constructor.cn.setElem('back').toSelector());

        // @ts-expect-error
        this.inputCode = new InputCode(this.constructor.querySelector(this.constructor.cn.setElem('code').toSelector()));

        this.formAction = this.getRoot().action;
        this.formMethod = this.getRoot().method;

        // @ts-expect-error
        this.recaptchaNode = this.getRoot().querySelector(this.constructor.cn.setElem('recaptcha').toSelector());
        this.isUseCaptcha = window?.Site?.settings?.captchaIsUse === 'true';

        this.connectionCallback();
    }

    connectionCallback() {
        EventBus.subscribe('grecaptch:loading', () => {
            this.recaptchaInstanse = new Recaptcha({
                // @ts-expect-error
                containerSelector: this.constructor.cn.setElem('recaptcha').toSelector(),
                siteKey: Shop.config.get('recaptcha_key')?.recaptcha_key
            });

            if (this.isUseCaptcha) {
                this.toggleHiddenRecaptcha();
                this.recaptchaId = this.recaptchaInstanse.render();
            }
        });

        this.loginInput.addEventListener('keyup', () => {
            this.toggleDisableSubmit(! this.isValidPhone());
            this.hideAllErrors();
        });

        this.getRoot().addEventListener('input', () => {
            this.hideAllErrors();
        });

        this.getRoot().addEventListener('beforeinput', () => {
            this.hideAllErrors();
        });

        this.timeoutSubmitButton.addEventListener('click', () => {
            this.hideAllErrors();
        });

        this.getRoot().addEventListener('submit', evt => {
            this.handleSubmitPhone(evt);
        });

        this.inputCode.onSubmit = () => {
            this.handleSubmitSms();
        };

        this.backNode?.addEventListener('click', () => {
            this.showForm('phone');
        });
    }

    toggleHiddenRecaptcha(isHidden?: boolean) {
        if (! this.recaptchaNode) return;

        // @ts-expect-error
        const activeClass = this.constructor.cn.setElem('recaptcha').setMode({ active: true }).toString();

        this.recaptchaNode.classList[isHidden ? 'remove' : 'add'](activeClass);
    }

    isRenderedRecaptcha() {
        return this.recaptchaId !== undefined;
    }

    handleSubmitPhone(evt: SubmitEvent) {
        evt.preventDefault();

        const formData = new FormData(this.getRoot());

        fetch(this.formAction, {
            method: this.formMethod,
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    this.phoneNode.textContent = formData.get('login') as string ?? '';
                    this.showForm('sms');
                    this.renderTimeout(data.timeout);
                }

                if (data.status === 'error') {
                    const timeoutInfo = data.timeout ? ` ${data.timeout} сек` : '';

                    this.showError(this.errorPhoneClassName, `${data.errors[0]}${timeoutInfo}`);

                    if (! data.timeout) {
                        this.showError(this.errorSmsClassName, data.errors[0]);
                    }
                }
            })
            .catch(() => {
                this.showError(this.errorPhoneClassName);
            });
    }

    handleSubmitSms() {
        const formData = new FormData(this.getRoot());
        const code = formData.getAll('code').join('');

        formData.delete('code');
        formData.set('code', code);

        fetch('/client_account/session.json', {
            method: this.formMethod,
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    window.location.href = data?.redirect_to ?? '/client_account/orders';
                }

                if (data.status === 'error') {
                    this.showError(this.errorSmsClassName, data.errors[0]);

                    if (! this.isRenderedRecaptcha() && data.errors[0] === 'Пожалуйста, подтвердите, что вы не робот') {
                        this.toggleHiddenRecaptcha();
                        this.recaptchaId = this.recaptchaInstanse?.render();
                    }
                }
            })
            .catch(() => {
                this.showError(this.errorSmsClassName);
            })
            .finally(() => {
                if (this.isRenderedRecaptcha()) this.recaptchaInstanse?.reset();
            });
    }

    isValidPhone() {
        return Boolean(this.loginInput.value.length);
    }

    toggleDisableSubmit(isDisabled: boolean) {
        this.submitButton.disabled = isDisabled;
    }

    showError(containerClassName: string, message?: string) {
        const errorCn = new Base.BuildCn(containerClassName);
        // @ts-expect-error
        const errorNode = this.constructor.querySelector(errorCn.toSelector());

        if (! errorNode) return;

        const errorActiveClass = errorCn.setMode({ active: true }).toString();

        errorNode.textContent = message ?? 'Произошла ошибка. Попробуйте повторить действия позже.';

        errorNode.classList.add(errorActiveClass);
    }

    hideError(containerClassName: string) {
        const errorCn = new Base.BuildCn(containerClassName);
        // @ts-expect-error
        const errorNode = this.constructor.querySelector(errorCn.toSelector());

        if (! errorNode) return;

        const errorActiveClass = errorCn.setMode({ active: true }).toString();

        errorNode.classList.remove(errorActiveClass);
    }

    hideAllErrors() {
        this.hideError(this.errorPhoneClassName);
        this.hideError(this.errorSmsClassName);
    }

    renderTimeout(timeout: number) {
        // @ts-expect-error
        const activeClass = this.constructor.cn.setElem('timeout-info').setMode({ active: true }).toString();
        // @ts-expect-error
        const submitButtonactiveClass = this.constructor.cn.setElem('timeout-submit').setMode({ active: true }).toString();
        let index = 1;

        this.timeoutValueNode.textContent = (timeout).toString();
        this.timeoutNode.classList.add(activeClass);
        this.timeoutSubmitButton.classList.remove(submitButtonactiveClass);

        const intervalId = setInterval(() => {
            this.timeoutValueNode.textContent = (timeout - index).toString();
            index++;

            if (index > timeout) {
                this.timeoutNode.classList.remove(activeClass);
                this.timeoutSubmitButton.classList.add(submitButtonactiveClass);
                clearInterval(intervalId);
            }
        }, 1000);
    }

    showForm(form: 'phone' | 'sms') {
        // @ts-expect-error
        const activePhoneFormClass = this.constructor.cn.setElem('phone-form').setMode({ active: true }).toString();
        // @ts-expect-error
        const activeSmsFormClass = this.constructor.cn.setElem('sms-form').setMode({ active: true }).toString();

        if (form === 'phone') {
            this.phoneFormNode.classList.add(activePhoneFormClass);
            this.smsFormNode.classList.remove(activeSmsFormClass);
            this.onShowPhoneForm();
        } else if (form === 'sms') {
            this.phoneFormNode.classList.remove(activePhoneFormClass);
            this.smsFormNode.classList.add(activeSmsFormClass);
            this.inputCode.reset();
            this.inputCode.focus();
            this.onShowSmsForm();
        }
    }

    onShowPhoneForm() {}

    onShowSmsForm() {}
}
