import $ from 'jquery';
import { Base } from '@gate31//core/src/libs/BaseComponent';

class SnippetProductSliderDeskError extends Error {
    name = 'SnippetProductSliderDeskError'
}

export class SnippetProductSliderDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('SnippetProductSliderDesk');
    static list = new WeakMap<HTMLElement, SnippetProductSliderDesk>();

    private readonly dataIndexAttr = 'data-index';

    constructor(node: HTMLElement) {
        super(node);

        this.connectedCallback();
    }

    private static getOrCreateInstance(Elem: HTMLElement): SnippetProductSliderDesk {
        if (this.list.has(Elem)) {
            return this.list.get(Elem) as SnippetProductSliderDesk;
        }

        const instance = new SnippetProductSliderDesk(Elem);

        this.list.set(Elem, instance);

        return instance;
    }

    static init() {
        $(document).on('mouseover', this.cn.toSelector(), e => {
            const $slider = $(e.currentTarget).get(0);

            if (! $slider) {
                return;
            }

            this.getOrCreateInstance($slider);
        });
    }

    connectedCallback() {
        this.getRoot().addEventListener('mouseover', e => {
            const target = e.target as HTMLElement;

            const currentIndex = this.getCurrentIndex(target);
            const $slide = this.getCurrentSlide(this.getRoot(), currentIndex);
            const $navigation = this.getCurrentNavigation(this.getRoot(), currentIndex);

            this.hiddenSlidesCurrentSlider(this.getRoot());
            this.hiddenNavigationsCurrentSlider(this.getRoot());

            this.openCurrentSlide($slide);
            this.openCurrentNavigation($navigation);
        });
    }

    private openCurrentNavigation(nav: HTMLDivElement) {
        const activeNavCn = SnippetProductSliderDesk.cn.setElem('navigation').setMode({ active: true });

        nav.classList.add(activeNavCn.toString());
    }

    private hiddenNavigationsCurrentSlider(slider: HTMLElement) {
        const navCn = SnippetProductSliderDesk.cn.setElem('navigation');
        const navCnList = slider.querySelectorAll<HTMLImageElement>(navCn.toSelector());

        navCnList.forEach(nav => nav.classList.remove(navCn.setMode({ active: true }).toString()));
    }

    private openCurrentSlide(slide: HTMLImageElement) {
        const activeSlideCn = SnippetProductSliderDesk.cn.setElem('image').setMode({ active: true });

        slide.classList.add(activeSlideCn.toString());
    }

    private hiddenSlidesCurrentSlider(slider: HTMLElement) {
        const slideCn = SnippetProductSliderDesk.cn.setElem('image');
        const slideList = slider.querySelectorAll<HTMLImageElement>(slideCn.toSelector());

        slideList.forEach(slide => slide.classList.remove(slideCn.setMode({ active: true }).toString()));
    }

    private getCurrentNavigation(slider: HTMLElement, index: string | number) {
        const slideCn = SnippetProductSliderDesk.cn.setElem('navigation');
        const slide = slider.querySelector<HTMLDivElement>(`${slideCn.toSelector()}[${this.dataIndexAttr}="${index}"]`);

        if (! slide) {
            throw new SnippetProductSliderDeskError(`element not found: ${slideCn.toSelector()}`);
        }

        return slide;
    }

    private getCurrentSlide(slider: HTMLElement, index: string | number) {
        const slideCn = SnippetProductSliderDesk.cn.setElem('image');
        const slide = slider.querySelector<HTMLImageElement>(`${slideCn.toSelector()}[${this.dataIndexAttr}="${index}"]`);

        if (! slide) {
            throw new SnippetProductSliderDeskError(`element not found: ${slideCn.toSelector()}`);
        }

        return slide;
    }

    private getCurrentIndex(target: HTMLElement) {
        const index = target.getAttribute(this.dataIndexAttr);

        if (! index) {
            throw new SnippetProductSliderDeskError(`index not found: ${target}, [${this.dataIndexAttr}]`);
        }

        return index;
    }

    private getCurrentSlider(target: HTMLElement) {
        const slider = target.closest<HTMLDivElement>(SnippetProductSliderDesk.cn.toSelector());

        if (! slider) {
            throw new SnippetProductSliderDeskError(`element not found: ${SnippetProductSliderDesk.cn.toSelector()}`);
        }

        return slider;
    }
}

