import { Base } from '@gate31/core/src/libs/BaseComponent';
import { CustomCn } from '@gate31/core/src/libs/classname';

export class ProductVideoDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ProductVideoDesk');
    static controlDisplayTime = 2000;
    static videos = new Map<HTMLElement, ProductVideoDesk>();

    hiddenButtonCn: CustomCn;

    playButton: HTMLDivElement | null;
    pauseButton: HTMLDivElement | null;

    observer: IntersectionObserver | undefined;
    player: {
        play: () => Promise<void>;
        pause: () => Promise<void>;
    }

    constructor({ node }: { node: HTMLElement }) {
        super(node);

        this.hiddenButtonCn = ProductVideoDesk.cn.setElem('video-btn').setMode({ hidden: true });

        this.playButton = this.getRoot().querySelector<HTMLDivElement>(ProductVideoDesk.cn.setElem('video-play').toSelector());
        this.pauseButton = this.getRoot().querySelector<HTMLDivElement>(ProductVideoDesk.cn.setElem('video-pause').toSelector());

        this.observer = new IntersectionObserver(e => {
            this.observerCallback(e);
        });
        this.observer.observe(this.getRoot());

        this.connectionCallback();

        ProductVideoDesk.videos.set(node, this);
    }

    static init() {
        document.querySelectorAll<HTMLElement>(ProductVideoDesk.cn.toSelector()).forEach(node => {
            if (! ProductVideoDesk.videos.has(node)) {
                // eslint-disable-next-line
                new ProductVideoDesk({ node });
            }
        });
    }

    showControl() {
        this.getRoot().classList.add(ProductVideoDesk.cn.setMode({ active: true }).toString());
    }

    hiddenControl() {
        this.getRoot().classList.remove(ProductVideoDesk.cn.setMode({ active: true }).toString());
    }

    play() {
        if (! this.player) {
            this.createPlayer();
        }

        this.player.play().catch(() => {});

        this.hiddenPlayButton();
        this.showPauseButton();
    }

    pause() {
        if (! this.player) {
            this.createPlayer();
        }

        this.player.pause().catch(() => {});

        this.hiddenPauseButton();
        this.showPlayButton();
    }

    hiddenPlayButton() {
        this.playButton?.classList.add(this.hiddenButtonCn.toString());
    }
    showPlayButton() {
        this.playButton?.classList.remove(this.hiddenButtonCn.toString());
    }
    hiddenPauseButton() {
        this.pauseButton?.classList.add(this.hiddenButtonCn.toString());
    }
    showPauseButton() {
        this.pauseButton?.classList.remove(this.hiddenButtonCn.toString());
    }

    createPlayer() {
        this.player = new window.Vimeo.Player(this.getRoot().querySelector('iframe'));
    }

    connectionCallback() {
        this.playButton?.addEventListener('click', () => {
            this.play();
        });
        this.pauseButton?.addEventListener('click', () => {
            this.pause();
        });
    }

    observerCallback(entries: Array<IntersectionObserverEntry>) {
        if (! this.player) {
            this.createPlayer();
        }

        if (entries[0].isIntersecting) {
            // Докрутили до видео

            this.play();

            this.showControl();
            setTimeout(() => {
                this.hiddenControl();
            }, ProductVideoDesk.controlDisplayTime);
        } else {
            // Перекрутили видео

            this.pause();
        }
    }
}
