import { createSelector } from '@reduxjs/toolkit';
import { checkedFieldCounter } from '@gate31/feature/Filters';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Connect } from '@gate31/core/src/redux/store';
import { desktopStore, DesktopState } from '../../scripts/store';

/* eslint-disable */
const selectFiltersState = createSelector(
    [
        checkedFieldCounter
    ],
    (
        checkedFieldCounter
    ) => {
        return {
            checkedFieldCounter
        };
    }
);
/* eslint-unable */

@Connect(desktopStore)
export class FiltersTriggerDesk extends Base<HTMLButtonElement> {
    static cn = new Base.BuildCn('FiltersTriggerDesk');

    $filtersTriggerCounter: HTMLDivElement;
    $filtersTriggerText: HTMLDivElement;

    constructor(node: HTMLButtonElement) {
        super(node);

        this.$filtersTriggerCounter = this.findElem<HTMLDivElement>(FiltersTriggerDesk.cn.setElem('counter').toSelector());
        this.$filtersTriggerText = this.findElem<HTMLDivElement>(FiltersTriggerDesk.cn.setElem('text').toSelector());

        this.connectionCallback();
    }

    connectionCallback() {
        this.getRoot().addEventListener('click', this.handleClick);
    }

    handleClick = () => {
        desktopStore.actions.filtersToggleOpen();
    }

    onStoreChange(state: DesktopState) {
        const { checkedFieldCounter } = selectFiltersState(state);

        this.updateCounterValue(checkedFieldCounter);

        if (checkedFieldCounter && checkedFieldCounter > 0) {
            this.openCounter();
        } else {
            this.hiddenCounter();
        }
    }

    updateCounterValue(checkedFieldCounter: number) {
        this.$filtersTriggerCounter.textContent = String(checkedFieldCounter);
    }

    hiddenCounter() {
        this.$filtersTriggerCounter.classList.remove(FiltersTriggerDesk.cn.setElem('counter').setMode({ show: true }).toString());
    }

    openCounter() {
        this.$filtersTriggerCounter.classList.add(FiltersTriggerDesk.cn.setElem('counter').setMode({ show: true }).toString());
    }
}
