import { Base } from '@gate31/core/src/libs/BaseComponent';
import EventBus from 'EventBus';
import Shop from 'Shop';
import Recaptcha from '@gate31/feature/recaptcha/recaptcha';

export class LoginAccountEmail extends Base<HTMLFormElement> {
    static cn = new Base.BuildCn('LoginAccountEmail');

    emailInput: HTMLInputElement;
    passwordInput: HTMLInputElement;
    submitButton: HTMLButtonElement;

    formAction: string;
    formMethod: string;
    errorNode: HTMLElement | null;
    recaptchaNode: HTMLElement | null;

    isUseCaptcha: boolean;
    recaptchaInstanse: Recaptcha | undefined;
    recaptchaId: number | undefined;

    constructor(node: HTMLFormElement) {
        super(node);

        this.emailInput = this.getRoot().querySelector('[name=email]')!;
        this.passwordInput = this.getRoot().querySelector('[name=password]')!;
        this.submitButton = this.getRoot().querySelector('[type=submit]')!;
        // @ts-expect-error
        this.errorNode = this.getRoot().querySelector(this.constructor.cn.setElem('error').toSelector());
        // @ts-expect-error
        this.recaptchaNode = this.getRoot().querySelector(this.constructor.cn.setElem('recaptcha').toSelector());

        this.formAction = this.getRoot().action;
        this.formMethod = this.getRoot().method;

        this.isUseCaptcha = window?.Site?.settings?.captchaIsUse === 'true';

        this.connectionCallback();
    }

    connectionCallback() {
        EventBus.subscribe('grecaptch:loading', () => {
            this.recaptchaInstanse = new Recaptcha({
                // @ts-expect-error
                containerSelector: this.constructor.cn.setElem('recaptcha').toSelector(),
                siteKey: Shop.config.get('recaptcha_key')?.recaptcha_key
            });

            if (this.isUseCaptcha) {
                this.toggleHiddenRecaptcha();
                this.recaptchaId = this.recaptchaInstanse.render();
            }
        });

        this.getRoot().addEventListener('input', () => {
            this.toggleDisableSubmit(! this.isValid());
            this.hideError();
        });
        this.getRoot().addEventListener('submit', evt => {
            this.handleForm(evt);
        });
    }

    isValid() {
        return Boolean(this.emailInput.value.length) && Boolean(this.passwordInput.value.length);
    }

    toggleDisableSubmit(isDisabled: boolean) {
        this.submitButton.disabled = isDisabled;
    }

    showError(message?: string) {
        if (! this.errorNode) return;

        // @ts-expect-error
        const errorActiveClass = this.constructor.cn.setElem('error').setMode({ active: true }).toString();

        this.errorNode.textContent = message ?? 'Произошла ошибка. Попробуйте повторить действия позже.';

        this.errorNode.classList.add(errorActiveClass);
    }

    hideError() {
        // @ts-expect-error
        const errorActiveClass = this.constructor.cn.setElem('error').setMode({ active: true }).toString();

        this.errorNode?.classList.remove(errorActiveClass);
    }

    toggleHiddenRecaptcha(isHidden?: boolean) {
        if (! this.recaptchaNode) return;

        // @ts-expect-error
        const activeClass = this.constructor.cn.setElem('recaptcha').setMode({ active: true }).toString();

        this.recaptchaNode.classList[isHidden ? 'remove' : 'add'](activeClass);
    }

    isRenderedRecaptcha() {
        return this.recaptchaId !== undefined;
    }

    // eslint-disable-next-line
    onSubmit(formData: FormData) {}

    handleForm(evt: Event) {
        evt.preventDefault();

        const formData = new FormData(this.getRoot());

        this.onSubmit(formData);

        if (this.isRenderedRecaptcha()) {
            formData.set('g-recaptcha-response', this.recaptchaInstanse?.getResponse() ?? '');
        }

        this.toggleDisableSubmit(true);

        fetch(this.formAction, {
            method: this.formMethod,
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ok') {
                    window.location.href = data?.redirect_to ?? '/client_account/orders';
                }

                if (data.status !== 'error') return;

                if (! this.isRenderedRecaptcha() && data.errors[0] === 'Пожалуйста, подтвердите, что вы не робот') {
                    this.toggleHiddenRecaptcha();
                    this.recaptchaId = this.recaptchaInstanse?.render();
                }

                this.showError(data.errors[0]);
            })
            .catch(() => {
                this.showError();
            })
            .finally(() => {
                this.toggleDisableSubmit(false);
                if (this.isRenderedRecaptcha()) this.recaptchaInstanse?.reset();
            });
    }
}
