import { Base } from '@gate31/core/src/libs/BaseComponent';
import { CustomCn } from '@gate31/core/src/libs/classname';
import Cart from 'Cart';
import EventBus from 'EventBus';
import * as Sentry from '@sentry/browser';

class CartPopoverSnippetDeskError extends Error {
    name = 'CartPopoverSnippetDeskError'
}

export class CartPopoverSnippetDesk {
    static cn = new Base.BuildCn('CartPopoverSnippetDesk');

    buttonPlusCn: CustomCn;
    buttonMinusCn: CustomCn;
    buttonRemoveCn: CustomCn;

    constructor() {
        this.buttonPlusCn = CartPopoverSnippetDesk.cn.setElem('counter-plus');
        this.buttonMinusCn = CartPopoverSnippetDesk.cn.setElem('counter-minus');
        this.buttonRemoveCn = CartPopoverSnippetDesk.cn.setElem('remove');

        this.connectionCallback();
    }

    connectionCallback() {
        EventBus.subscribe('update_items:insales:cart', e => {
            if (! e.action.currentItems) {
                return;
            }

            const item = e.action.currentItems[0];

            const id = item.id;
            const quantity = item.quantity;

            this.updateCounter({
                id, quantity
            });
        });

        EventBus.subscribe('delete_items:insales:cart', e => {
            const id = e.action.items[0];

            this.removeItem(id);
        });

        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;

            if (target.closest(this.buttonRemoveCn.toSelector())) {
                const variantId = Number(target.closest(this.buttonRemoveCn.toSelector())?.getAttribute('data-trigger-remove'));

                if (! variantId) {
                    return Sentry.captureException(new CartPopoverSnippetDeskError('Не найден id элемента data-trigger-remove'), {
                        tags: {
                            section: 'popover'
                        }
                    });
                }

                Cart.delete({ items: [ variantId ] });
            }

            if (target.closest(this.buttonPlusCn.toSelector())) {
                const variantId = Number(target.closest(this.buttonPlusCn.toSelector())?.getAttribute('data-trigger-plus'));

                if (! variantId) {
                    return Sentry.captureException(new CartPopoverSnippetDeskError('Не найден id элемента data-trigger-plus'), {
                        tags: {
                            section: 'popover'
                        }
                    });
                }

                Cart.add({
                    items: { [variantId]: 1 }
                });
            }

            if (target.closest(this.buttonMinusCn.toSelector())) {
                const variantId = Number(target.closest(this.buttonMinusCn.toSelector())?.getAttribute('data-trigger-minus'));

                if (! variantId) {
                    return Sentry.captureException(new CartPopoverSnippetDeskError('Не найден id элемента data-trigger-minus'), {
                        tags: {
                            section: 'popover'
                        }
                    });
                }

                const currentItem = Cart.order.order_lines.find(item => item.id === variantId);

                if (currentItem && currentItem.quantity > 1) {
                    Cart.remove({
                        items: { [variantId]: 1 }
                    });
                }
            }
        });
    }

    removeItem(id: number) {
        const snippet = document.querySelector(CartPopoverSnippetDesk.cn.toSelector() + `[data-variant-id="${id}"]`);

        if (! snippet) {
            return;
        }

        snippet.remove();
    }

    updateCounter({ id, quantity }: { id: number; quantity: number }) {
        const snippet = document.querySelector(CartPopoverSnippetDesk.cn.toSelector() + `[data-variant-id="${id}"]`);

        if (! snippet) {
            return;
        }

        const counterNode = snippet.querySelector(CartPopoverSnippetDesk.cn.setElem('counter-count').toSelector());

        if (! counterNode) {
            return;
        }

        counterNode.innerHTML = String(quantity);
    }
}
