// @ts-nocheck
import { Product, ProductLiquid } from '@gate31/types';
import Shop from 'Shop';

export function declOfNum(number, titles) {
    const cases = [ 2, 0, 1, 1, 1, 2 ];

    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

export function numWord(value, words){
    value = Math.abs(value) % 100;

    let num = value % 10;
    if(value > 10 && value < 20) return words[2];
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0];

    return words[2];
}

export function priceFormat(price: string | number) {
    return Shop.money.format(price).replace('руб.', '₽');
}


export function getCookie(name: string) {
    const matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function changeProductObject(data: Product[]) {
    const newData: ProductLiquid[] = JSON.parse(JSON.stringify(data));

    Object.values(newData).forEach(product => {
        // Цена
        // if (product.variants[0].old_price) {
        //     product.variants[0].old_price = priceFormat(product.variants[0].old_price);
        // }
        // if (product.variants[0].price) {
        //     product.variants[0].price = priceFormat(product.variants[0].price);
        // }
        // if (product.variants[0].base_price) {
        //     product.variants[0].base_price = priceFormat(product.variants[0].base_price);
        // }

        // Бейджи товара
        const badge = product.properties.find(item => item.permalink === 'badge' ? item.id : undefined);
        const isShowVideo = product.properties.find(item => item.permalink === 'pokazyvat-video' ? item.id : undefined);
        const isSale = product.properties.find(item => item.permalink === 'aktsiya' ? item.id : undefined);

        let newProperties = {
            badge: {
                characteristics: [{ name: '' }]
            },
            'pokazyvat-video': {
                characteristics: []
            }
        };
        if (badge !== undefined) {
            const id = badge.id;

            newProperties = {
                badge: {
                    characteristics: [
                        { name: product.characteristics.filter(item => item.property_id === id)[0].title }
                    ]
                },
            };
        }

        if (isShowVideo !== undefined) {
            const id = isShowVideo.id;

            newProperties['pokazyvat-video'] = {
                characteristics: [
                    { name: product.characteristics.filter(item => item.property_id === id)[0].title }
                ]
            };
        }

        if (isSale !== undefined) {
            const id = isSale.id;

            newProperties['aktsiya'] = {
                characteristics: product.characteristics
                    .filter(item => item.property_id === id)
                    .map(item => ({ handle: item.permalink }))
            };
        }

        product.properties = newProperties;

        // Изображение товара
        const imgUrl = product.images[0].original_url;

        product.first_image = {
            original_url: imgUrl
        };
    });

    return newData;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validateDateOfBirthday = (value: string | null | undefined) => {
    if (! value) {
        return false;
    }

    return /^\d{2}\.\d{2}\.\d{4}$/.test(value)
}

export const validatePhone = (value) => {
    if (! value) {
        return false;
    }

    return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(value)
}

export function buildBackgroundColorProduct(colorArray): string {
    const colorLength = colorArray.length;

    const linearGradient = colorArray.map((color, index) => {
        const fillArea = 100 / colorLength;
        const colorStartPosition = fillArea * index;
        const colorEndPosition = fillArea * (index + 1);

        return `${color} ${colorStartPosition}% ${colorEndPosition}%`
    }).join(', ')

    return `linear-gradient(90deg, ${linearGradient})`;
}
