import { createSelector } from '@reduxjs/toolkit';
import { Filters } from '@gate31/feature/Filters/filters';
import { Connect, MemoSelect } from '@gate31/core/src/redux/store';
import {
    checkedFieldCounter,
    fetchResultCounter,
    filterState, selectIsFulfilledCounter,
    selectIsPendingCounter,
    statusCounter
} from '@gate31/feature/Filters';
import { LiquidTemplates } from '@gate31/uikit/common/scripts/liquid-render';
import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { numWord } from '@gate31/core/src/libs/utils';
import { desktopStore, DesktopState } from '../../scripts/store';

/* eslint-disable */
const selectFetchResultCounter = createSelector([fetchResultCounter, selectIsFulfilledCounter], (fetchResultCounter, selectIsFulfilledCounter) => {
    return {
        fetchResultCounter, selectIsFulfilledCounter
    };
});
const selectStatusCounter = createSelector([statusCounter, selectIsPendingCounter], (statusCounter, counterIsPending) => {
    return {
        statusCounter, counterIsPending
    };
});
const selectCheckedFieldCounter = createSelector([checkedFieldCounter], (checkedFieldCounter) => {
    return {
        checkedFieldCounter
    };
});
const selectFilterState = createSelector([filterState], (filterState) => {
    return {
        filterState
    };
});

/* eslint-unable */

class FilterDeskError extends Error {
    name = 'FilterDeskError';
}

@Connect(desktopStore)
export class FiltersDesk extends Filters<DesktopState> {
    static cn = new BaseJs.BuildCn('FiltersDesk');

    filtersStateKeys: Array<string>;

    constructor(template: keyof LiquidTemplates) {
        super(template);

        this.filtersStateKeys = ['options[788084][]', 'options[788085][]', 'characteristics[]'];

        desktopStore.actions.init({
            data: {
                size: window?.GATE31?.COLLECTION_OPTIONS?.sizes,
                color: window?.GATE31?.COLLECTION_OPTIONS?.colors.map(color => {
                    return { ...color, background: this.getColorBackground(color)};
                }),
                composition: window?.GATE31?.COLLECTION_OPTIONS?.composition,
                sex: window?.GATE31?.COLLECTION_OPTIONS?.sex
            },
            state: this.getStateFromUrl(),
        });
    }

    getStateFromUrl() {
        const state = desktopStore.getState();
        const query = state.routing.locationBeforeTransitions.query;
        const filterState: Record<string, string[]> = {};

        Object.keys(query).map(key => {
            if (this.filtersStateKeys.includes(key)) {
                if (typeof query[key] === 'string') {
                    filterState[key] = [query[key]] as Array<string>;
                } else {
                    filterState[key] = query[key] as Array<string>;
                }
            }
        });

        return filterState;
    }

    connectedCallback() {
        this.getRoot().addEventListener('change', this.onFiltersChange);
        this.getRoot().addEventListener('submit', e => {
            e.preventDefault();

            this.onSubmit();
        });
        this.findElem(FiltersDesk.cn.setElem('reset').toSelector()).addEventListener('click', () => {
            this.onReset();
        })
        this.findElem(FiltersDesk.cn.setElem('close-button').toSelector()).addEventListener('click', () => {
            desktopStore.actions.setFilterState(this.getStateFromUrl());
            desktopStore.actions.filtersToggleOpen();
        });
        this.getRoot().addEventListener('click', e => {
            const target = e.target as HTMLElement;

            if (target.classList.contains((this.constructor as typeof Filters).cn.setElem('column-reset-button').toString())) {
                const dataNameAttr = 'data-reset-filters';
                const dataKeyAttr = 'data-reset-filters-key';

                const columnName = target.getAttribute(dataNameAttr);
                const columnKey = target.getAttribute(dataKeyAttr);

                if (! columnName) {
                    throw new Error(`not fount data-attr in reset button: ${dataNameAttr}`);
                }

                if (! columnKey) {
                    throw new Error(`not fount data-attr in reset button: ${columnKey}`);
                }

                this.onUncheckedColumn(columnName, columnKey);
            }
        });
    }

    onSubmit = () => {}

    onReset = () => {}

    onUncheckedColumn(groupName: string, groupKey: string) {
        desktopStore.actions.clearFilterGroup({ groupName, groupKey })
        desktopStore.actions.fetchFiltersCounter({
            filters: desktopStore.getState().filters.state
        });
    }

    onFiltersChange = () => {
        desktopStore.actions.setFilterState(this.getFiltersState());

        desktopStore.actions.fetchFiltersCounter({
            filters: desktopStore.getState().filters.state
        });
    }

    @MemoSelect(selectFilterState)
    onFiltersStateChange(state: DesktopState) {
        if (! state.filters.isOpen) {
            return;
        }

        this.getRoot().querySelectorAll<HTMLInputElement>(FiltersDesk.cn.setElem('checkbox-control').toSelector())
            .forEach(field => {
                const name = field.getAttribute('name') as string;
                const value = field.getAttribute('value') as string;

                field.checked = state?.filters?.state[name]?.includes(value) || false;
            })
    }

    @MemoSelect(selectFetchResultCounter)
    onUpdateButtonText(state: DesktopState) {
        const {
            fetchResultCounter,
        } = selectFetchResultCounter(state);

        if (state.filters.isOpen) {
            this.updateButtonText(fetchResultCounter);
        }
    }

    @MemoSelect(selectStatusCounter)
    onFetchStatusChange(state: DesktopState) {
        const { counterIsPending } = selectStatusCounter(state);

        if (state.filters.isOpen) {
            this.disableButton(counterIsPending);
        }
    }

    @MemoSelect(selectCheckedFieldCounter)
    onCheckedListChange(state: DesktopState) {
        const isOpen = state.filters.isOpen;

        if (isOpen) {
            this.showToolsFilterColumn(state);
            this.updateCounterToolsFilterColumn(state.filters.state, state.filters.data);
        }
    }

    onStoreChange(state: DesktopState, oldState: DesktopState) {}

    openFilters({ container, state }: { container?: HTMLElement; state: DesktopState }) {
        const templateData = this.prepareFilterData(state);

        this.renderTemplate({
            container,
            templateData
        });

        this.connectedCallback();
    }

    updateButtonText(count: number) {
        const button = this.getRoot().querySelector(FiltersDesk.cn.setElem('button').toSelector());

        if (! button) {
            throw new FilterDeskError(`not found element: ${button}`);
        }

        const buttonTextWrapper = button.querySelector('span');

        if (! buttonTextWrapper) {
            throw new FilterDeskError(`not found element: ${button}`);
        }


        buttonTextWrapper.textContent = `Показать ${count} ${numWord(count, ['изделие', 'изделия', 'изделий'])}`;
    }
}
