import EventBus from 'EventBus';
import Gate31Api from '@gate31/core/src/api/gate31-api';
import { Base } from '@gate31/core/src/libs/BaseComponent';

export class ConfirmPhoneDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ConfirmPhoneDesk');

    confirmed: boolean;

    $phoneWrapper: HTMLDivElement;
    $phoneField: HTMLInputElement;
    $phoneBtn: HTMLButtonElement;
    $phoneError: HTMLDivElement;
    $phoneCodeWrapper: HTMLDivElement;
    $phoneCodeField: HTMLInputElement;
    $phoneCodeBtn: HTMLButtonElement;
    $phoneCodeError: HTMLDivElement;
    $successIcon: HTMLDivElement;
    $resetBtn: HTMLSpanElement;
    $getBtn: HTMLSpanElement;

    constructor(opts: { id: string; node: HTMLElement }) {
        super(opts.node);

        this.confirmed = false;

        if (! this.getRoot()) {
            return;
        }

        this.$phoneWrapper = ConfirmPhoneDesk.querySelector<HTMLDivElement>(ConfirmPhoneDesk.cn.setElem('phone-wrapper').toSelector()) as HTMLDivElement;
        this.$phoneField = ConfirmPhoneDesk.querySelector<HTMLInputElement>(ConfirmPhoneDesk.cn.setElem('phone-field').toSelector());
        this.$phoneBtn = ConfirmPhoneDesk.querySelector<HTMLButtonElement>(ConfirmPhoneDesk.cn.setElem('phone-trigger').toSelector());
        this.$phoneError = ConfirmPhoneDesk.querySelector<HTMLDivElement>(ConfirmPhoneDesk.cn.setElem('phone-error').toSelector());
        this.$phoneCodeWrapper = ConfirmPhoneDesk.querySelector<HTMLDivElement>(ConfirmPhoneDesk.cn.setElem('wrapper-phone-code').toSelector());
        this.$phoneCodeField = ConfirmPhoneDesk.querySelector<HTMLInputElement>(ConfirmPhoneDesk.cn.setElem('phone-code-field').toSelector());
        this.$phoneCodeBtn = ConfirmPhoneDesk.querySelector<HTMLButtonElement>(ConfirmPhoneDesk.cn.setElem('phone-code-trigger').toSelector());
        this.$phoneCodeError = ConfirmPhoneDesk.querySelector<HTMLDivElement>(ConfirmPhoneDesk.cn.setElem('phone-code-error').toSelector());
        this.$successIcon = ConfirmPhoneDesk.querySelector<HTMLDivElement>(ConfirmPhoneDesk.cn.setElem('success-icon').toSelector());
        this.$resetBtn = ConfirmPhoneDesk.querySelector<HTMLSpanElement>(ConfirmPhoneDesk.cn.setElem('reset').toSelector());
        this.$getBtn = ConfirmPhoneDesk.querySelector<HTMLSpanElement>(ConfirmPhoneDesk.cn.setElem('get').toSelector());

        this.callbackConnection();
    }

    callbackConnection() {
        // Отправка номера для получения кода
        this.$phoneBtn.addEventListener('click', () => {
            this.resetFieldPhoneCode();
            this.getCode();
        });

        // Отправка кода подтверждения
        this.$phoneCodeBtn.addEventListener('click', () => {
            this.sendCode();
        });

        // Сброс номера телефона
        this.$resetBtn.addEventListener('click', () => {
            this.unablePhoneBtn();
            this.openPhoneBtn();
            this.unablePhoneField();
            this.hiddenPhoneCode();
            this.hiddenSuccessIcon();

            this.confirmed = false;
        });

        // Повторный запрос кода
        this.$getBtn.addEventListener('click', () => {
            this.getCode();
            this.lockGetBtn();
        });

        this.$phoneField.addEventListener('input', () => {
            this.removePhoneError();
        });

        this.$phoneCodeField.addEventListener('input', () => {
            this.removePhoneCoreError();
            this.removePhoneError();
        });

        EventBus.subscribe('confirm-phone:get-code', () => {
            this.disablePhoneBtn(); // Убрать кнопку подтверждения
            this.disabledPhoneField();
            this.openPhoneCode(); // Показать поле для кода
            this.openGetButton();
            this.openPhoneCodeField();
        });
        EventBus.subscribe('confirm-phone:send-code', () => {
            this.openSuccessIcon();
            this.hiddenPhoneBtn();

            this.hiddenGetButton();
            this.hiddenPhoneCodeField();

            this.removePhoneCoreError();
            this.removePhoneError();

            this.confirmed = true;
        });

        this.$phoneField.addEventListener('input', () => {
            const notPointerClass = ConfirmPhoneDesk.cn.setElem('btn-not-pointer').toString();

            if (this.$phoneField.value) {
                this.$phoneBtn.classList.remove(notPointerClass);
            } else {
                this.$phoneBtn.classList.remove(notPointerClass);
            }
        });

        this.$phoneCodeField.addEventListener('input', () => {
            const notPointerClass = ConfirmPhoneDesk.cn.setElem('btn-not-pointer').toString();

            if (this.$phoneCodeField.value) {
                this.$phoneCodeBtn.classList.remove(notPointerClass);
            } else {
                this.$phoneCodeBtn.classList.remove(notPointerClass);
            }
        });
    }

    private openPhoneCodeField() {
        this.$phoneCodeField.classList.remove('hidden');
        this.$phoneCodeBtn.classList.remove('hidden');
    }

    private hiddenPhoneCodeField() {
        this.$phoneCodeField.classList.add('hidden');
        this.$phoneCodeBtn.classList.add('hidden');
    }

    private openGetButton() {
        this.$getBtn.classList.remove('hidden');
    }

    private hiddenGetButton() {
        this.$getBtn.classList.add('hidden');
    }

    private lockGetBtn() {
        let timer = 59;
        const defaultText = this.$getBtn.innerHTML;

        this.$getBtn.setAttribute('disabled', '');

        const intervalId = setInterval(() => {
            timer -= 1;

            const $getBtnContent = this.$getBtn.querySelector<HTMLSpanElement>('span');

            if (! $getBtnContent) {
                throw new Error('Ошибка в отрисовки таймера повторной отправки');
            }

            $getBtnContent.innerHTML = `Отправить повторно через ${timer} сек.`;

            if (timer <= 0) {
                $getBtnContent.innerHTML = defaultText;
                this.$getBtn.removeAttribute('disabled');
                clearInterval(intervalId);
            }
        }, 1000);
    }

    private unablePhoneField() {
        this.$phoneField.removeAttribute('disabled');
    }

    private disabledPhoneField() {
        this.$phoneField.setAttribute('disabled', '');
    }

    private hiddenPhoneBtn() {
        this.$phoneBtn.classList.add('hidden');
    }

    private openPhoneBtn() {
        this.$phoneBtn.classList.remove('hidden');
    }

    private openSuccessIcon() {
        this.$successIcon.classList.remove('hidden');
    }

    private hiddenSuccessIcon() {
        this.$successIcon.classList.add('hidden');
    }

    private unablePhoneBtn() {
        this.$phoneBtn.removeAttribute('disabled');
    }

    private disablePhoneBtn() {
        this.$phoneBtn.setAttribute('disabled', '');
    }

    private openPhoneCode() {
        this.$phoneCodeWrapper.classList.remove('hidden');
    }

    private hiddenPhoneCode() {
        this.$phoneCodeWrapper.classList.add('hidden');
    }

    resetFieldPhoneCode() {
        this.$phoneCodeField.value = '';
    }

    renderNotConfirmError() {
        this.renderError({
            $field: this.$phoneError,
            error: 'Номер телефона необходимо подтвердить'
        });
    }

    private renderError({ error, $field }: { error: string; $field: HTMLElement }) {
        $field.innerHTML = error;
        $field.classList.add('active');
    }

    removePhoneError() {
        this.$phoneError.classList.remove('active');
    }

    removePhoneCoreError() {
        this.$phoneCodeError.classList.remove('active');
    }

    /**
     * Отправить код в смс
     */
    getCode() {
        const phone = this.$phoneField.value;

        Gate31Api.configPhoneGetCode(phone)
            .then(response => {
                if (! response.status || response.status !== 'success') {
                    throw response;
                }

                EventBus.publish('confirm-phone:get-code', response);
            })
            .catch(error => {
                switch (error.code) {
                    case 'INVALID_PHONE_COUNTRY_CODE':
                        this.renderError({
                            $field: this.$phoneError,
                            error: 'Для регистрации аккаунта на иностранные номера обратитесь в поддержку'
                        });
                        break;
                    default:
                        this.renderError({
                            $field: this.$phoneError,
                            error: 'Ошибка при отправке. Повторите попытку'
                        });
                        break;
                }
            });
    }

    /**
     * Отправить код подтверждения
     */
    sendCode() {
        const code = this.$phoneCodeField.value;
        const phone = this.$phoneField.value;

        Gate31Api.configPhoneSendCode({ phone, code })
            .then(response => {
                if (! response.status || response.status !== 'success') {
                    throw response;
                }

                EventBus.publish('confirm-phone:send-code', response);
            })
            .catch(() => {
                this.renderError({
                    $field: this.$phoneCodeError,
                    error: 'Неверный код или превышено время ожидания'
                });
            });
    }
}
