import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Connect, MemoSelect } from '@gate31/core/src/redux/store';
import { ProductLiquid } from '@gate31/types';
import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';
import { createSelector } from '@reduxjs/toolkit';
import { collectionProducts } from '@gate31/feature/Collection';
import { changeProductObject } from '@gate31/core/src/libs/utils';
import { ProductVideoDesk } from '@gate31/desktop/components/ProductVideoDesk/ProductVideoDesk';
import { DesktopState, desktopStore } from '../../scripts/store';

/* eslint-disable */
const selectCollectionProducts = createSelector([collectionProducts], (collectionProducts) => {
    return {
        collectionProducts
    };
});
/* eslint-unable */


@Connect(desktopStore)
export class CollectionDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('CollectionDesk');

    private _id: number;

    constructor(node: HTMLDivElement) {
        super(node);

        this._id = Number(this.getRoot().getAttribute('data-collection-id'));
    }

    onStoreChange() {}

    get id() {
        return this._id;
    }

    @MemoSelect(selectCollectionProducts)
    onProductsStoreChange(state: DesktopState) {
        const { collectionProducts } = selectCollectionProducts(state);

        if (! collectionProducts.length ) {
            return;
        }

        if (state.collection.currentPage === 1) {
            // Изменены или сортировка или фильтрация
            this.getRoot().innerHTML = changeProductObject(state.collection.products).map(this.renderItem).join('');
        } else {
            // Изменена пагинация
            this.getRoot().innerHTML += changeProductObject(state.collection.products).map(this.renderItem).join('');
        }

        ProductVideoDesk.init();
    }

    renderItem(product: ProductLiquid): string {
        return LiquidRender.render('snippet-product-desktop-template', {
            data: product,
            view_mode: 'round',
            is_dolya: product.properties?.aktsiya?.characteristics[0].handle !== 'sample-sale',
            is_show_video: true
        });
    }
}
