import queryString from '@gate31/core/src/libs/query-string';
import { filter } from '@gate31/touch/scripts/collection';

type path = [{
        title: number | string;
        url?: string;
        // eslint-disable-next-line
        is_link: boolean;
    }];
interface opts {
    pages: number;
    curePage: number;
    filterState: filter;
    hostUrl: string;
}

// eslint-disable-next-line
export function paginationObj(opts: opts) {
    let path: path = [
        {
            title: 1,
            url: url(1, opts.filterState, opts.hostUrl),
            is_link: true
        }
    ];

    if (opts.curePage === 1) {
        path = [
            {
                title: 1,
                is_link: false
            }
        ];
    }

    if (opts.pages === 1) {
        path.splice(0, 1);
    } else if (opts.pages <= 5) {
        for (let i = 2; i <= opts.pages; i++) {
            if (i === opts.curePage) {
                path.push({
                    title: i,
                    is_link: false
                });
            } else {
                path.push({
                    title: i,
                    url: url(i, opts.filterState, opts.hostUrl),
                    is_link: true
                });
            }
        }
    } else if (opts.pages === 6) {
        if (opts.curePage <= 1) {
            firstPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else if (opts.curePage >= 6) {
            lastPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else {
            for (let i = 2; i <= opts.pages; i++) {
                if (i === opts.curePage) {
                    path.push({
                        title: i,
                        is_link: false
                    });
                } else {
                    path.push({
                        title: i,
                        url: url(i, opts.filterState, opts.hostUrl),
                        is_link: true
                    });
                }
            }
        }
    } else if (opts.pages === 7) {
        if (opts.curePage === 1 || opts.curePage === 2) {
            firstPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else if (opts.curePage === 7 || opts.curePage === 6) {
            lastPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else {
            for (let i = 2; i <= opts.pages; i++) {
                if (i === opts.curePage) {
                    path.push({
                        title: i,
                        is_link: false
                    });
                } else {
                    path.push({
                        title: i,
                        url: url(i, opts.filterState, opts.hostUrl),
                        is_link: true
                    });
                }
            }
        }
    } else if (opts.pages === 8) {
        if (opts.curePage <= 3) {
            firstPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else if (opts.curePage >= 6) {
            lastPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else {
            for (let i = 2; i <= opts.pages; i++) {
                if (i === opts.curePage) {
                    path.push({
                        title: i,
                        is_link: false
                    });
                } else {
                    path.push({
                        title: i,
                        url: url(i, opts.filterState, opts.hostUrl),
                        is_link: true
                    });
                }
            }
        }
    } else if (opts.pages <= 9) {
        if (opts.curePage === 5) {
            // собрана полная линия (когда я назожусь на отметке 5)
            for (let i = 2; i <= opts.pages; i++) {
                if (i === 5) {
                    path.push({
                        title: i,
                        is_link: false
                    });
                } else {
                    path.push({
                        title: i,
                        url: url(i, opts.filterState, opts.hostUrl),
                        is_link: true
                    });
                }
            }
        } else if (opts.curePage < 5) {
            firstPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else if (opts.curePage > 5) {
            lastPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        }
    } else if (opts.pages > 9) {
        if (opts.curePage <= 5) {
            firstPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else if (opts.pages - opts.curePage <= 4) { // Мы находимся в конце погинации
            lastPages({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        } else {
            centerPositionPagination({
                pages: opts.pages,
                curePage: opts.curePage,
                filterState: opts.filterState,
                hostUrl: opts.hostUrl
            }, path);
        }
    }

    return path;
}

function centerPositionPagination(opts: opts, path: path) {
    path.push({
        title: '&hellip;',
        is_link: false
    });
    path.push({
        title: opts.curePage - 2,
        url: url(opts.curePage - 2, opts.filterState, opts.hostUrl),
        is_link: true
    });
    path.push({
        title: opts.curePage - 1,
        url: url(opts.curePage - 1, opts.filterState, opts.hostUrl),
        is_link: true
    });
    path.push({
        title: opts.curePage,
        is_link: false
    });
    path.push({
        title: opts.curePage + 1,
        url: url(opts.curePage + 1, opts.filterState, opts.hostUrl),
        is_link: true
    });
    path.push({
        title: opts.curePage + 2,
        url: url(opts.curePage + 2, opts.filterState, opts.hostUrl),
        is_link: true
    });
    path.push({
        title: '&hellip;',
        is_link: false
    });
    path.push({
        title: opts.pages,
        url: url(opts.pages, opts.filterState, opts.hostUrl),
        is_link: true
    });
}
function firstPages(opts: opts, path: path) {
    for (let i = 1; i <= opts.curePage; i++) {
        if (i === 1) {
            if (i === opts.curePage) {
                path[0].is_link = false;
                delete path[0].url;
            }
        } else if (i === opts.curePage) {
            path.push({
                title: i,
                is_link: false
            });
        } else {
            path.push({
                title: i,
                url: url(i, opts.filterState, opts.hostUrl),
                is_link: true
            });
        }
    }

    path.push({
        title: opts.curePage + 1,
        url: url(opts.curePage + 1, opts.filterState, opts.hostUrl),
        is_link: true
    });
    path.push({
        title: opts.curePage + 2,
        url: url(opts.curePage + 2, opts.filterState, opts.hostUrl),
        is_link: true
    });
    path.push({
        title: '&hellip;',
        is_link: false
    });
    path.push({
        title: opts.pages,
        url: url(opts.pages, opts.filterState, opts.hostUrl),
        is_link: true
    });
}
function lastPages(opts: opts, path: path) {
    path.push({
        title: '&hellip;', is_link: false
    });
    path.push({
        title: opts.curePage - 2, url: url(opts.curePage - 2, opts.filterState, opts.hostUrl), is_link: true
    });
    path.push({
        title: opts.curePage - 1, url: url(opts.curePage - 1, opts.filterState, opts.hostUrl), is_link: true
    });

    for (let i = opts.curePage; i <= opts.pages; i++) {
        if (i === opts.curePage) {
            path.push({
                title: i, is_link: false
            });
        } else {
            path.push({
                title: i, url: url(i, opts.filterState, opts.hostUrl), is_link: true
            });
        }
    }
}

export const url = (page: number, filterState: filter, hostUrl: string) => {
    return `${hostUrl}?${queryString.stringify({
        ...filterState,
        page
    }, null) }`;
};
