import { CollectionSliceState } from './store';

export const collectionProducts = (state: { collection: CollectionSliceState }) => {
    return state.collection.products;
};
export const fetchStatus = (state: { collection: CollectionSliceState }) => {
    return state.collection.status;
};
export const fetchStatusIsSuccess = (state: { collection: CollectionSliceState }) => {
    return state.collection.status === 'succeeded';
};
export const currentPage = (state: { collection: CollectionSliceState }) => {
    return state.collection.currentPage;
};
export const pages = (state: { collection: CollectionSliceState }) => {
    return state.collection.pages;
};

