import LiquidRender from '../../scripts/liquid-render';
import { EventEmitter } from '../../scripts/component-event-emitter';

const ee = new EventEmitter();

export interface InputImagesInt<T> {
    baseClass: string;
    name: string;
    className: string | undefined;
    dataAttr: string | undefined;
    template: HTMLDivElement;
    inputTemplate: string;
    input: HTMLInputElement | null;
    imagesList: Array<string | void>;
    imagesWrap: HTMLDivElement | null;
    wrap: HTMLDivElement | null;
    cb?: (arg: Array<string | void>) => void;
    formData: FormData;

    createInput: () => HTMLDivElement;
    createImage: (arg1: string, arg2: number) => string;
    inputHandler: () => Array<FileReader> | undefined;
    renderImages: () => void;
    handlerImages: () => void;
    removeImage: (arg: T) => T;
    resetInput: () => void;
    toggleError: (isValid: boolean) => void;
}

interface InputImagesOpts {
    name: string;
    className?: string;
    dataAttr?: string;
    cb?: (arg: Array<string | void>) => void;
}

export default class InputImages implements InputImagesInt<number> {
    baseClass: string;
    name: string;
    className: string | undefined;
    dataAttr: string | undefined;
    template: HTMLDivElement;
    inputTemplate: string;
    input: HTMLInputElement | null;
    imagesList: Array<string | void>;
    imagesWrap: HTMLDivElement | null;
    wrap: HTMLDivElement | null;
    cb?: (arg: Array<string | void>) => void;
    formData: FormData;

    constructor(opts: InputImagesOpts) {
        this.baseClass = 'input-images';
        this.name = opts.name;
        this.className = opts.className ? opts.className : '';
        this.dataAttr = opts.dataAttr ? opts.dataAttr : '';
        this.inputTemplate = LiquidRender.render('input-images-template', { className: this.className, dataAttr: this.dataAttr, name: this.name });

        this.cb = opts.cb;

        this.template = this.createInput();
        this.input = this.template.querySelector<HTMLInputElement>(`[type="file"].${this.baseClass}__input`);
        this.imagesWrap = this.template.querySelector<HTMLDivElement>(`.${this.baseClass}__images`);
        this.wrap = this.template.querySelector(`.${this.baseClass}__wrap`);

        if (! this.input) {
            return;
        }

        this.imagesList = [];
        this.formData = new FormData();
        this.input.addEventListener('change', () => {
            this.inputHandler();
        });

        ee.on(`images-input:${this.name}:update`, () => {
            this.renderImages();
            this.handlerImages();

            if (this.cb !== undefined) {
                this.cb(this.imagesList);
            }
        });
    }

    resetInput() {
        this.imagesList = [];
        this.formData = new FormData();
    }

    handlerImages() {
        const currentImagesRemoveBtn = this.imagesWrap?.querySelectorAll<HTMLDivElement>(`.${this.baseClass}__remove`);

        if (! currentImagesRemoveBtn || ! currentImagesRemoveBtn.length) {
            return;
        }

        currentImagesRemoveBtn.forEach(button => {
            button.addEventListener('click', e => {
                const target = e.target as HTMLElement;
                const index = Number((target.closest(`.${this.baseClass}__img`) as HTMLElement).getAttribute('data-index'));

                this.removeImage(index);
            });
        });
    }

    removeImage(index: number): number {
        delete this.imagesList[index];
        this.formData.delete(`file${index}`);

        ee.emit(`images-input:${this.name}:update`);

        return index;
    }

    createInput() {
        const element = document.createElement('div');

        element.classList.add(this.baseClass);
        element.innerHTML = this.inputTemplate;

        return element;
    }

    /*
    * Создает блок с изображением
    */
    createImage(src: string, index: number): string {
        return LiquidRender.render('input-images-image-template', { index, src });
    }

    /*
    * Перерисовывает изображение инпута
    */
    renderImages(): void {
        if (! this.imagesWrap) {
            return;
        }

        this.imagesWrap.innerHTML = '';

        this.imagesList.forEach((scrBase64, index) => {
            if (scrBase64) {
                const image = this.createImage(scrBase64, index);

                (this.imagesWrap as HTMLDivElement).innerHTML += image;
            }
        });
    }

    /*
    * Добавляем загруженные изображения в массив imagesList в формате base64
    */
    inputHandler(): Array<FileReader> | undefined {
        if (! this.input || ! this.input.files) {
            return;
        }

        const reader: Array<FileReader> = [];

        for (const i in this.input.files) {
            if (this.input.files.hasOwnProperty(i)) {
                reader[i] = new FileReader();
                reader[i].readAsDataURL(this.input.files[i]);

                reader[i].onload = e => {
                    const imageBase64 = (e.target as FileReader).result as string;

                    this.imagesList.push(imageBase64);

                    if (! this.input || ! this.input.files?.length) {
                        return;
                    }

                    const currentIndex = this.imagesList.length - 1;

                    this.formData.append(`file${currentIndex}`, this.input.files[i]);

                    ee.emit(`images-input:${this.name}:update`);
                };
            }
        }
    }

    toggleError(isValid: boolean) {
        if (! this.wrap) return;

        if (isValid) {
            this.wrap.classList.remove('is-error');
        } else {
            this.wrap.classList.add('is-error');
        }
    }
}
