import { Liquid } from 'liquidjs';
import { priceFormat } from '@gate31/core/src/libs/utils';
import FiltersDeskTemplate from '@gate31/desktop/components/FiltersDesk/FiltersDesk.liquid';
import LoyaltyProgram from '@gate31/touch/components/cart-loyalty/cart-loyalty.liquid';
import cartPriceTemplate from '@gate31/touch/components/cart-price/cart-price.liquid';
import DolyamiContentTouch from '@gate31/touch/components/DolyamiContentTouch/DolyamiContentTouch.liquid';
import orderDiscountTemplate from '@gate31/feature/order-discount/order-discount.liquid';
import radioButtonTemplate from '@gate31/feature/radio-button/radio-button.liquid';
import reviewsItemTemplate from '@gate31/feature/reviews/reviews-item.liquid';
import reviewsProductHeaderTemplate from '@gate31/feature/reviews/reviews-product-header.liquid';
import reviewsResultTemplate from '@gate31/feature/reviews/reviews-result.liquid';
import reviewsRating from '@gate31/feature/reviews/reviews-rating.liquid';
import reviewsNextButton from '@gate31/feature/reviews/reviews-next-button.liquid';
import DolyaPriceTemplate from '@gate31/feature/DolyaPrice/DolyaPrice.liquid';
import SnippetProductDeskTemplate from '@gate31/desktop/components/SnippetProductDesk/SnippetProductDesk.liquid';
import SnippetProductSliderDeskTemplate from '@gate31/desktop/components/SnippetProductSliderDesk/SnippetProductSliderDesk.liquid';
import FavoritePopoverSnippetDeskTemplate from '@gate31/desktop/components/FavoritePopoverSnippetDesk/FavoritePopoverSnippetDesk.liquid';
import CartPopoverSnippetDeskTemplate from '@gate31/desktop/components/CartPopoverSnippetDesk/CartPopoverSnippetDesk.liquid';
import HeaderPopoverDesk from '@gate31/desktop/components/HeaderPopoverDesk/HeaderPopoverDesk.liquid';
import WarehouseProductDesk from '@gate31/desktop/components/WarehouseProductDesk/WarehouseProductDesk.liquid';
import ProductModalGallerySliderDesk from '@gate31/desktop/components/ProductModalGallerySliderDesk/ProductModalGallerySliderDesk.liquid';
import DolyamiContentDeskTemplate from '@gate31/desktop/components/DolyamiContentDesk/DolyamiContentDesk.liquid';
import CartPriceDesktop from '@gate31/desktop/snippets/cart-price-desktop.liquid';
import ModalDeskTemplate from '@gate31/desktop/components/ModalDesk/ModalDesk.liquid';
import TableSizeDesk from '@gate31/desktop/components/TableSizeDesk/TableSizeDesk.liquid';
import ReviewsRatingDesk from '@gate31/desktop/components/ReviewsRatingDesk/ReviewsRatingDesk.liquid';
import subscriptionModalDeskTemplate from '@gate31/desktop/components/SubscribeDesk/SubscribeDesk.liquid';
import PasswordToggleDeskTemplate from '@gate31/desktop/components/PasswordToggleDesk/PasswordToggleDesk.liquid';
import ReviewsStatsDesk from '@gate31/desktop/components/ReviewsStatsDesk/ReviewsStatsDesk.liquid';
import ReviewsItemDesk from '@gate31/desktop/components/ReviewsItemDesk/ReviewsItemDesk.liquid';
import SearchBoxDesk from '@gate31/desktop/components/SearchBoxDesk/SearchBoxDesk.liquid';
import AccountOrderDesk from '@gate31/desktop/components/AccountOrderDesk/AccountOrderDesk.liquid';
import ProductVideoDeskTemplate from '@gate31/desktop/components/ProductVideoDesk/ProductVideoDesk.liquid';
import SnippetProductTemplate from '../../../touch/components/snippet-product/snippet-product.liquid';
import IconTemplate from '../components/icon/icon.liquid';
import buttonTemplate from '../components/button/button.liquid';
import statusTemplate from '../../../feature/status/status.liquid';
import productDiscountTemplate from '../components/product-discount/product-discount.liquid';
import productPriceTemplate from '../../../feature/product-price/product-price.liquid';
import pictureTemplate from '../components/picture/picture.liquid';
import collectionFiltersTemplate from '../components/collection-filter/collection-filter__temp.liquid';
import orderItemTemplate from '../components/order-item/order-item.liquid';
import orderLoad from '../components/snippet-product-load/snippet-product-load.liquid';
import fileItem from '../components/file/file__temp.liquid';
import cartDefault from '../components/cart-default/cart-default.liquid';
import clientDefault from '../components/client/client__default-text.liquid';
import clientDefaultOrderList from '../components/client/client__default-order-list.liquid';
import passwordTemplate from '../components/password/password__temp.liquid';
import recommendation from '../components/recomendation/recommendation.liquid';
import warehouseEmpty from '../components/warehous/warehouse-empty__temp.liquid';
import warehouse from '../components/warehous/warehouse__temp.liquid';
import paginationTemplate from '../components/pagination/pagination-links.liquid';
import discountPriceTemplate from '../components/discount-price/discount-price.liquid';
import inputTemplate from '../components/input/input.liquid';
import inputImageTemplate from '../components/input-images/input-images.liquid';
import inputImagesImageTemplate from '../components/input-images/input-images__image.liquid';
import productAddCard from '../components/product-add-card/product-add-card.liquid';
import searchTemplate from '../components/search/search.liquid';
import searchItemTemplate from '../components/search/search-item.liquid';
import modalTemplate from '../components/modal/modal.liquid';
import modalMiniTemplate from '../components/modal/modal-mini.liquid';
import modalBaseTemplate from '../components/modal/modal-base.liquid';
import checkboxInputTemplate from '../components/checkbox-input/checkbox-input.liquid';

const INCLUDES = {
    icon: IconTemplate as string,
    button: buttonTemplate as string,
    status: statusTemplate as string,
    'product-discount': productDiscountTemplate as string,
    'product-price': productPriceTemplate as string,
    picture: pictureTemplate as string,
    'checkbox-input': checkboxInputTemplate as string,
    'snippet-product': SnippetProductTemplate as string,
    'discount-price': discountPriceTemplate as string,
    'radio-button': radioButtonTemplate as string,
    input: inputTemplate as string,
    'order-discount': orderDiscountTemplate as string,
    'reviews-rating': reviewsRating as string,
    SnippetProductSliderDesk: SnippetProductSliderDeskTemplate as string,
    ProductVideoDesk: ProductVideoDeskTemplate as string,
    DolyaPrice: DolyaPriceTemplate as string
};

class LiquidRenderError extends Error {
    name = 'LiquidRenderError'
}

export type LiquidTemplates = {
    'reviews-result-template': {};
    'input-images-template': {};
    'input-images-image-template': {};
    'snippet-product-template': {};
    'snippet-product-desktop-template': {};
    'cart-price-template': {};
    'loyalty-program-template': {};
    'reviews-product-header-template': {};
    'status-template': {};
    'collection-filter-template': {};
    'filters-desk-template': {};
    'snippet-product-load': {};
    'order-item-template': {};
    'reviews-item-template': {};
    'reviews-next-button-template': {};
    'file-item-template': {};
    'cart-default-template': {};
    'client-default-template': {};
    'client-default-order-list-template': {};
    'password-template': {};
    'recommendation-template': {};
    'warehouse-empty-template': {};
    'warehouse-template': {};
    'pagination-template': {};
    'product-add-card-template': {};
    'search-template': {};
    'search-item-template': {};
    'modal-template': {};
    'modal-mini-template': {};
    'modal-base-template': {};
    'favorite-popover-snippet-desk-template': {};
    'cart-popover-snippet-desk-template': {};
    'header-popover-desk-template': {};
    'warehouse-product-desk-template': {};
    'product-modal-gallery-slider-desk-template': {};
    'modal-desk-template': {};
    'table-size-desk-template': {};
    'cart-price-desktop-template': {};
    'reviews-rating-desk-template': {};
    'password-toggle-desk-template': {};
    'subscription-modal-desk-template': {};
    'reviews-stats-desk-template': {};
    'reviews-item-desk-template': {};
    'search-box-desk-template': {};
    'account-order-desk-template': {};
    'dolyami-content-desk-template': {};
    'dolyami-content-touch-template': {};
}

const TEMPLATES: Record<keyof LiquidTemplates, { template: string; dynamicPartials: Record<string, string> }> = {
    'dolyami-content-touch-template': {
        template: DolyamiContentTouch,
        dynamicPartials: {}
    },
    'dolyami-content-desk-template': {
        template: DolyamiContentDeskTemplate,
        dynamicPartials: {}
    },
    'search-template': {
        template: searchTemplate,
        dynamicPartials: {}
    },
    'search-item-template': {
        template: searchItemTemplate,
        dynamicPartials: {}
    },
    'product-add-card-template': {
        template: productAddCard,
        dynamicPartials: {}
    },
    'modal-template': {
        template: modalTemplate,
        dynamicPartials: {}
    },
    'modal-mini-template': {
        template: modalMiniTemplate,
        dynamicPartials: {}
    },
    'modal-base-template': {
        template: modalBaseTemplate,
        dynamicPartials: {}
    },
    'reviews-result-template': {
        template: reviewsResultTemplate,
        dynamicPartials: {}
    },
    'input-images-template': {
        template: inputImageTemplate,
        dynamicPartials: {}
    },
    'reviews-product-header-template': {
        template: reviewsProductHeaderTemplate,
        dynamicPartials: {}
    },
    'input-images-image-template': {
        template: inputImagesImageTemplate,
        dynamicPartials: {}
    },
    'cart-price-template': {
        template: cartPriceTemplate,
        dynamicPartials: {}
    },
    'snippet-product-template': {
        template: SnippetProductTemplate,
        dynamicPartials: {}
    },
    'snippet-product-desktop-template': {
        template: SnippetProductDeskTemplate,
        dynamicPartials: {}
    },
    'loyalty-program-template': {
        template: LoyaltyProgram,
        dynamicPartials: {}
    },
    'warehouse-empty-template': {
        template: warehouseEmpty,
        dynamicPartials: {}
    },
    'warehouse-template': {
        template: warehouse,
        dynamicPartials: {}
    },
    'recommendation-template': {
        template: recommendation,
        dynamicPartials: {}
    },
    'password-template': {
        template: passwordTemplate,
        dynamicPartials: {}
    },
    'client-default-order-list-template': {
        template: clientDefaultOrderList,
        dynamicPartials: {}
    },
    'client-default-template': {
        template: clientDefault,
        dynamicPartials: {}
    },
    'cart-default-template': {
        template: cartDefault,
        dynamicPartials: {}
    },
    'file-item-template': {
        template: fileItem,
        dynamicPartials: {}
    },
    'status-template': {
        template: statusTemplate,
        dynamicPartials: {}
    },
    'collection-filter-template': {
        template: collectionFiltersTemplate,
        dynamicPartials: {}
    },
    'filters-desk-template': {
        template: FiltersDeskTemplate,
        dynamicPartials: {}
    },
    'snippet-product-load': {
        template: orderLoad,
        dynamicPartials: {}
    },
    'order-item-template': {
        template: orderItemTemplate,
        dynamicPartials: {}
    },
    'reviews-next-button-template': {
        template: reviewsNextButton,
        dynamicPartials: {}
    },
    'pagination-template': {
        template: paginationTemplate,
        dynamicPartials: {}
    },
    'reviews-item-template': {
        template: reviewsItemTemplate,
        dynamicPartials: {}
    },
    'favorite-popover-snippet-desk-template': {
        template: FavoritePopoverSnippetDeskTemplate,
        dynamicPartials: {}
    },
    'cart-popover-snippet-desk-template': {
        template: CartPopoverSnippetDeskTemplate,
        dynamicPartials: {}
    },
    'header-popover-desk-template': {
        template: HeaderPopoverDesk,
        dynamicPartials: {}
    },
    'warehouse-product-desk-template': {
        template: WarehouseProductDesk,
        dynamicPartials: {}
    },
    'product-modal-gallery-slider-desk-template': {
        template: ProductModalGallerySliderDesk,
        dynamicPartials: {}
    },
    'modal-desk-template': {
        template: ModalDeskTemplate,
        dynamicPartials: {}
    },
    'table-size-desk-template': {
        template: TableSizeDesk,
        dynamicPartials: {}
    },
    'cart-price-desktop-template': {
        template: CartPriceDesktop,
        dynamicPartials: {}
    },
    'reviews-rating-desk-template': {
        template: ReviewsRatingDesk,
        dynamicPartials: {}
    },
    'password-toggle-desk-template': {
        template: PasswordToggleDeskTemplate,
        dynamicPartials: {}
    },
    'subscription-modal-desk-template': {
        template: subscriptionModalDeskTemplate,
        dynamicPartials: {}
    },
    'reviews-stats-desk-template': {
        template: ReviewsStatsDesk,
        dynamicPartials: {}
    },
    'reviews-item-desk-template': {
        template: ReviewsItemDesk,
        dynamicPartials: {}
    },
    'search-box-desk-template': {
        template: SearchBoxDesk,
        dynamicPartials: {}
    },
    'account-order-desk-template': {
        template: AccountOrderDesk,
        dynamicPartials: {}
    }
};

export default class LiquidRender {
    static engine = new Liquid({
        fs: {
            resolve(root, file) {
                const templateName = file.replace(/\'|"/g, '');

                if (INCLUDES[templateName as keyof typeof INCLUDES]) {
                    return templateName;
                }

                throw new Error(`Include ${file} not found`);
            },
            existsSync: include => Boolean(INCLUDES[include as keyof typeof INCLUDES]),
            readFileSync(url) {
                return INCLUDES[url as keyof typeof INCLUDES];
            },
            exists(a) {
                return new Promise(() => {
                    return a;
                });
            },
            readFile(a) {
                return new Promise(() => {
                    return a;
                });
            }
        }
    });

    static getTemplate<T extends keyof LiquidTemplates>(template: T): string {
        return <string>TEMPLATES[template]?.template;
    }

    static render<T extends keyof LiquidTemplates>(template: T, data: LiquidTemplates[T]): string {
        const templateBody = LiquidRender.getTemplate(template);

        if (! templateBody) {
            throw new LiquidRenderError(`Template ${template} not found`);
        }

        return LiquidRender.engine.parseAndRenderSync(templateBody, {
            ...data,
            ...TEMPLATES[template].dynamicPartials
        }, {
            // @ts-ignore
            dynamicPartials: false
        });
    }
}

LiquidRender.engine.registerFilter('money', (v: string) => {
    const d = priceFormat(String(v));

    return d + ' руб.';
});
LiquidRender.engine.registerFilter('replace', (v: string, arg1, arg2) => {
    return v.replace(arg1, arg2);
});
