import { Sorting } from '@gate31/feature/Sorting/sorting';
import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { Connect } from '@gate31/core/src/redux/store';
import { DesktopState, desktopStore } from '../../scripts/store';
import { SelectDesk } from '../SelectDesk/SelectDesk';

@Connect(desktopStore)
export class SortingDesk extends Sorting<DesktopState> {
    static cn = new BaseJs.BuildCn('SortingDesk');
    select: SelectDesk;

    constructor(node: HTMLElement) {
        super(node);

        this.select = SelectDesk.getOrCreateInstance(document.querySelector(`.SelectDesk__${SortingDesk.cn.toString()}`) as HTMLElement);

        this.connectedCallback();
        this.getSortingStateFromUrl();
    }

    onStoreChange() {}

    connectedCallback() {
        this.select.onChange = () => {
            this.onSortingChange();
            this.onSubmit();
        };
    }

    reset() {
        this.select.setValue('');
        desktopStore.actions.setSortingState({});
    }

    onSubmit = () => {}

    getSortingStateFromUrl() {
        const state = desktopStore.getState();

        const query = state.routing.locationBeforeTransitions.query;
        const order = query.order;

        desktopStore.actions.setSortingState({
            order: order || ''
        });
    }

    onSortingChange() {
        desktopStore.actions.setSortingState({ order: this.select.getValue() });
    }
}
