import { Base } from '@gate31/core/src/libs/BaseComponent';
import { ReviewsApi } from '@gate31/core/src/api/MneniyaApi';
import { ReviewsStatsDesk } from '@gate31/desktop/components/ReviewsStatsDesk/ReviewsStatsDesk';
import { ReviewsItemsDesk } from '@gate31/desktop/components/ReviewsItemsDesk/ReviewsItemsDesk';
import { ReviewsFormDesk } from '@gate31/desktop/components/ReviewsFormDesk/ReviewsFormDesk';
import { Stats } from '@gate31/types/ReviewsTypes';

interface ReviewsOptions {
    /**
     * Смещение загружаемых отзывов
     */
    offsetIncrement?: number;
}

export class ReviewsPage extends Base<HTMLElement> {
    static cn = new Base.BuildCn('ReviewsPage');

    loadButton: HTMLButtonElement | null;
    successWrap: HTMLDivElement;
    formWrap: HTMLDivElement;

    api: ReviewsApi;
    reviewsStats: ReviewsStatsDesk;
    reviewsForm: ReviewsFormDesk;
    reviewsItems: ReviewsItemsDesk;

    offset = 0;
    offsetIncrement: number;
    stats: Stats;

    constructor(node: HTMLElement, options?: ReviewsOptions) {
        super(node);

        this.loadButton = this.getRoot().querySelector(ReviewsPage.cn.setElem('load').toSelector());
        this.successWrap = this.getRoot().querySelector(ReviewsPage.cn.setElem('success').toSelector()) as HTMLDivElement;
        this.formWrap = this.getRoot().querySelector(ReviewsPage.cn.setElem('form-wrapper').toSelector()) as HTMLDivElement;

        this.api = new ReviewsApi();
        this.reviewsStats = new ReviewsStatsDesk({
            container: ReviewsPage.querySelector(ReviewsPage.cn.setElem('stats').toSelector()),
            template: 'reviews-stats-desk-template'
        });
        this.reviewsItems = new ReviewsItemsDesk({
            container: this.getRoot().querySelector(ReviewsPage.cn.setElem('items').toSelector()) as HTMLDivElement,
            template: 'reviews-item-desk-template'
        });
        this.reviewsForm = new ReviewsFormDesk(ReviewsFormDesk.querySelector(ReviewsFormDesk.cn.toSelector()));

        this.offsetIncrement = options?.offsetIncrement ?? 10;

        this.connectionCallback();
    }

    async connectionCallback() {
        await this.loadReviews();

        this.reviewsStats.render(this.stats);

        this.loadButton?.addEventListener('click', () => this.loadReviews());

        this.reviewsForm.onSubmit = () => this.toggleHideForm();
    }

    loadReviews() {
        this.toggleDisableLoadButton(true);

        return this.api.getReviewsAll(this.offset)
            .then(res => {
                const { Stats: stats, Reviews: reviews } = res;

                this.stats = stats;
                this.offset += this.offsetIncrement;

                this.reviewsItems.render({
                    reviewsStat: stats,
                    reviewsList: reviews
                });

                this.toggleHideLoadButton(this.offset >= stats.FilteredReviewsTotalCount);
            })
            .finally(() => {
                this.toggleDisableLoadButton(false);
            });
    }

    toggleHideLoadButton(isHidden: boolean) {
        this.loadButton?.classList[isHidden ? 'remove' : 'add'](ReviewsPage.cn.setElem('load').setMode({ active: true }).toString());
    }

    toggleDisableLoadButton(isDisabled: boolean) {
        if (! this.loadButton) return;

        this.loadButton.disabled = isDisabled;
    }

    toggleHideForm() {
        const successWrapActiveClass = ReviewsPage.cn.setElem('success').setMode({ active: true }).toString();
        const formWrapActiveClass = ReviewsPage.cn.setElem('form-wrapper').setMode({ active: true }).toString();

        this.successWrap.classList.add(successWrapActiveClass);
        this.formWrap.classList.remove(formWrapActiveClass);

        setTimeout(() => {
            this.successWrap.classList.remove(successWrapActiveClass);
            this.formWrap.classList.add(formWrapActiveClass);
        }, 5000);
    }
}
