import $ from 'jquery';
import { Base } from '@gate31/core/src/libs/BaseComponent';

export class CookieDesk extends Base<HTMLDivElement> {
    static cn = new Base.BuildCn('CookieDesk');
    static cookieKey = 'cookie-modal';

    closeButton: HTMLDivElement;

    constructor(node: HTMLDivElement) {
        super(node);

        this.closeButton = CookieDesk.querySelector<HTMLDivElement>(CookieDesk.cn.setElem('close').toSelector());

        this.connectionCallback();
    }

    connectionCallback() {
        this.closeButton.addEventListener('click', () => {
            this.setCookieValue();
            this.hideModal();
        });

        if (this.getCookieValue() !== 'false') {
            this.showModal();
        }
    }

    getCookieValue(): string {
        return $ && $.cookie ? $.cookie(CookieDesk.cookieKey) : '';
    }

    setCookieValue() {
        $ && $.cookie ? $.cookie(
            CookieDesk.cookieKey,
            'false',
            {
                expires: 10
            }
        ) : null;
    }

    hideModal() {
        this.getRoot().classList.remove(CookieDesk.cn.setMode({ active: true }).toString());
    }

    showModal() {
        this.getRoot().classList.add(CookieDesk.cn.setMode({ active: true }).toString());
    }
}
