import Client from '@gate31/uikit/common/components/client/client';
import { Maxma, responseCalculate } from '@gate31/core/src/api/maxma';
import { VarProduct } from '@gate31/types';
import EventBus, { EventBaseDataUpdateCart } from 'EventBus';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';
import Cart from 'Cart';
import { ClientResponseInt } from '@gate31/types/client';

interface bodyForCalculateAccrualInt {
    user: {
            phone: string;
            name: string;
            email: string;
            id: number;
    };
    client: ClientResponseInt['client'];
    fieldValues: { [key: string]: string };
    orderLine: Array<VarProduct>;
    loyalty: {
        usedBonuses: string | number;
    };
}
interface LoyaltyOpts {
    selectors: {
        wrapLoyalty: string;
        useBonusesBlock: string;
        form: '#loyalty-form';
        inputField: string;
        buttonSubmit: string;
        accordion: string;
        collectedWrap: string;
        balanceWrap: string;
        variantButtons: string;
        realForm: {
            form: string;
            field: string;
        };
    };
}
export interface ClientMaxmaI {
    response: {
        client: {
            phoneNumber: string;
            bonuses: number;
            pendingBonuses: number;
            fullName: string;
            /** Номер бонусной карты в виде строки */
            cardString?: string;
        };
        bonuses: Array<{
            expireAt: string;
            amount: number;
        }>;
        level?: {
            name: string;
        };
        walletsLink?: string;
    };
}
interface Bonuses {
    applied: number;
    collected: number;
    maxToApply: number | string;
}

const STATUS_DISCOUNT = {
    DISCOUNT_DELETED: 'DISCOUNT_DELETED'
};

// На странице корзины запускается только если клиент авторизован

export class Loyalty {
    realForm: {
        form: HTMLFormElement;
        field: HTMLInputElement;
    }
    state: {
        status: 'default' | 'discount' | 'remove-discount';
        bonuses: Bonuses;
        loyaltyValue: number | string;
    }
    inputField: HTMLInputElement;
    buttonSubmit: HTMLButtonElement;
    accordion: HTMLElement;
    form: HTMLElement;
    variantButtons: NodeListOf<HTMLInputElement>;
    user: ClientResponseInt | undefined;
    collectedWrap: HTMLSpanElement;
    balanceWrap: HTMLSpanElement;
    orderList: VarProduct[] | [];
    wrapLoyalty: HTMLElement;
    useBonusesBlock: HTMLElement;
    hasCouponDiscount: boolean | undefined;

    constructor(opts: LoyaltyOpts) {
        this.wrapLoyalty = document.querySelector(opts.selectors.wrapLoyalty) as HTMLElement;

        if (! this.wrapLoyalty) {
            return;
        }

        this.useBonusesBlock = document.querySelector(opts.selectors.useBonusesBlock) as HTMLElement;

        this.user = undefined;
        this.orderList = [];

        this.realForm = {
            form: document.querySelector(opts.selectors.realForm.form) as HTMLFormElement,
            field: document.querySelector(opts.selectors.realForm.field) as HTMLInputElement
        };

        this.hasCouponDiscount = undefined;

        // Видимая форма
        this.form = this.wrapLoyalty.querySelector(opts.selectors.form) as HTMLElement;

        this.inputField = this.wrapLoyalty.querySelector(opts.selectors.inputField) as HTMLInputElement;
        this.buttonSubmit = this.wrapLoyalty.querySelector(opts.selectors.buttonSubmit) as HTMLButtonElement;

        // Элементы области программы лояльности
        this.accordion = this.wrapLoyalty.querySelector(opts.selectors.accordion) as HTMLElement;
        this.collectedWrap = this.wrapLoyalty.querySelector(opts.selectors.collectedWrap) as HTMLElement;
        this.balanceWrap = this.wrapLoyalty.querySelector(opts.selectors.balanceWrap) as HTMLElement;
        this.variantButtons = this.wrapLoyalty.querySelectorAll(opts.selectors.variantButtons) as NodeListOf<HTMLInputElement>;

        this.state = {
            status: 'default',
            bonuses: {
                applied: 0,
                collected: 0,
                maxToApply: ''
            },
            loyaltyValue: this.inputField.value || STATUS_DISCOUNT.DISCOUNT_DELETED
        };

        EventBus.subscribe('update_items:insales:cart', CartState => {
            this.setSaleCharacteristics(CartState.order_lines);

            this.defineStrategy(CartState.order_lines)
                .then(defineStrategy => {
                    if (defineStrategy !== 'disabled') {
                        // Доступно списание бонусов
                        this.process(CartState);
                    } else {
                        this.hiddenLoyalty();
                    }
                });
        });
    }

    openLoyalty() {
        this.wrapLoyalty.classList.add('active');
    }

    hiddenLoyalty() {
        this.wrapLoyalty.classList.remove('active');
    }

    hiddenUseBonuses() {
        this.useBonusesBlock.classList.add('hidden');
    }

    chooseShowOrHiddenUsedBonuses(opts: responseCalculate) {
        const isCoupon = Cart.order.coupon ? Cart.order.coupon.valid : false;
        const selectedDiscount = Cart.order.discounts[0] ? Cart.order.discounts[0].amount : 0;
        const maxToApplyBonuses = opts.response.calculationResult.bonuses.maxToApply;

        if (isCoupon && maxToApplyBonuses <= selectedDiscount) {
            this.hiddenUseBonuses();
        }
    }

    chooseShowOrHideLoyalty(opts: responseCalculate) {
        const bonuses = opts.response.calculationResult.bonuses;
        const rowOffers = opts.response.calculationResult.rows.find(row => row.offers && row.offers.length);
        const isDiscountHome31 = rowOffers ? rowOffers.offers.some(offer => offer.name === 'HOME31') : false;

        if (isDiscountHome31 && bonuses.collected === 0 && bonuses.maxToApply === 0) {
            return;
        }

        this.openLoyalty();
    }

    static getBalanceUser(userPhone: string): Promise<ClientMaxmaI> {
        return Maxma.getBalance(userPhone)
            .then(res => res.json());
    }

    isUserBalance() {
        return this.state.bonuses.maxToApply !== '';
    }

    async getUserData(): Promise<ClientResponseInt | undefined> {
        if (this.user) {
            return this.user;
        }

        this.user = await Client.get()
            .then(res => res.status === 'ok' ? res : undefined)
            .catch(() => undefined);

        return this.user;
    }

    updateHasCouponDiscount(discounts: Array<{
        discount: number | string;
        amount: number;
        description: string;
    }> | []) {
        if (! discounts.length) {
            this.hasCouponDiscount = false;
            return false;
        }

        if (discounts[0]?.description?.includes('Скидка по купону')) {
            this.hasCouponDiscount = true;
            return true;
        }

        this.hasCouponDiscount = false;
        return false;
    }

    getBodyForCalculateAccrual() {
        const loyaltyFieldSampleSale = document.querySelector<HTMLInputElement>('#loyalty-field-sample-sale');
        const loyaltyFieldSale = document.querySelector<HTMLInputElement>('#loyalty-field-sale');

        if (! this.user || ! this.user.client || ! this.user.client.phone) {
            return;
        }

        const body = {
            user: {
                phone: this.user.client.phone,
                name: this.user.client.name || '',
                email: this.user.client.email as string,
                id: this.user.client.id
            },
            fieldValues: {
                'loyalty-field-sample-sale': loyaltyFieldSampleSale?.value || '',
                'loyalty-field-sale': loyaltyFieldSale?.value || ''
            },
            client: this.user.client,
            orderLine: this.orderList,
            loyalty: {
                usedBonuses: this.state.loyaltyValue
            }
        };

        return body;
    }

    async calculateAccrual(body: bodyForCalculateAccrualInt) {
        const resultCalculate = await Maxma.calculatePurchase(body);

        if (resultCalculate.response) {
            this.chooseShowOrHideLoyalty(resultCalculate);
            this.chooseShowOrHiddenUsedBonuses(resultCalculate);
        }

        const resultCalculateBonuses = resultCalculate.response.calculationResult.bonuses;

        this.state.bonuses.maxToApply = resultCalculateBonuses.maxToApply;
        this.state.bonuses.collected = resultCalculateBonuses.collected;
        this.state.bonuses.applied = resultCalculateBonuses.applied;

        return this.state.bonuses;
    }

    resetLoyaltyDiscount() {
        this.realForm.field.value = STATUS_DISCOUNT.DISCOUNT_DELETED;
        this.realForm.form.submit();
    }

    handleToggleVariant() {
        this.variantButtons.forEach(input => {
            input.addEventListener('change', e => {
                const target = e.target as HTMLInputElement;

                // Использовать баллый
                if (target.value === 'Use-points') {
                    this.form.classList.remove('cart-loyalty__content_hidden');
                }

                // Накапливать баллый
                if (target.value === 'Earn-points') {
                    this.form.classList.add('cart-loyalty__content_hidden');

                    this.resetLoyaltyDiscount();
                }
            });
        });
    }

    handleValue(input: HTMLInputElement, output: HTMLInputElement) {
        input.addEventListener('input', () => {
            this.synchronizedValue(input, output);
        });
    }

    handleForm() {
        if (! this.buttonSubmit) {
            return;
        }

        this.buttonSubmit.addEventListener('click', () => {
            this.realForm.form.submit();
        });
    }

    synchronizedValue(input: HTMLInputElement, output: HTMLInputElement) {
        output.value = input.value;

        if (input.value === '0' || input.value === '') {
            output.value = STATUS_DISCOUNT.DISCOUNT_DELETED;

            return STATUS_DISCOUNT.DISCOUNT_DELETED;
        }

        return input.value;
    }

    openAccordion() {
        const isActiveAccordion = this.accordion.querySelector('[data-accordion-is-active]')?.getAttribute('data-accordion-is-active') === 'true';
        const headerAccordion = this.accordion.querySelector('[data-accordion-header]') as HTMLElement;

        if (! isActiveAccordion) {
            headerAccordion.click();
        }
    }

    renderCollected() {
        this.collectedWrap.innerHTML = String(`+ ${this.state.bonuses.collected}`);
    }
    renderBalance() {
        this.balanceWrap.innerHTML = String(this.state.bonuses.maxToApply);
    }
    addLimitation() {
        this.inputField.addEventListener('input', () => {
            const value = Number(this.inputField.value);
            const maxValue = Number(this.state.bonuses.maxToApply);

            if (typeof value === 'number' && typeof maxValue === 'number' && value > maxValue) {
                this.inputField.value = String(this.state.bonuses.maxToApply);
                this.realForm.field.value = String(this.state.bonuses.maxToApply);
            }
        });
    }

    /**
    * Метод перезаписывает ТЕХНИЧЕСКОЕ НАЗВАНИЕ СКИДКИ на нормально (для стр оформления заказа) (для стра заказа)
    */
    static updateDiscountName() {
        const discountSaleHTML = document.querySelector('[data-discount-sale]');
        let discountSale = '';

        if (discountSaleHTML) {
            discountSale = discountSaleHTML.getAttribute('data-discount-sale') as string;
        }

        const technicalString = 'LOYALTY_DISCOUNT';

        const titleList = [
            {
                name: 'default',
                title: 'БОНУСЫ GATE31 MILES'
            },
            {
                name: 'Набор_футболок_BASE',
                title: 'Набор футболок'
            },
            {
                name: 'Скидка_партнерам:_30%',
                title: 'Программа лояльности'
            },
            {
                name: 'HOME31',
                title: 'СКИДКА HOME31'
            }
        ];

        const nameHTMLListInOrder = document.querySelectorAll('.account-order-list__result-name');

        nameHTMLListInOrder.forEach(item => {
            if (item.innerHTML.indexOf(technicalString) >= 0) {
                const objectTitle = titleList.find(nameObj => nameObj.name === discountSale) || titleList.find(nameObj => nameObj.name === 'default');

                if (objectTitle) {
                    item.innerHTML = objectTitle.title;
                }
            }
        });

        $(document).on('loaded:insales:payments', () => {
            const nameHTMLListInNewOrder = document.querySelectorAll('.discount-subtotal-description');

            nameHTMLListInNewOrder.forEach(item => {
                if (item.innerHTML.indexOf(technicalString) >= 0) {
                    const objectTitle = titleList.find(nameObj => nameObj.name === discountSale) || titleList.find(nameObj => nameObj.name === 'default');

                    if (objectTitle) {
                        item.innerHTML = objectTitle.title;
                    }
                }
            });
        });
    }

    setOrderList(list: Array<VarProduct> | []):Array<VarProduct> | [] {
        return this.orderList = list;
    }

    init() {
        if (this.realForm && this.inputField) {
            this.synchronizedValue(this.inputField, this.realForm.field);
        }

        this.handleForm();
        this.handleValue(this.inputField, this.realForm.field);

        this.openAccordion();
        this.handleToggleVariant();
    }

    async process(CartState: EventBaseDataUpdateCart) {
        this.setOrderList(CartState.order_lines);
        this.updateHasCouponDiscount(CartState.discounts);

        const user = await this.getUserData();

        if (! user) {
            return;
        }

        const bodyForCalculateAccrual = this.getBodyForCalculateAccrual();

        if (! bodyForCalculateAccrual) {
            return;
        }

        this.calculateAccrual(bodyForCalculateAccrual)
            .then(() => {
                this.renderCollected();
                this.renderBalance();
                this.addLimitation();
            })
            .catch(error => {
                return Sentry.captureException(error, {
                    tags: {
                        section: 'maxma',
                        component: 'get-client'
                    }
                });
            });
    }

    /**
     * Для передачи данных на сервер для создания заказа в maxma
     * @orderLines: Массив товаров заказа
     */
    static setSaleCharacteristics(orderLines: EventBaseDataUpdateCart['order_lines']) {
        // Задачв. Присвоить полям sample-sale и sale id всех товаров с соответствующими флагами.
        const sampleSaleField = document.querySelector<HTMLInputElement>('[id="order_field_24463859"]');
        const saleField = document.querySelector<HTMLInputElement>('[id="order_field_24463863"]');

        const ACTION_PROP_ID = 37421688;

        const sampleSaleListProductId: Array<number> = [];
        const saleListProductId: Array<number> = [];

        orderLines.forEach(orderLinesItem => {
            const isSampleSale = Boolean(orderLinesItem.product.characteristics.find(item => item.permalink === 'sample-sale' && item.property_id === ACTION_PROP_ID));
            const isSale = Boolean(orderLinesItem.product.characteristics.find(item => item.permalink === 'sale' && item.property_id === ACTION_PROP_ID));

            if (isSampleSale) {
                sampleSaleListProductId.push(orderLinesItem.id);
            }
            if (isSale) {
                saleListProductId.push(orderLinesItem.id);
            }
        });

        if (sampleSaleField) {
            sampleSaleField.value = sampleSaleListProductId.join(',');
        }
        if (saleField) {
            saleField.value = saleListProductId.join(',');
        }
    }

    setSaleCharacteristics(orderLines: EventBaseDataUpdateCart['order_lines']) {
        const sampleSaleField = document.querySelector<HTMLInputElement>('#loyalty-field-sample-sale');
        const saleField = document.querySelector<HTMLInputElement>('#loyalty-field-sale');

        const ACTION_PROP_ID = 37421688;

        const sampleSaleListProductId: Array<number> = [];
        const saleListProductId: Array<number> = [];

        orderLines.forEach(orderLinesItem => {
            const isSampleSale = Boolean(orderLinesItem.product.characteristics.find(item => item.permalink === 'sample-sale' && item.property_id === ACTION_PROP_ID));
            const isSale = Boolean(orderLinesItem.product.characteristics.find(item => item.permalink === 'sale' && item.property_id === ACTION_PROP_ID));

            if (isSampleSale) {
                sampleSaleListProductId.push(orderLinesItem.id);
            }
            if (isSale) {
                saleListProductId.push(orderLinesItem.id);
            }
        });

        if (sampleSaleField) {
            sampleSaleField.value = sampleSaleListProductId.join(',');
        }
        if (saleField) {
            saleField.value = saleListProductId.join(',');
        }
    }

    /*
    * Определяем поведение программы лояльности на основе клиента и корзины
    */
    async defineStrategy(orderLines: EventBaseDataUpdateCart['order_lines']): Promise<'disabled' | 'enabled' | 'partially'> {
        const ACTION_PROP_ID = 37421688;
        const CLIENT_GROUP_LIST = [
            {
                id: 24657,
                title: 'HOME31'
            },
            {
                id: 27303,
                title: 'Сотрудники'
            }
        ] as const;

        const clientGroupId = await Client.getGroupId();
        const clientGroup = clientGroupId ? CLIENT_GROUP_LIST.find(item => item.id === clientGroupId)?.title : undefined;

        const characteristicsList = orderLines
            .map(orderItem => orderItem.product.characteristics)
            .flat();

        const productSimpleSaleListLength = characteristicsList.filter(item => item.permalink === 'sample-sale' && item.property_id === ACTION_PROP_ID).length;
        const productSaleListLength = characteristicsList.filter(item => item.permalink === 'sale' && item.property_id === ACTION_PROP_ID).length;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const isSampleSale = Boolean(productSimpleSaleListLength);
        const isSale = Boolean(productSaleListLength);

        if (orderLines.length === productSimpleSaleListLength) {
            // У всех товаров sample-sale
            return 'disabled';
        }

        if (clientGroup === 'HOME31') {
            if (isSale) {
                return 'partially';
            }

            return 'disabled';
        }

        if (clientGroup === 'Сотрудники') {
            return 'disabled';
        }

        return 'enabled';
    }
}
