import { BaseJs } from '@gate31/core/src/libs/BaseJsComponents';
import { COLORS } from '@gate31/core/src/libs/colors';
import { buildBackgroundColorProduct } from '@gate31/core/src/libs/utils';
import { LiquidTemplates } from '@gate31/uikit/common/scripts/liquid-render';
import { DesktopState } from '@gate31/desktop/scripts/store';
import { FiltersSliceState, FiltersSlideDateItem } from './filtersStore';

export abstract class Filters<S> extends BaseJs {
    static readonly cn = new BaseJs.BuildCn('Filters');

    private submitBtn: HTMLButtonElement;

    protected constructor(template: keyof LiquidTemplates) {
        super(template);
    }

    abstract onStoreChange(state: S, oldState?: S): void;
    abstract connectedCallback(): void;
    abstract onFiltersChange(filterState: FiltersSliceState['state']): void;
    abstract onSubmit(filterState: FiltersSliceState['state']): void;
    abstract onReset(filterState: FiltersSliceState['state']): void;

    getFiltersState() {
        const result: FiltersSliceState['state'] = {};
        const data = new FormData(this.getRoot() as HTMLFormElement);

        data.forEach((value, key) => {
            if (result[key]) {
                result[key].push(String(value));
            } else if (value) {
                result[key] = [ String(value) ];
            }
        });

        return result;
    }

    disableButton(disabled: boolean) {
        this.submitBtn = this.findElem<HTMLButtonElement>((this.constructor as typeof Filters).cn.setElem('submit').toSelector());

        this.submitBtn.disabled = disabled;
    }

    getCurrentColumn(target: HTMLElement) {
        const column = target.closest((this.constructor as typeof Filters).cn.setElem('column-tools').toSelector());

        if (! column) {
            throw new Error(`column not fount: ${(this.constructor as typeof Filters).cn.setElem('column-tools').toSelector()}`);
        }

        return column;
    }

    // eslint-disable-next-line
    showToolsFilterColumn(state: Record<string, any>) {
        if (! state.filters.isOpen) {
            return;
        }

        const filterState = state.filters.state as Record<string, Array<string>>;
        const filterData = state.filters.data as Record<string, Array<{ id: number }>>;

        const cnColumnTools = (this.constructor as typeof Filters).cn.setElem('column-tools');
        const $columnToolsList = this.getRoot().querySelectorAll<HTMLDivElement>(cnColumnTools.toSelector());
        const dataToolsAttr = 'data-column-tools';
        const dataToolsNameAttr = 'data-column-tools-name';

        $columnToolsList.forEach($columnTools => {
            const type = $columnTools.getAttribute(dataToolsAttr);
            const key = $columnTools.getAttribute(dataToolsNameAttr);

            if (! type) {
                throw new Error(`value data-attr in null ${dataToolsAttr}`);
            }

            if (! key) {
                throw new Error(`value data-attr in null ${dataToolsNameAttr}`);
            }

            const currentData = filterData[key];
            const currentState = filterState[type];

            const l = currentState ? currentState.filter(i => currentData.find(j => String(j.id) === i)).length : 0;

            if (l > 0) {
                $columnTools.classList.add(cnColumnTools.setMode({ show: true }).toString());
            } else {
                $columnTools.classList.remove(cnColumnTools.setMode({ show: true }).toString());
            }
        });
    }

    updateCounterToolsFilterColumn(state: Record<string, Array<string>>, data: {[p: string]: FiltersSlideDateItem[]}) {
        const cnColumnTools = (this.constructor as typeof Filters).cn.setElem('column-tools');
        const cnColumnCounter = (this.constructor as typeof Filters).cn.setElem('column-counter');

        const $columnToolsList = this.getRoot().querySelectorAll<HTMLDivElement>(cnColumnTools.toSelector()); // '.FilterDesk__column-tools'
        const dataToolsAttr = 'data-column-tools';
        const dataToolsNameAttr = 'data-column-tools-name';

        $columnToolsList.forEach($columnTools => {
            const type = $columnTools.getAttribute(dataToolsAttr);
            const name = $columnTools.getAttribute(dataToolsNameAttr);

            if (! type) {
                throw new Error(`value data-attr in null ${dataToolsAttr}`);
            }

            const $columnCounter = $columnTools.querySelector<HTMLDivElement>(cnColumnCounter.toSelector());

            if (! $columnCounter) {
                throw new Error(`element not fount ${cnColumnCounter.toSelector()}`);
            }

            $columnCounter.innerText = state[type] ? String(state[type].filter(i => data[(name as string)].find(j => String(j.id) === i)).length) : String(0);
        });
    }

    prepareFilterData(state: DesktopState) {
        const data = state.filters.data;
        const filterState = state.filters.state;

        return {
            data: Object.keys(data).map(key => {
                const settingsRoutSrt = window?.GATE31?.COLLECTION_OPTIONS?.settingsRouts || '';
                const settingsRouts = settingsRoutSrt.split(',').map(i => i.trim()).filter(Boolean);
                const isActiveSex = settingsRouts.includes((window?.GATE31?.COLLECTION_OPTIONS?.handle as string));

                if (key === 'sex' && ! isActiveSex) {
                    return;
                }

                return {
                    key,
                    data: data[key].map(item => {
                        let checked = false;

                        const name = item.name;
                        const id = item.id;

                        if (! filterState[name]) {
                            return {
                                ...item,
                                checked
                            };
                        }

                        if (Array.isArray(filterState[name])) {
                            const list = filterState[name];

                            if (list.length && list.includes(String(id))) {
                                checked = true;
                            }
                        }

                        return {
                            ...item,
                            checked
                        };
                    })
                };
            })
        };
    }

    getColorBackground(color: { title: string; id: number; name: string }) {
        const hexColor = COLORS.find(elem => elem.title === color.title)?.hex;

        if (hexColor?.length) {
            return buildBackgroundColorProduct(hexColor);
        }

        // todo Возможно передавать в сентри чтобы знать что отсутствуют используемые цвета
    }
}
