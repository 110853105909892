import Swiper from 'swiper';
import { createSelector } from '@reduxjs/toolkit';
import { Base } from '@gate31/core/src/libs/BaseComponent';
import { Connect, MemoSelect } from '@gate31/core/src/redux/store';
import { desktopStore, DesktopState } from '@gate31/desktop/scripts/store';
import { selectCurrentHeight } from '@gate31/feature/HeaderWrapper';

/* eslint-disable */
const memoSelectCurrentHeight = createSelector([ selectCurrentHeight ], currentHeight => {
    return {
        currentHeight
    };
});
/* eslint-unable */

@Connect(desktopStore)
export class MainBannerDesk extends Base<HTMLElement> {
    static cn = new Base.BuildCn('MainBannerDesk');

    constructor(node: HTMLElement) {
        super(node);
    }

    onStoreChange() {}

    @MemoSelect(memoSelectCurrentHeight)
    onUpdateSliderHeight(state: DesktopState) {
        const { currentHeight } = memoSelectCurrentHeight(state);

        this.setSliderHeight(currentHeight);
    }

    setSliderHeight(height: number) {
        this.getRoot().style.height = `calc(100vh - ${height}px)`;
    }

    getSlides() {
        return this.getRoot().querySelectorAll(MainBannerDesk.cn.setElem('slide').toSelector());
    }

    getDelay() {
        return parseInt(this.getRoot().getAttribute('data-autoplay-delay') || '5000', 10);
    }

    static initSlider(node: HTMLElement) {
        const root = new MainBannerDesk(node);

        const slides = root.getSlides();

        if (slides.length < 2) return;

        const delay = root.getDelay();

        // eslint-disable-next-line
        new Swiper(node, {
            loop: true,
            autoplay: {
                delay,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            }
        });
    }

    static init() {
        const roots = document.querySelectorAll<HTMLElement>(MainBannerDesk.cn.toSelector());

        roots.forEach(MainBannerDesk.initSlider);
    }
}
